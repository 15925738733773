import React, { forwardRef, useEffect, useRef, useState } from "react"
import "react-spring-bottom-sheet/dist/style.css" // Import the styles
import freezeCard from "../../../../svg/v3/freezeCard.svg"
import crossIcon from "../../../../../assets/images/v3/closeBtn.svg"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import Ellipse from "../../../../svg/v3/Ellipse"
import { ColorButton, WhiteButton } from "../../../../core/buttons"
import ProceedToBlock from "./proceedToBlock"
import { handleApiAuth } from "../../../../../utils/auth"
import ApiIds from "../../../../../auth/ApiIds"
import {
  AU_BlockCodes,
  BottomSheetType,
  EventName,
  Issuers,
} from "../../../../../utils/enums"
import CardService from "../../../../../services/CardService"
import { captureEvents, consoleError } from "../../../../../utils/functions"
import { setCards } from "../../../../../store/actions/User"
import { useNavigate } from "react-router-dom"
import { setdisabledFunctionsByBlockCode } from "../../../../../store/actions/Session"
import Mpin_v3 from "../../../../auth/mpin/Mpin_v3"
import { useBottomSheet } from "../../../../auth/BottomSheetContext"
import DividerV3 from "../../../../core/Divider/v3/DividerV3"
import moment from "moment"

const defaultReasons = [
  { value: "CARDSTOLEN", text: "My Card Is Stolen" },
  { value: "CARDLOST", text: "Lost My Card" },
  { value: "DAMAGED", text: "My Card Is Damaged" },
  { value: "FRAUD", text: "Fraud" },
]

const FreezeInsteadScreen = forwardRef(
  (
    {
      isOpen,
      onClose,
      title,
      subtitle,
      setBottomSheetContent,
      reason,
      type,
      setOptionsOpen,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const sheetRef = useRef()
    const freezeInsteadContainerRef = useRef(null)

    const [loading, setLoading] = useState(true)
    const [submitLoading, setSubmitLoading] = useState(false)

    const [theme, user, authStore, config, session, screen] = useSelector(
      state => [
        state.theme,
        state.user,
        state.auth,
        state.config,
        state.session,
        state.screen,
      ],
    )

    const card = user.cards?.[0]
    const bottomSheetContext = useBottomSheet()

    const handleSubmitClick = async () => {
      setSubmitLoading(true)
      setBottomSheetContent(null)

      await handleApiAuth({
        apiId: ApiIds.LOCK_CARD,
        onAuthSuccess: onLockAuthSuccess,
        onAuthFailure: onAuthFailure,
        onAuthCancel: onAuthCancel,
        otpReason: t("CardDetailsWidgetV3.toChangeCardStatus"),
        mpinReason: t("CardDetailsWidgetV3.toChangeCardStatus"),
        version: config?.version,
        bottomSheetContext: bottomSheetContext,
      })
      setSubmitLoading(false)
    }

    const onLockAuthSuccess = async apiToken => {
      try {
        const response = await CardService.lockCard(apiToken, user.cards[0]?.id)
        const result = response?.data

        if (result?.success) {
          let eventName = EventName.TEMP_LOCK
          captureEvents({ eventName: eventName, metadata: {} })

          const tempCard = { ...card }
          tempCard.isLocked = true
          dispatch(setCards({ cards: [tempCard] }))

          let disabledFunctions = session?.disabledFunctionsByBlockCode
          if (tempCard?.isLocked) {
            if (session?.issuer === Issuers.AU_BANK) {
              let index = disabledFunctions.indexOf(AU_BlockCodes.CARD_LOCKED)
              if (index === -1) {
                disabledFunctions.push(AU_BlockCodes.CARD_LOCKED)
              }
              dispatch(
                setdisabledFunctionsByBlockCode({
                  disabledFunctionsByBlockCode: disabledFunctions,
                }),
              )
            }
          } else {
            if (session?.issuer === Issuers.AU_BANK) {
              let index = disabledFunctions.indexOf(AU_BlockCodes.CARD_LOCKED)
              if (index > -1) {
                disabledFunctions.splice(index, 1)
              }
              dispatch(
                setdisabledFunctionsByBlockCode({
                  disabledFunctionsByBlockCode: disabledFunctions,
                }),
              )
            }
          }

          const hadnleGoBackToHome = () => {
            bottomSheetContext.closeBottomSheet()
            navigate("/")
          }

          const authenticateForUnfreezeCard = async () => {
            await handleApiAuth({
              apiId: ApiIds.UNLOCK_CARD,
              onAuthSuccess: onFetchUnfreezeCardAuthSuccess,
              onAuthFailure: onUnfreezeCardAuthFailure,
              onAuthCancel: () => {
                console.log("cancelled")
              },
              otpReason: "to unlock card",
              mpinReason: "to unlock card",
              version: config?.version,
              bottomSheetContext: bottomSheetContext,
            })
          }

          const onFetchUnfreezeCardAuthSuccess = async apiToken => {
            try {
              const response = await CardService.unlockCard(
                apiToken,
                user.cards[0]?.id,
              )
              const result = response.data
              if (result?.success) {
                const newCard = { ...user.cards[0] }
                newCard.isLocked = false
                dispatch(setCards({ cards: [newCard] }))
                bottomSheetContext.openBottomSheet(
                  BottomSheetType.SUCCESS,
                  "Your card has been successfully unfrozen!",
                  "",
                  "/",
                )
              }
            } catch (e) {
              consoleError(e)
              onUnfreezeCardAuthFailure()
            }
          }

          const onUnfreezeCardAuthFailure = (error, message) => {
            consoleError("Unfreeze Card failure", error)
            bottomSheetContext.openBottomSheet(
              BottomSheetType.FAILURE,
              "Something went wrong!",
              "There was a problem in unfreezing your card. Please try again in a while",
              null,
              null,
              () => authenticateForUnfreezeCard(),
            )
          }

          bottomSheetContext.openBottomSheet(
            BottomSheetType.SUCCESS,
            "Your card was frozen successfully!",
            `effective ${moment(new Date()).format("DD-MM-YYYY, hh:mm A")}.`,
            null,
            3000,
            null,
            false,
            false,
            true,
            true,
            "Unfreeze card now",
            "Return to Home",
            null,
            authenticateForUnfreezeCard,
            hadnleGoBackToHome,
          )
        } else {
          consoleError(result.errors)
          captureEvents({
            eventName: EventName.CARD_CONTROLS_ERROR,
            metadata: {
              msg: t("CardDetailsWidgetV3.errorCardControl"),
            },
          })

          handleFailureState()
          // navigate("/", { replace: true })
        }
      } catch (error) {
        consoleError(error)
        handleFailureState()
        // toast("Sorry, an error occurred while \nupdating card status")
        // navigate("/", { replace: true })
      }
    }

    const handleFailureState = () => {
      bottomSheetContext.openBottomSheet(
        BottomSheetType.FAILURE,
        t("CardDetailsWidgetV3.BottomSheetFailureMsg"),
        t("CardDetailsWidgetV3.problemFreezingCard"), //Need to change this
        null,
        null,
        () => handleSubmitClick(),
      )
    }

    const onAuthFailure = async (error, message) => {
      error && consoleError(error)
      message && console.log()
      handleFailureState()
      // navigate("/", { replace: true })
    }

    const onAuthCancel = async message => {
      message && console.log()
      handleFailureState()
      // navigate("/", { replace: true })
    }

    useEffect(() => {
      const element = freezeInsteadContainerRef.current
      setTimeout(() => {
        element.classList.add("active")
      }, 3)
      return () => {
        element.classList.remove("active")
      }
    }, [])

    return (
      <div
        style={{ maxWidth: "450px" }}
        ref={freezeInsteadContainerRef}
        className='freezeInstead-container'
      >
        <span
          className='v3-verify-otp-close-btn v3-blockCardReason-close-btn'
          style={{ float: "right", marginRight: "20px" }}
          onClick={() => {
            if (setOptionsOpen) {
              setOptionsOpen(false)
            }
            setBottomSheetContent(null)
          }}
        >
          <img src={crossIcon} />
        </span>
        <div>
          <div ref={sheetRef} className='bottom-sheet'>
            <div className='v3-blockCardReason-ellipse-container'>
              <Ellipse
                className='v3-blockCardReason-ellipse-svg'
                color1={"#D4EBFF"}
              />
              <img
                src={freezeCard}
                className='v3-blockCardReason-bottomSheet-cardFreeze-svg'
              />
            </div>

            <div className='v3-blockCardReason-faq-title'>
              {t("CardDetailsWidgetV3.freezeCardOption")}
            </div>

            <div className='v3-blockCardReason-bottomSheet-subtitle '>
              {t("CardDetailsWidgetV3.temporaryBlockedCardUnfreeze")}
            </div>
            <DividerV3 />
            <div className='v3-blockCardReason-bottomSheet-cta-container'>
              <ColorButton
                width='93%'
                text='Yes, Freeze my card'
                onPress={() => handleSubmitClick()}
              />
            </div>

            {type !== "home" && (
              <div className='v3-blockCardReason-bottomSheet-cta-container'>
                <WhiteButton
                  width='93%'
                  text='No, Go ahead and block my card'
                  onPress={() =>
                    setBottomSheetContent(
                      <ProceedToBlock
                        setBottomSheetContent={setBottomSheetContent}
                        reason={reason}
                      />,
                    )
                  }
                />
              </div>
            )}

            <div className='v3-blockCardReason-bottomSheet-extra-space'></div>
          </div>
        </div>
      </div>
    )
  },
)

export default FreezeInsteadScreen
