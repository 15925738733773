import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import ApiIds from "../../../../auth/ApiIds"
import Loader from "../../../core/loader"
import CardService from "../../../../services/CardService"
import CommonService from "../../../../services/CommonService"
import {
  setDeviceId,
  setIsMpinSet,
  setVerifiedChallenges,
} from "../../../../store/actions/Auth"
import {
  clearSetMpinState,
  setSetMpinApiToken,
} from "../../../../store/actions/SetMpin"
import {
  setCards,
  setReplacementFee,
  setUser,
} from "../../../../store/actions/User"
import {
  getHeaderText,
  handleApiAuth,
  showCancelButton,
} from "../../../../utils/auth"
import {
  AuthChallenges,
  DEFAULT_MPIN_SIZE,
  DisplayConfig,
  HEADER_HEIGHT_IN_PX,
  MpinErrors,
  ProgramTypes,
} from "../../../../utils/constants"
import {
  AU_BlockCodes,
  AuthenticationType,
  BottomSheetType,
  EventName,
  Federal_BlockCodes,
  Issuers,
  PwaVersions,
  SetMpinTncActions,
} from "../../../../utils/enums"
import {
  captureEvents,
  consoleError,
  getEnumsFromAU_BlockCodes,
  getEnumsFromFederal_BlockCodes,
  getFlowIdForSetMpin,
  getUnion,
  goToRedirectUrl,
} from "../../../../utils/functions"
import { EmbeddedLink, Header } from "../../../core"
import { ColorButton, WhiteButton } from "../../../core/buttons"
import SetMpinIntro from "./SetMpinIntro"
import SetMpinSuccess from "./SetMpinSuccess"
import styles from "../styles"
import AuthLoader from "../../../core/SkeletonLoader/authLoader"
import { Checkbox } from "../../../../core/Checkbox"
import { showToast1 } from "../../../core/toast"
import AuthService from "../../../../services/AuthService"
import {
  setIssuer,
  setdisabledFunctionsByBlockCode,
} from "../../../../store/actions/Session"
import OtpInput from "../../otp/OtpInput_v3"
import SetMpinIntro_v3 from "./SetMpinIntro_v3"
import ActivationAuthHeader_v3 from "../../../core/ActivationAuthHeader_v3"
import InfoCircle from "../../../svg/v3/infoCircle"
import { useBottomSheet } from "../../BottomSheetContext"
import Error_v3 from "../../../core/Error_v3"
import SetMpinSuccess_v3 from "./SetMpinSuccess_v3"
import DividerV3 from "../../../core/Divider/v3/DividerV3"

// As of now, set mpin screen can be reached from -
// 1. App component
// 2. From middle of an auth by clicking forgot mpin option or mpin gets blocked due to multiple incorrect attempts
// 3. Change mpin option in manage card screen
// Success and failure actions will differ based on the screen the user is coming from and they need to be set in set mpin store
const getProgramTypeFromSession = programType => {
  switch (programType) {
    case "CreditCard":
      return ProgramTypes.CREDIT_CARD
    case "Paylater":
      return ProgramTypes.PAY_LATER
    case "Prepaid":
      return ProgramTypes.PREPAID
    default:
      return ProgramTypes.OTHER
  }
}
const SetMpin_v3 = ({ setBottomSheetContent }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const theme = useSelector(state => state.theme)
  const setMpinStore = useSelector(state => state.setMpin)
  const user = useSelector(state => state.user)
  const authStore = useSelector(state => state.auth)
  const screen = useSelector(state => state.screen)
  const config = useSelector(state => state.config)
  const session = useSelector(state => state.session)

  const totalAuthSteps = (authStore.factors?.length ?? 0) + 1
  const stepNumber = totalAuthSteps

  const setMpinConfig = screen.mpin?.setMpin || {}

  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState("")
  const [showSetMpinSuccess, setShowSetMpinSuccess] = useState(false)
  const [isIntroDone, setIsIntroDone] = useState(false)
  const [isTncChecked, setIsTncChecked] = useState(
    Boolean(
      authStore.afaDetails.config?.isMpinEverSet?.result
        ? setMpinConfig?.tncIsCheckedByDefaultSubsequent
        : setMpinConfig?.tncIsCheckedByDefault,
    ),
  )

  const [pin, setPin] = useState(["", "", "", "", "", "", ""])
  const [confirmPin, setConfirmPin] = useState(["", "", "", "", "", "", ""])
  const [redirectionLoader, setRedirectionLoader] = useState(false)
  const [oldMpinMatchedCounter, setOldMpinMatchedCounter] = useState(0)

  // check version
  const isV2 =
    config?.version === PwaVersions.V2 ||
    config?.version === PwaVersions.V2_1 ||
    config?.version === PwaVersions.V3

  const showHeader = isV2
  const headerText = getHeaderText(AuthChallenges.VERIFY_CUSTOMER)
  const mpinSize =
    config?.auth?.[AuthChallenges.MPIN]?.length || DEFAULT_MPIN_SIZE
  const oldMpinMatchCount =
    config?.auth?.[AuthChallenges.MPIN]?.oldMpinMatchCount

  // isLogin is set if coming from app component
  const isLogin = Boolean(location.state?.isLogin)

  // isChangeMpin is set if coming from change mpin option in manage card
  const isChangeMpin = Boolean(location.state?.isChangeMpin)

  const showIntro = isV2 && isLogin && !isIntroDone

  const isMpinSet = authStore.afaDetails.config.isMpinSet.result
  const bottomSheetContext = useBottomSheet()
  const isMpinEverSet = authStore.afaDetails.config.isMpinEverSet.result

  // if tnc action is to activate card, ensure card is not already activated
  const showTnc = authStore.afaDetails.config?.isMpinEverSet?.result
    ? setMpinConfig.tncEnabledSubsequent
    : setMpinConfig.tncEnabled
  const showCancelBtn = isMpinSet
  const mpinWord = screen?.mpin?.setMpin?.mpinCharacterCase || "mPIN"

  const resetMpinInput1 = useRef()
  const resetMpinInput2 = useRef()

  const focusFirst = () => {
    resetMpinInput2.current?.focusFirst()
  }
  const focusLast = () => {
    resetMpinInput1.current?.focusLast()
  }

  useEffect(() => {
    captureEvents({ eventName: EventName.SET_MPIN, metadata: {} })
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const response = await AuthService.getValidateSession({
          sessionToken: localStorage.getItem("sessionToken"),
        })

        if (
          response.status === 200 &&
          response.data.data.isPromoted &&
          !user.customer?.id
        ) {
          const sessionInfoResponse = await CommonService.getSessionInfo()

          const sessionInfoResult = sessionInfoResponse.data
          if (sessionInfoResponse.status === 200) {
            //API for Block Codes
            let blockCodes = []

            let blockCodeInfo = sessionInfoResult?.data?.blockCodeInfo

            let accountblockCodes = blockCodeInfo?.account?.blockCodes

            let cardBlockCodesArray = blockCodeInfo.cards

            let cardBlockCodes = []

            for (let ind = 0; ind < cardBlockCodesArray?.length; ind++) {
              cardBlockCodes.push(cardBlockCodesArray[ind].blockCode)
            }

            let disabledFunctions = []
            if (sessionInfoResult?.data?.issuerName === Issuers.AU_BANK) {
              let union = getUnion(accountblockCodes, cardBlockCodes)
              blockCodes = union
              disabledFunctions = getEnumsFromAU_BlockCodes(blockCodes)
            }

            if (sessionInfoResult?.data?.issuerName === Issuers.FEDERAL_BANK) {
              let accountStatus = sessionInfoResult.data.account.status
              if (accountStatus === "ACTIVE" || accountStatus === "DORMANT") {
                let union = getUnion(accountblockCodes, cardBlockCodes)
                blockCodes = union
                disabledFunctions = getEnumsFromFederal_BlockCodes(blockCodes)
              } else {
                navigate("/blocked")
              }
            }

            const blockedAccountStatuses = [
              "CLOSED",
              "SUSPENDED",
              "FORCED_SUSPENDED",
              "PENDING_CLOSURE",
              "CHARGE_OFF",
            ]
            if (
              sessionInfoResult?.data?.issuerName !== Issuers.FEDERAL_BANK &&
              sessionInfoResult?.data?.issuerName !== Issuers.AU_BANK
            ) {
              if (
                blockedAccountStatuses.includes(
                  sessionInfoResult.data.account.status,
                )
              ) {
                navigate("/blocked")
              }
            }

            if (
              disabledFunctions.includes(AU_BlockCodes.LOGIN_DISABLE) ||
              disabledFunctions.includes(AU_BlockCodes.REGISTRATION_DISABLE) ||
              disabledFunctions.includes(
                AU_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
              ) ||
              disabledFunctions.includes(
                AU_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
              )
            ) {
              navigate("/blocked")
            }

            if (
              disabledFunctions.includes(
                Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
              ) ||
              disabledFunctions.includes(
                Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
              )
            ) {
              navigate("/blocked")
            }

            if (sessionInfoResult?.success) {
              dispatch(
                setIssuer({
                  issuer: sessionInfoResult.data?.issuerName,
                }),
              )
              dispatch(
                setdisabledFunctionsByBlockCode({
                  disabledFunctionsByBlockCode: disabledFunctions,
                }),
              )

              // get session info
              // we cannot rely on summary api to get customer and account as summary api may have auth and auth may
              // require to set mpin which in turn requires customer

              // set user in store
              dispatch(
                setUser({
                  customer: sessionInfoResult.data?.customer,
                  cards: sessionInfoResult.data?.cards,
                  account: sessionInfoResult.data?.account,
                  programType: getProgramTypeFromSession(
                    sessionInfoResult.data?.programType,
                  ),
                  enablePciWidget: sessionInfoResult.data?.enablePciWidget,
                }),
              )
              dispatch(
                setReplacementFee({
                  cardReplacementFee: sessionInfoResult?.data?.cards
                    ? sessionInfoResult?.data?.cards[0]?.replacementFee
                    : 0,
                }),
              )
            } else {
              consoleError(sessionInfoResult?.errors)
              setError(sessionInfoResult?.errors)
              // don't proceed further
              return
            }
          } else {
            consoleError("Error fetching session Info")
            navigate("/error")
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!isV2 || (isV2 && !isLogin) || (isV2 && isLogin && isIntroDone)) {
        // api auth for set mpin is to be done before showing the set mpin screen
        // if api token is already set it means api auth is done
        if (setMpinStore.apiToken) return

        if (oldMpinMatchedCounter == 0) {
          setRedirectionLoader(true)
          setLoading(true)
        }
        await handleApiAuth({
          apiId: ApiIds.SET_MPIN,
          onAuthSuccess: onSetMpinAuthSuccess,
          flowId: getFlowIdForSetMpin(oldMpinMatchCount, oldMpinMatchedCounter),
          onAuthFailure: onSetMpinAuthFailure,
          onAuthCancel: onSetMpinAuthCancel,
          otpReason: t("mpin.setMpinReason"),
          mpinReason: t("mpin.setMpinReason"),
          navigate,
          version: config?.version,
          pageNavigation: true,
          bottomSheetContext: bottomSheetContext,
        })

        setTimeout(() => {
          setLoading(false)
        }, 3000)
      }
    })()
  }, [isIntroDone, oldMpinMatchedCounter])

  const setMpin = async () => {
    if (pin.length < mpinSize) {
      setError(true)
      // toast(
      //   config?.version === PwaVersions.V2_1
      //     ? "The mPIN entered is incorrect"
      //     : "The MPIN entered is incorrect",
      // )
      return
    }

    if (pin !== confirmPin) {
      captureEvents({
        eventName: EventName.INCORRECT_INPUT,
        metadata: {},
      })
      setError(`${t("mpin.mpinMismatchErrMsg")}`)
      // toast(
      //   config?.version === PwaVersions.V2_1
      //     ? "mPINs don't match"
      //     : "MPINs don't match",
      // )
      setSubmitLoading(false)
      return
    }

    const finalPin = pin
    let sameValidation = false
    let consecutiveValidation = false
    const maxSameAllowed = mpinSize
    const maxConsecutiveAllowed = mpinSize
    let sameCount = 1
    let consecutiveCount = 1

    for (let ind = 1; ind < finalPin.length; ind++) {
      if (finalPin.charAt(ind) === finalPin.charAt(ind - 1)) sameCount++
      else sameCount = 1
      if (finalPin.charAt(ind) == +finalPin.charAt(ind - 1) + 1)
        consecutiveCount++
      else consecutiveCount = 1
      if (sameCount >= maxSameAllowed) sameValidation = true
      if (consecutiveCount >= maxConsecutiveAllowed)
        consecutiveValidation = true
    }

    const continuousValidation =
      setMpinStore?.mpinMetadata?.hasNonContiguousValidation
    const nonRepeatValidation =
      setMpinStore?.mpinMetadata?.hasNonRepetitiveValidation

    if (sameValidation && nonRepeatValidation) {
      // toast(`MPIN cannot have ${maxSameAllowed} \nrepetitive digits`)
      setError(
        `${t("mpin.mpinRepetitiveErrMsg", { maxSameAllowed: maxSameAllowed })}`,
      )
      return
    }
    if (consecutiveValidation && continuousValidation) {
      // toast(`MPIN cannot have ${maxConsecutiveAllowed} \nconsecutive digits`)
      setError(
        `${t("mpin.mpinRepetitiveErrMsg", { maxSameAllowed: maxSameAllowed })}`,
      )
      return
    }

    setSubmitLoading(true)

    try {
      const mpin = pin

      const response = await CommonService.setMpin(setMpinStore.apiToken, {
        customerId: user.customer.id,
        programId: user.account.programId,
        mpin,
      })
      const result = response.data

      if (result?.success) {
        // update isMpinSet flag
        dispatch(setIsMpinSet({ isMpinSet: { result: true } }))
        captureEvents({ eventName: EventName.MPIN_UPDATED, metadata: {} })

        // if coming from change mpin then get an mpin verification id since user is not prompted to enter the new mpin
        if (isChangeMpin) {
          const verifyChallengeMpinResponse =
            await CommonService.verifyChallengeMpin({
              customerId: user.customer?.id,
              programId: user.account?.programId,
              mpin,
            })
          const verifyChallengeMpinResult = verifyChallengeMpinResponse.data

          if (verifyChallengeMpinResult?.success) {
            const verifiedChallenges = {
              ...authStore.verifiedChallenges,
              mpinRefId: verifyChallengeMpinResult.data.mpinRefId,
            }

            dispatch(setVerifiedChallenges({ verifiedChallenges }))
          }
        }

        if (!authStore.verifiedChallenges.deviceId) {
          // generate device token
          const deviceIdResponse = await CommonService.generateDeviceId({
            accountId: user.account.id,
          })
          const deviceIdResult = deviceIdResponse.data

          if (deviceIdResult?.success) {
            dispatch(
              setDeviceId({
                deviceId: deviceIdResult.data?.deviceToken,
              }),
            )
            // this will also set device id in local storage
          }
        }

        if (isV2) {
          setShowSetMpinSuccess(true)
        } else {
          await onCompletion()
        }
      } else {
        if (result.errors == MpinErrors.OLD_MPIN_MATCH) {
          // on old mpin delete mpin ref id and prev  mpin token if available, so that get api token can be triggered.
          let verifiedChallenges = { ...authStore.verifiedChallenges }
          // verifiedChallenges.mpinRefId = null
          dispatch(setVerifiedChallenges({ verifiedChallenges }))
          dispatch(setSetMpinApiToken({ apiToken: null }))
          setOldMpinMatchedCounter(oldMpinMatchedCounter + 1)
          setError(
            `Your new ${mpinWord} should be different than the previous ${oldMpinMatchCount} ${mpinWord}`,
          )
          setSubmitLoading(false)
          return
        } else {
          handleFailureState()
          await setMpinStore.onSetMpinFailure(result?.errors)
          consoleError(result?.errors)
        }
        dispatch(setSetMpinApiToken({ apiToken: null }))
      }
    } catch (error) {
      consoleError(error)
      dispatch(setSetMpinApiToken({ apiToken: null }))
      // handleFailureState()
    }

    setSubmitLoading(false)
  }

  const handleFailureState = () => {
    bottomSheetContext.openBottomSheet(
      BottomSheetType.FAILURE,
      t("mpin.someOtherErrorMsg"),
      t("mpin.updateMpinErrMsg"),
      null,
      null,
      () => setMpin(),
    )
  }

  const onSetMpinAuthSuccess = async apiToken => {
    setRedirectionLoader(false)
    // set api token to be used in set mpin api
    dispatch(setSetMpinApiToken({ apiToken }))
    // navigate back to set mpin screen after auth
    navigate("/Auth/SetMpin", { replace: true, state: { isChangeMpin } })
  }

  const onSetMpinAuthFailure = async (error, message) => {
    // first call failure method from store
    await setMpinStore.onSetMpinFailure(error, message)
    // then clear the store
    dispatch(clearSetMpinState())
  }

  const onSetMpinAuthCancel = async message => {
    await setMpinStore.onSetMpinCancel(message)
    dispatch(clearSetMpinState())
  }

  const onCompletion = async () => {
    await setMpinStore.onSetMpinSuccess()
    dispatch(clearSetMpinState())
  }

  const onCancel = async () => {
    await setMpinStore.onSetMpinCancel()
    dispatch(clearSetMpinState())
  }

  const onSetMpinSuccessSubmit = async () => {
    if (isTncChecked) {
      if (
        authStore.afaDetails.config?.isMpinEverSet?.result
          ? setMpinConfig.tncActionSubsequent ===
            SetMpinTncActions.ENABLE_DOMESTIC_ONLINE
          : setMpinConfig.tncAction === SetMpinTncActions.ENABLE_DOMESTIC_ONLINE
      ) {
        await handleApiAuth({
          apiId: ApiIds.UPDATE_CARD_CONTROLS,
          onAuthSuccess: onEnableDomesticOnlineSuccess,
          onAuthFailure: onCompletion,
          onAuthCancel: onCompletion,
          otpReason: t("mpin.enableDomesticTrxns"),
          mpinReason: t("mpin.enableDomesticTrxns"),
          version: config?.version,
          bottomSheetContext: bottomSheetContext,
        })
      } else if (
        authStore.afaDetails.config?.isMpinEverSet?.result
          ? setMpinConfig.tncActionSubsequent ===
            SetMpinTncActions.ACTIVATE_CARD
          : setMpinConfig.tncAction === SetMpinTncActions.ACTIVATE_CARD
      ) {
        await handleApiAuth({
          apiId: ApiIds.ACTIVATE_CARD,
          onAuthSuccess: onActivateCardSuccess,
          onAuthFailure: onCompletion,
          onAuthCancel: onCompletion,
          otpReason: t("mpin.activateCard"),
          mpinReason: t("mpin.activateCard"),
          navigate,
          version: config?.version,
          bottomSheetContext: bottomSheetContext,
        })
      } else {
        await onCompletion()
      }
    } else {
      await onCompletion()
    }
  }

  useEffect(() => {
    if (pin.length === mpinSize) {
      focusFirst()
    }
  }, [pin])

  const onEnableDomesticOnlineSuccess = async apiToken => {
    try {
      const response = await CardService.updateCardControls(
        apiToken,
        user.cards[0]?.id,
        {
          updateCardControls: [
            {
              txnTags: {
                location: "DOMESTIC",
                txnType: "RETAIL",
                txnChannel: "ECOMMERCE",
              },
              isEnabled: true,
            },
          ],
        },
      )
      const result = response?.data

      if (result?.success) {
        dispatch(setCards({ cards: [result.data] }))
      } else {
        consoleError(
          "Failed to enable domestic online transaction after set mpin. Error: " +
            result.errors,
        )
      }
    } catch (error) {
      consoleError(
        "An exception occurred while enabling domestic online transaction after set mpin. Error: " +
          error,
      )
    }

    await onCompletion()
  }

  const onActivateCardSuccess = async apiToken => {
    try {
      // when activating card from tnc action, as of now activation code is not needed
      const response = await CardService.activateCard(
        apiToken,
        user.cards[0]?.id,
      )
      const result = response?.data

      if (result?.success) {
        const tempCard = { ...user.cards?.[0] }
        tempCard.isPhysicalCardActivated = true
        dispatch(setCards({ cards: [tempCard] }))
      } else {
        consoleError(
          "Failed to activate card after set mpin. Error: " + result.errors,
        )
      }
    } catch (error) {
      consoleError(
        "An exception occurred while activating card after set mpin. Error: " +
          error,
      )
    }

    await onCompletion()
  }
  if (redirectionLoader) return <AuthLoader />
  return loading ? (
    <></>
  ) : showIntro ? (
    <SetMpinIntro_v3
      setMpinConfig={setMpinConfig}
      onSubmit={() => {
        captureEvents({ eventName: EventName.WELCOME_PAGE_CTA, metadata: {} })
        setIsIntroDone(true)
      }}
    />
  ) : showSetMpinSuccess ? (
    <SetMpinSuccess_v3
      timeoutCallback={() => navigate("/")}
      timeoutDuration={6000}
    />
  ) : (
    <div>
      <Header
        onBack={async () => {
          navigate("/")
        }}
        text={
          isMpinEverSet
            ? `${t("mpin.resetMpinSteps", { stepNumber: stepNumber, totalAuthSteps: totalAuthSteps })}`
            : `${t("mpin.authSteps", { stepNumber: stepNumber, totalAuthSteps: totalAuthSteps })}`
        }
      />
      {/* <ActivationAuthHeader_v3 type='set-mpin' /> */}:
      <div>
        <div>
          <div
            className='v3-verify-mpin-title'
            style={{ color: theme.v3.cssVars.primary.color1 }}
          >
            {isMpinEverSet
              ? `${t("mpin.setNewMpin")}`
              : `${t("mpin.lastStepSetMpin")}`}
          </div>
          <div className='v3-setMpin-info-widget'>
            {!isMpinEverSet && (
              <div
                className='v3-setMpin-info'
                onClick={() => bottomSheetContext.closeBottomSheet(null)}
              >
                {t("mpin.mpinPatternWarningText")}
              </div>
            )}
          </div>
        </div>

        <div className='v3-reset-mpin-text-title'>Enter {mpinWord}</div>
        <div
          className='v3-otp-input-bottom-sheet-content'
          style={{ justifyContent: "start" }}
        >
          <OtpInput
            isValid={!error}
            length={mpinSize}
            onChange={e => {
              setPin(e)
            }}
            isHashed
            isSetFlow={true}
            ref={resetMpinInput1}
          />
        </div>

        <div className='v3-reset-mpin-text-title'>
          Confirm{isMpinEverSet && " New "} {mpinWord}
        </div>
        <div className='v3-otp-input-bottom-sheet-content'>
          <OtpInput
            isValid={!error}
            length={mpinSize}
            onChange={e => {
              setError("")
              setConfirmPin(e)
            }}
            isHashed
            isSetFlow={true}
            maxInputSize={mpinSize}
            ref={resetMpinInput2}
            goToPreviousInput={focusLast}
          />
        </div>
      </div>
      {error && <Error_v3 errorMessage={error} />}
      <div className='v3-setMpin-cta-container1'>
        <DividerV3 />
        <div
          className='setMpin-cta-tnc '
          style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
        >
          {showTnc && (
            <div
              className='setMpin-tnc'
              style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
            >
              <div
                className='setMpin-tnc-container'
                style={{
                  backgroundColor:
                    config?.version === PwaVersions.V2 ||
                    config?.version === PwaVersions.V2_1
                      ? theme.widgetBackgroundColor
                      : theme.v3.cssVars.leadingWhite,
                }}
              >
                <div
                  onClick={() => setIsTncChecked(!isTncChecked)}
                  className='setMpin-tnc-checkbox'
                >
                  <Checkbox
                    marginLeft='-3px'
                    {...styles.tncCheckBox}
                    checkmarkClassName={
                      isTncChecked
                        ? "setMpin-tnc-checkMark-checked"
                        : "setMpin-tnc-checkMark-notchecked"
                    }
                    isChecked={isTncChecked}
                    backgroundColor={
                      isTncChecked ? theme.v3.rawColors.secondary.color1 : null
                    }
                  />
                </div>
                <div
                  className='setMpin-tnc-text'
                  style={{ color: theme.v3.cssVars.tertiaryNeutral.color2 }}
                >
                  <EmbeddedLink
                    text={
                      authStore.afaDetails.config?.isMpinEverSet?.result
                        ? setMpinConfig.tncTextSubsequent
                        : setMpinConfig.tncText
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <ColorButton
          text={"Confirm"}
          margin='auto'
          isDisable={
            (showTnc && !isTncChecked) ||
            pin.length !== mpinSize ||
            confirmPin.length !== mpinSize
          }
          onPress={setMpin}
          isLoading={submitLoading}
        />
      </div>
    </div>
  )
}

export default SetMpin_v3
