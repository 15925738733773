import React, { useState } from "react"
import { useSelector } from "react-redux"
import { SwiperSlide, Swiper } from "swiper/react"
import CampaignOfferCard from "../../../../../OffersList/v3/Components/campaignOfferCard"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { getDifferenceInDays } from "../../../../../../utils/functions"

const MilestoneOffer = ({ campaignOffers }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const handleViewAllClick = path => {
    navigate(path)
  }

  return (
    campaignOffers &&
    campaignOffers.length > 0 && (
      <div className='v3-offers-section-2'>
        <div className='v3-offers-section-header'>
          <div className='v3-offers-section-header-title'>
            {t("offers.milestoneOffers")}
          </div>
          <div
            className='v3-offers-section-header-cta'
            onClick={() => handleViewAllClick("/CampaignOffers")}
          >
            {t("offers.ViewAll")}
          </div>
        </div>
        <div
          className='offer-carouselProvider'
          style={{ paddingLeft: 0, marginLeft: "20px" }}
        >
          <Swiper
            slidesPerView={campaignOffers.length > 1 ? 1.5 : 1.2}
            centeredSlides={false}
            breakpoints={{
              320: { slidesPerView: 1.1 },
              360: { slidesPerView: 1.15 },
              390: { slidesPerView: 1.25 },
              412: { slidesPerView: 1.3 },
              420: { slidesPerView: 1.35 },
              450: { slidesPerView: 1.45 },
            }}
            style={{ overflow: "visible" }}
          >
            {campaignOffers
              ?.slice(
                0,
                screen?.home?.homeMiscellaneous?.milestoneOoffersListLength ||
                  3,
              )
              ?.map((benefit, index) => {
                let offerExpiry = getDifferenceInDays(benefit?.activeTo)
                return (
                  <SwiperSlide>
                    <div
                      style={{
                        paddingTop: "5px",
                        // paddingLeft: index === 0 && "4px",
                      }}
                    >
                      {/* {offerExpiry < 8 && offerExpiry > 0 && ( */}
                      {/* <div
                        className='v3-allCampaignOffers-campaignOfferCard-badge'
                        style={{ paddingTop: "4px" }}
                      >
                        <img src={cardBadge} alt='badge' />
                        <div
                          className={`v3-allCampaignOffers-campaignOfferCard-badge-expires ${offerExpiry > 1 ? "v3-allCampaignOffers-campaignOfferCard-badge-expires-inDays" : ""}`}
                        >
                          Expires{" "}
                          {offerExpiry < 1
                            ? "Today"
                            : `in ${offerExpiry.toFixed(0)} ${offerExpiry.toFixed(0) == 1 ? "d" : "d"}`}
                        </div>
                      </div> */}
                      {/* )} */}
                      <CampaignOfferCard
                        key={index}
                        offer={benefit}
                        length={campaignOffers.length}
                      />
                    </div>
                  </SwiperSlide>
                )
              })}
          </Swiper>
        </div>
      </div>
    )
  )
}

export default MilestoneOffer
