import * as Sentry from "@sentry/react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./styles.css"
import CryptoJs from "crypto-js"
import queryString from "query-string"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
import CommonService from "../src/services/CommonService"
import { setAfaDetails, setDeviceId } from "../src/store/actions/Auth"
import {
  setClientParams,
  setCssLoaded,
  setIssuer,
  setOnboardingUrl,
  setRedirectUrl,
  setRepaymentDetails,
  setSession,
  setSessionInactiveExpiry,
  setdisabledFunctionsByBlockCode,
} from "../src/store/actions/Session"
import { setDesign } from "../src/store/actions/Theme"
import {
  setAggregateRewardsSummary,
  setEmiDetails,
  setReplacementFee,
  setUser,
  setactivationCodeFromURL,
} from "../src/store/actions/User"
import * as analytics from "../src/utils/analytics"
import {
  addCssFromUrl,
  addJsFromUrl,
  consoleError,
  getEnumsFromAU_BlockCodes,
  getEnumsFromFederal_BlockCodes,
  getUnion,
  retrieveHTMLFromTxt,
} from "../src/utils/functions"
import ApiIds from "./auth/ApiIds"
import Preloader_v2 from "./components/PreLoader/preloader_v2"
import { showToast1 } from "./components/core/toast"
import SessionExpired from "./components/messages/SessionExpired"
import ErrorScreen from "./components/messages/errorScreen"
import { addCoreStyles } from "./core/styles"
import useIdle from "./hooks/useIdle"
import ExternalRoutes from "./routes/ExternalRoutes"
import Paths from "./routes/Paths"
import Routes from "./routes/Routes"
import AuthService from "./services/AuthService"
import CardService from "./services/CardService"
import PublicService from "./services/PublicService"
import { setConfigState } from "./store/actions/Config"
import { setFeatureFlags } from "./store/actions/FeatureFlags"
import { setScreenState } from "./store/actions/Screen"
import { setMpinMetaData, setSetMpinState } from "./store/actions/SetMpin"
import { handleApiAuth } from "./utils/auth"
import {
  AuthChallenges,
  DisplayConfig,
  EXTERNAL_PATH_ROOT,
  PHYSICAL_CARD_ACTIVATION_PATH,
  ProgramTypes,
  TransactionDateTypes,
  offerType,
} from "./utils/constants"
import {
  AU_BlockCodes,
  AuthenticationType,
  Federal_BlockCodes,
  Issuers,
  PwaVersions,
} from "./utils/enums"
import AccountService from "./services/AccountService"
import {
  clearBenefits,
  setCampaignBenefits,
  setCvpBenefits,
  setMerchantBenefits,
  setThirdPartyBenefits,
} from "./store/actions/Benefits"
import GlobalV3Theme from "./components/Theme"
import { BottomSheetProvider } from "./components/auth/BottomSheetContext"
import PwaRedirectionLoader from "./components/core/PwaRedirectionLoader"
import View from "./nativeBaseReplacements/View"
import { useToast } from "./nativeBaseReplacements/useToast"

const getProgramTypeFromSession = programType => {
  switch (programType) {
    case "CreditCard":
      return ProgramTypes.CREDIT_CARD
    case "Paylater":
      return ProgramTypes.PAY_LATER
    case "Prepaid":
      return ProgramTypes.PREPAID
    default:
      return ProgramTypes.OTHER
  }
}

function App() {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const nativeToast = useToast()

  const session = useSelector(state => state.session)
  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)
  const featureFlags = useSelector(state => state.featureFlags)
  const screen = useSelector(state => state.screen)
  const user = useSelector(state => state.user)
  const benefits = useSelector(state => state.benefits)

  const mpinWord = screen?.mpin?.setMpin?.mpinCharacterCase || "mPIN"
  const [loading, setLoading] = useState(true)
  const [preloader, setPreloader] = useState(true)
  const [loaderAnimation, setLoaderAnimation] = useState(false)
  const [error, setError] = useState(false)
  const [isExternalPath, setIsExternalPath] = useState(false)
  const [sessionLoaded, setSessionLoaded] = useState(false)
  const [activationCode, setActivationCode] = useState("")
  const [v3ThemeConfig, setV3ThemeConfig] = useState()
  const sessionToken = localStorage.getItem("sessionToken") || null
  let sessionTokenCount = localStorage.getItem("sessionTokenCount") || 0
  let isNavigated = false
  useIdle(session.pwaSessionInActiveTimeoutMinutes * 60 * 1000, sessionLoaded)

  const queryParams = queryString.parse(location.search)

  const versioned_preloader = version => {
    switch (version) {
      case PwaVersions.V2:
        return <Preloader_v2 />
      case PwaVersions.V2_1:
        return <Preloader_v2 />
      case PwaVersions.V3:
        return <PwaRedirectionLoader loaderAnimation={loaderAnimation} />
      default:
        return null
    }
  }

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  useEffect(() => {
    // init analytics modules
    // analytics.initZipy()
    analytics.initMixpanel()
    analytics.initAmplitude()

    if (
      session?.disabledFunctionsByBlockCode?.includes(
        AU_BlockCodes.LOGIN_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode?.includes(
        AU_BlockCodes.REGISTRATION_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode?.includes(
        AU_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode?.includes(
        AU_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
      )
    ) {
      navigate("/blocked")
      isNavigated = true
    }

    if (
      session?.disabledFunctionsByBlockCode?.includes(
        Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode?.includes(
        Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
      )
    ) {
      navigate("/blocked")
      isNavigated = true
    }

    // expand elements to full height (to show full sized colored background in screens)

    /*
    For making child div expand to full remaining height -
    on parent: display:flex; flex-direction:column;
    on child: flex: 1;
    */

    const html = document.getElementsByTagName("html")[0]
    const body = document.getElementsByTagName("body")[0]
    const root = document.getElementById("root")

    // TODO: find source of this element
    const rootFirstChild = root.firstChild

    html.style.minHeight = "100%"
    html.style.display = "flex"
    html.style.flexDirection = "column"

    body.style.flex = "1"
    body.style.display = "flex"
    body.style.flexDirection = "column"

    root.style.flex = "1"
    root.style.display = "flex"
    root.style.flexDirection = "column"

    rootFirstChild.style.flex = "1"
    rootFirstChild.style.display = "flex"
    rootFirstChild.style.flexDirection = "column"
  }, [])

  useEffect(() => {
    ;(async () => {
      if (
        location.pathname.includes(`/${EXTERNAL_PATH_ROOT}`) ||
        location.pathname.includes(`/${PHYSICAL_CARD_ACTIVATION_PATH}`) // physical card activation path doesn't have external root
      ) {
        setIsExternalPath(true)

        if (queryParams.redirectUrl) {
          dispatch(
            setRedirectUrl({
              redirectUrl:
                queryParams.redirectUrl ||
                localStorage.getItem("redirectUrl") ||
                "/",
            }),
          )
        }
        if (queryParams.onboardingUrl) {
          dispatch(
            setOnboardingUrl({
              onboardingUrl:
                queryParams.onboardingUrl ||
                localStorage.getItem("onboardingUrl") ||
                "/",
            }),
          )
        }
      } else {
        // set redirect url
        dispatch(
          setRedirectUrl({
            redirectUrl:
              queryParams.redirectUrl ||
              localStorage.getItem("redirectUrl") ||
              "/",
          }),
        )
        // set session
        let sessionTokenIsPromoted
        if (queryParams?.applicationId) {
          localStorage.setItem("applicationId", queryParams?.applicationId)
        }
        if (
          queryParams.sessionToken &&
          queryParams.sessionToken !== localStorage.getItem("sessionToken")
        ) {
          // if url has session token and it is different from stored token then new session has started
          let tenantId = queryParams.tenantId // for every new session tenantId should also come.
          try {
            // get device token
            const response = await AuthService.getDeviceToken({
              sessionToken: queryParams.sessionToken,
              tenantId: tenantId,
            })
            const result = response.data

            if (result?.success) {
              console.log(
                "entering the device token call block",
                result?.data?.isPromoted,
                result?.data?.isPromoted ? "true" : "false",
              )
              localStorage.setItem(
                "sessionTokenIsPromoted",
                result?.data?.isPromoted ? "true" : "false",
              )
              // decrypt encryption key using session token
              const encryptionKey = result.data.encryptionKey
              const ivLength = 16
              const ivString = encryptionKey.substring(0, ivLength)
              const encryptedKeyString = encryptionKey.slice(ivLength)

              const key = CryptoJs.enc.Base64.parse(queryParams.sessionToken)
              const iv = CryptoJs.enc.Base64.parse(ivString)
              const encoder = CryptoJs.enc.Utf8
              const decryptedEncryptionKey = CryptoJs.AES.decrypt(
                encryptedKeyString,
                key,
                { iv },
              ).toString(encoder)

              // set session in store
              localStorage.setItem("tenantId", result.data.tenantId)
              dispatch(
                setSession({
                  sessionToken: queryParams.sessionToken,
                  deviceToken: result.data.deviceToken,
                  encryptionKey: decryptedEncryptionKey,
                  encryptionIv: ivString,
                }),
              )
              if (!sessionToken && queryParams.sessionToken) {
                sessionTokenCount = Number(sessionTokenCount) + 1
                localStorage.setItem("sessionTokenCount", sessionTokenCount)
              } else if (
                queryParams.sessionToken &&
                sessionToken &&
                queryParams.sessionToken !== sessionToken
              ) {
                sessionTokenCount = Number(sessionTokenCount) + 1
                localStorage.setItem("sessionTokenCount", sessionTokenCount)
              }
            } else {
              consoleError(result?.errors)
              setError(true)
              return
            }
          } catch (error) {
            consoleError(error)
            setError(true)
            return
          }
        } else {
          // else continue with stored session
          dispatch(
            setSession({
              sessionToken: localStorage.getItem("sessionToken"),
              deviceToken: localStorage.getItem("deviceToken"),
              encryptionKey: localStorage.getItem("encryptionKey"),
              encryptionIv: localStorage.getItem("encryptionIv"),
            }),
          )
        }

        // check for device id
        const deviceId = localStorage.getItem("deviceId")
        sessionTokenIsPromoted =
          localStorage.getItem("sessionTokenIsPromoted") || "true"
        console.log(sessionTokenIsPromoted, "sessionTokenIsPromoted")
        try {
          // first call app level apis - session info and design config
          const sessionInfoPromise = CommonService.getSessionInfo()
          const designPromise = CommonService.getDesignConfig()

          const promises = [sessionInfoPromise, designPromise]

          // verify deviceId
          if (deviceId) {
            const deviceIdPromise = CommonService.verifyDeviceId({
              deviceToken: deviceId,
            })
            promises.push(deviceIdPromise)
          }

          const responses = await Promise.all(promises)

          const sessionInfoResult =
            sessionTokenIsPromoted === "true" ? responses[0].data : {}
          localStorage.setItem(
            "accountId",
            sessionInfoResult?.data?.account?.id,
          )
          const designConfigResult = responses[1].data

          let mpinMetadata =
            sessionTokenIsPromoted === "true"
              ? sessionInfoResult?.data?.challengeMetadata?.MPIN
              : null
          if (mpinMetadata) {
            dispatch(
              setMpinMetaData({
                mpinMetadata: mpinMetadata,
              }),
            )
          }

          if (deviceId) {
            const deviceIdResult = responses[2].data
            if (deviceIdResult?.success) {
              dispatch(setDeviceId({ deviceId }))
            }
          }

          if (sessionTokenIsPromoted === "true") {
            console.log("a", sessionTokenIsPromoted)
            //API for Block Codes
            let blockCodes = []

            let blockCodeInfo = sessionInfoResult?.data?.blockCodeInfo

            let accountblockCodes = blockCodeInfo?.account?.blockCodes

            let cardBlockCodesArray = blockCodeInfo.cards

            let cardBlockCodes = []

            for (let ind = 0; ind < cardBlockCodesArray?.length; ind++) {
              cardBlockCodes.push(cardBlockCodesArray[ind].blockCode)
            }

            let disabledFunctions = []
            if (sessionInfoResult?.data?.issuerName === Issuers.AU_BANK) {
              let union = getUnion(accountblockCodes, cardBlockCodes)
              blockCodes = union
              disabledFunctions = getEnumsFromAU_BlockCodes(blockCodes)
            }

            if (sessionInfoResult?.data?.issuerName === Issuers.FEDERAL_BANK) {
              let accountStatus = sessionInfoResult.data.account.status
              if (accountStatus === "ACTIVE" || accountStatus === "DORMANT") {
                let union = getUnion(accountblockCodes, cardBlockCodes)
                blockCodes = union
                disabledFunctions = getEnumsFromFederal_BlockCodes(blockCodes)
              } else {
                navigate("/blocked")
                isNavigated = true
              }
            }

            const blockedAccountStatuses = [
              "CLOSED",
              "SUSPENDED",
              "FORCED_SUSPENDED",
              "PENDING_CLOSURE",
              "CHARGE_OFF",
            ]
            if (
              sessionInfoResult?.data?.issuerName !== Issuers.FEDERAL_BANK &&
              sessionInfoResult?.data?.issuerName !== Issuers.AU_BANK
            ) {
              if (
                blockedAccountStatuses.includes(
                  sessionInfoResult.data.account.status,
                )
              ) {
                navigate("/blocked")
              }
            }

            if (
              disabledFunctions.includes(AU_BlockCodes.LOGIN_DISABLE) ||
              disabledFunctions.includes(AU_BlockCodes.REGISTRATION_DISABLE) ||
              disabledFunctions.includes(
                AU_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
              ) ||
              disabledFunctions.includes(
                AU_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
              )
            ) {
              navigate("/blocked")
              isNavigated = true
            }

            if (
              disabledFunctions.includes(
                Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
              ) ||
              disabledFunctions.includes(
                Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
              )
            ) {
              navigate("/blocked")
              isNavigated = true
            }

            if (sessionInfoResult?.success) {
              dispatch(
                setIssuer({
                  issuer: sessionInfoResult.data?.issuerName,
                }),
              )
              dispatch(
                setdisabledFunctionsByBlockCode({
                  disabledFunctionsByBlockCode: disabledFunctions,
                }),
              )

              // get session info
              // we cannot rely on summary api to get customer and account as summary api may have auth and auth may
              // require to set mpin which in turn requires customer

              // set user in store
              dispatch(
                setUser({
                  customer: sessionInfoResult.data?.customer,
                  cards: sessionInfoResult.data?.cards,
                  account: sessionInfoResult.data?.account,
                  programType: getProgramTypeFromSession(
                    sessionInfoResult.data?.programType,
                  ),
                  enablePciWidget: sessionInfoResult.data?.enablePciWidget,
                }),
              )

              if (queryParams.activationCode) {
                dispatch(
                  setactivationCodeFromURL({
                    activationCode: queryParams.activationCode,
                  }),
                )
              }
              dispatch(
                setReplacementFee({
                  cardReplacementFee: sessionInfoResult?.data?.cards
                    ? sessionInfoResult?.data?.cards[0]?.replacementFee
                    : 0,
                }),
              )
            } else {
              consoleError(sessionInfoResult?.errors)
              setError(true)
              // don't proceed further
              return
            }
          } else {
            const applicationDetailsResponse =
              await PublicService.getApplicationDetails(
                localStorage.getItem("applicationId"),
              )
            if (applicationDetailsResponse.status === 200) {
              console.log(applicationDetailsResponse.data.data.mobileNumber)
              dispatch(
                setUser({
                  customer: {
                    mobileNumber:
                      applicationDetailsResponse.data.data.mobileNumber,
                    mobileCountryCode: "91",
                  },
                  cards: {},
                  account: {},
                  programType: {},
                  enablePciWidget: false,
                }),
              )
            } else {
              consoleError(sessionInfoResult?.errors)
              setError(true)
              // don't proceed further
              return
            }
          }

          const sessionInfo = sessionInfoResult?.data
          // get design config
          if (designConfigResult?.success) {
            const design = designConfigResult?.data
            dispatch(setScreenState({ ...design?.screenConfig }))

            let headerData = design?.screenConfig?.header
            //Getting content from text file, and setting it to headerFromFile
            let rightHeadingFromFile = null
            let leftHeadingFromFile = null
            let centertHeadingFromFile = null
            if (headerData.isEnabled) {
              rightHeadingFromFile = await retrieveHTMLFromTxt(
                headerData?.rightElementUrl,
              )
              leftHeadingFromFile = await retrieveHTMLFromTxt(
                headerData?.leftElementUrl,
              )
              centertHeadingFromFile = await retrieveHTMLFromTxt(
                headerData?.centerElementUrl,
              )
            }

            // common values
            const version = design?.version || PwaVersions.V1
            const jsonConfig = design?.jsonConfig || {}
            const color1 = design?.primaryColor
            const color2 = design?.secondaryColor || "#F8F8F8"
            const fontFamily = theme?.fontFamily
            const toastTextColor = design?.toastTextColor || "#FFFFFF"
            const toastBackgroundColor =
              design?.toastBackgroundColor || "#303030"

            dispatch(
              setConfigState({
                version,
                jsonConfig,
                auth: design?.auth,
                cardDetailsBannerImageUrl: design?.cardDetailsBannerImageUrl,
                clientHeaderColor: headerData?.color,
                clientHeaderCenterText: centertHeadingFromFile,
                clientHeaderRightElement: rightHeadingFromFile,
                clientHeaderLeftElement: leftHeadingFromFile,
                clientHeaderEnabled: headerData?.isEnabled,
              }),
            )
            dispatch(setFeatureFlags({ ...(design?.featureFlag || {}) }))

            const themeConfig = {
              ...design,
              color1: color1 || "#0D2950",
              color2: color2 || "#FFFFFF",
              cardPrimaryColor: design?.iconsColor || "#0D2950",
              toastColor: toastTextColor,
              toastBgColor: toastBackgroundColor,
              cardDesignUrl: design?.virtualCardDesignUrl,
              cardOrientation: design?.virtualCardOrientation?.toUpperCase(),
              virtualCardDetailsImage: design?.virtualCardDetailsUrl,
              title: design?.pwaHomepageTitle || "",
              titleColor: design?.headerTextColor || "#FFFFFF",
              deskTopBackgroundColor:
                design?.deskTopBackgroundColor || "#FFFFFF",
              headerBackgroundColor: design?.headerBackgroundColor || "#0D2950",
              backgroundColor: design?.backgroundColor || "#FFFFFF",
              widgetBackgroundColor: design?.widgetBackgroundColor || "#FFFFFF",
              appTextColor: design?.textColor || "#303030",
              transactionDateType:
                design?.transactionDateType ||
                TransactionDateTypes.TRANSACTION_DATE,
              iconOverlay: design?.screenConfig?.home?.iconOverlay || "NONE",
              iconOverlayImageUrl:
                design?.screenConfig?.home?.iconOverlayImageUrl || "",
              virtualCardAnimation:
                design?.screenConfig?.home?.virtualCardAnimation || "NONE",
              transactionViewType:
                design?.screenConfig?.home?.transaction?.transactionViewType ||
                "ALL",
            }
            // set design details
            dispatch(setDesign(themeConfig))

            setV3ThemeConfig(themeConfig)

            // apply styles for core components
            addCoreStyles({
              version,
              color1,
              color2,
              fontFamily,
              toastTextColor,
              toastBackgroundColor,
            })

            // apply styles from css file
            if (design?.cssFileUrl) {
              addCssFromUrl(design.cssFileUrl, () => {
                dispatch(
                  setCssLoaded({
                    isCssLoaded: true,
                  }),
                )
              })
            } else {
              dispatch(
                setCssLoaded({
                  isCssLoaded: true,
                }),
              )
            }
            if (design?.jsFileUrl) {
              addJsFromUrl(design?.jsFileUrl)
            }
            dispatch(
              setSessionInactiveExpiry({
                pwaSessionInActiveTimeoutMinutes:
                  design?.pwaSessionInActiveTimeoutMinutes || 2,
              }),
            )
            localStorage.setItem("version", version)
            localStorage.setItem(
              "backgroundColor",
              design?.backgroundColor || "#FFFFFF",
            )
            design?.screenConfig?.preLoader?.isHfBrandingEnabled
              ? localStorage.setItem(
                  "hfStatus",
                  design?.screenConfig?.preLoader?.isHfBrandingEnabled,
                )
              : localStorage.removeItem("hfStatus")
            design?.screenConfig?.preLoader?.bannerImageUrl
              ? localStorage.setItem(
                  "preLoaderBanner",
                  design?.screenConfig?.preLoader?.bannerImageUrl,
                )
              : localStorage.removeItem("preLoaderBanner")
            design?.screenConfig?.preLoader?.bankName
              ? localStorage.setItem(
                  "issuerBankName",
                  design?.screenConfig?.preLoader?.bankName,
                )
              : localStorage.removeItem("issuerBankName")
            design?.screenConfig?.preLoader?.bankLogo
              ? localStorage.setItem(
                  "issuerImage",
                  design?.screenConfig?.preLoader?.bankLogo,
                )
              : localStorage.removeItem("issuerImage")
            design?.screenConfig?.preLoader?.loaderTimeInSec
              ? localStorage.setItem(
                  "preLoaderTime",
                  design?.screenConfig?.preLoader?.loaderTimeInSec,
                )
              : localStorage.removeItem("preLoaderTime")

            // set emi details
            dispatch(
              setEmiDetails({
                enableManageEMI: design?.enableManageEmi || false,
                enableBalanceEMI: design?.enableBalanceEmi || false,
                enableTransactionEMI: design?.enableTransactionEmi || false,
              }),
            )

            // set repayment details
            dispatch(
              setRepaymentDetails({
                repaymentFlow: Boolean(design?.enableRepaymentFlow),
                repaymentUrl: queryParams.repaymentUrl || design?.repaymentUrl,
              }),
            )
          } else {
            // if didn't get design then default values to be used
          }

          // after app level apis get afa details for rest of the apis to work
          const afaResponse = await CommonService.getAfaDetails({
            mpinMetaData: {
              customerId:
                sessionTokenIsPromoted === "true"
                  ? sessionInfo?.customer?.id
                  : null,
            },
            apiTags: Object.values(ApiIds),
          })
          const afaResult = afaResponse.data

          if (afaResult?.success) {
            // set afa details
            dispatch(
              setAfaDetails({
                afaDetails: {
                  config: afaResult.data,
                },
              }),
            )

            let isMpinNeeded = false
            for (const value of Object.values(afaResult.data)) {
              if (!isMpinNeeded && value.result) {
                if (
                  Array.isArray(value.challenges) &&
                  value.challenges.find(
                    data => data.challenge === AuthChallenges.MPIN,
                  )
                ) {
                  isMpinNeeded = true
                } else if (Array.isArray(value.dynamicChallenges)) {
                  for (const flow of value.dynamicChallenges) {
                    if (
                      Array.isArray(flow.challenges) &&
                      flow.challenges.find(
                        data => data.challenge === AuthChallenges.MPIN,
                      )
                    ) {
                      isMpinNeeded = true
                    }
                  }
                }
              }
            }

            const isMpinSet =
              sessionTokenIsPromoted === "true"
                ? afaResult.data?.isMpinSet?.result
                : false

            const isMpinExpired =
              sessionTokenIsPromoted === "true"
                ? afaResult.data?.isMpinExpired?.result
                : false

            // For PWA V3 -> access restricted page.
            if (
              isMpinExpired &&
              isMpinNeeded &&
              designConfigResult.data.version === PwaVersions.V3
            ) {
              navigate("/AttemptExhausted", {
                state: {
                  type: AuthenticationType.MPIN_EXPIRED,
                },
              })
              isNavigated = true
            }

            //For pwa v2 normal set mpin flow.
            if (isMpinNeeded && (!isMpinSet || isMpinExpired)) {
              // if mpin is needed but is not set
              dispatch(
                setSetMpinState({
                  onSetMpinSuccess: async () => {
                    // navigate back
                    if (!isNavigated) {
                      ;(await sessionTokenIsPromoted) === "true"
                        ? afterAfaCompletion(sessionInfo)
                        : console.log()
                      await loginToPwa()
                    }
                  },

                  onSetMpinFailure: async () => {
                    if (!isNavigated) {
                      toast(`Failed to set ${mpinWord}`)
                      navigate("/Error", { replace: true })
                    }
                  },

                  onSetMpinCancel: async () => {
                    if (!isNavigated) navigate("/Error", { replace: true })
                  },
                }),
              )

              // navigate to set mpin screen

              if (!isNavigated) {
                navigate("/Auth/SetMpin", {
                  replace: true,
                  state: { isLogin: true },
                })
              }
            } else {
              ;(await sessionTokenIsPromoted) === "true"
                ? afterAfaCompletion(sessionInfo)
                : console.log()
              await loginToPwa()
            }
          } else {
            consoleError(afaResult?.errors)
            setError(true)
            return
          }
          setSessionLoaded(true)
        } catch (error) {
          consoleError(error)
          setError(true)
          return
        }
      }
      setLoaderAnimation(true)
      localStorage.setItem("firstTimeAuth", true)
      setTimeout(() => {
        setLoading(false)
        setPreloader(false)
      }, 400)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (queryParams.clientParams) {
      dispatch(
        setClientParams({
          clientParams: JSON.parse(queryParams.clientParams),
        }),
      )
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (activationCode) {
        setTimeout(async () => {
          await handleApiAuth({
            apiId: ApiIds.PWA_SESSION_INFO_FROM_ACTIVATION_CODE,
            onAuthSuccess: onVerifyCodeAuthSuccess,
            onAuthFailure: onAuthFailure,
            onAuthCancel: onAuthCancel,
            otpReason: "to verify activation code",
            mpinReason: "to verify activation code",
            toast,
            navigate,
          })
        }, 2000)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activationCode])

  const loginToPwa = async () => {
    await handleApiAuth({
      apiId: ApiIds.PWA_LOGIN,
      onAuthSuccess: onPWALoginAuthSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: onAuthCancel,
      otpReason: "to pwa login",
      mpinReason: "to pwa login",
      toast,
      navigate,
    })
  }

  const onPWALoginAuthSuccess = async apiToken => {
    try {
      const response = await AuthService.pwaLogin(apiToken)
      if (response.status == 204) {
        if (location.pathname == "/Auth/Mpin")
          //check if the current page is auth/mpin
          navigate("/")
        else navigate(location.pathname, { replace: true })
      } else {
        consoleError(response.data)
        toast("Login To PWA Failed")
      }
    } catch (error) {
      consoleError(error)
      toast("Login To PWA Failed")
    }
  }
  const onVerifyCodeAuthSuccess = async apiToken => {
    console.log("on verify auth success")
    try {
      const response = await CardService.verifyActivationCode(
        apiToken,
        user?.cards[0]?.id,
        activationCode,
      )
      const result = response?.data

      if (result?.success && result?.data?.accountId === user.account.id) {
        if (!user.enablePciWidget) {
          localStorage.setItem("activationCode", activationCode)
          navigate("/ResetPin", {
            state: { from: "Activation" },
            replace: true,
          })
        }
      } else {
        consoleError(result)
        toast("Please use your verification \nQR code for activation")
      }
    } catch (error) {
      consoleError(error)
      toast("An error occurred while activating")
      navigate("/", { replace: true })
    }
  }

  const afterAfaCompletion = async sessionInfo => {
    // if came back after card activation qr scan in iOS then redirect to qr screen
    const qrLink = window.hyperfaceIosProps?.cardActivationQrLink
    const isQrLinkSet = Boolean(qrLink) && Boolean(qrLink.trim())

    if (isQrLinkSet && location.pathname === "/") {
      // if qr link is set and user is not redirected back from any pci widget
      if (!isNavigated) {
        navigate("/ActivateCard/Scanner")
        return
      }
    }

    // if coming from activation flow
    if (queryParams.isActivate) {
      localStorage.setItem("activationCode", queryParams.activationCode)

      await handleApiAuth({
        apiId: ApiIds.PWA_SESSION_INFO_FROM_ACTIVATION_CODE,
        onAuthSuccess: onVerifyActivationAuthSuccess(sessionInfo),
        onAuthFailure: onAuthFailure,
        onAuthCancel: onAuthCancel,
        otpReason: "to verify activation",
        mpinReason: "to verify activation",
        toast,
        navigate,
      })
    }
  }
  const navigateToPath = isNavigated => {
    if (!isNavigated) {
      navigate(location.pathname, { replace: true })
    }
  }
  const onVerifyActivationAuthSuccess = sessionInfo => async apiToken => {
    try {
      const activationCode = queryParams.activationCode
      const verification = await CardService.verifyActivationCode(
        apiToken,
        sessionInfo.cards[0]?.id,
        activationCode,
      )

      if (
        verification.data?.success &&
        verification.data?.data?.accountId === sessionInfo.account.id
      ) {
        if (!sessionInfo.enablePciWidget) {
          localStorage.setItem("activationCode", activationCode)
          if (!isNavigated) {
            navigate("/ResetPin", { state: { from: "Activation" } })
          }
        } else {
          await handleApiAuth({
            apiId: ApiIds.PCI_SET_PIN_URL,
            onAuthSuccess: onResetPinAuthSuccess(sessionInfo),
            onAuthFailure: onAuthFailure,
            onAuthCancel: onAuthCancel,
            otpReason: "to reset pin",
            mpinReason: "to reset pin",
            toast,
            navigate,
          })
        }
      } else {
        consoleError(verification.data?.errors)
        toast("Please use your verification QR code \nfor activation")
      }
    } catch (error) {
      consoleError(error)
      toast("An error occurred while activating")
    }
    navigateToPath(isNavigated)
  }

  const onResetPinAuthSuccess = sessionInfo => async apiToken => {
    try {
      const activationCode = queryParams.activationCode

      const response = await CardService.getResetPinWidget(
        apiToken,
        sessionInfo.cards[0]?.id,
        {
          callbackUrl:
            [ProgramTypes.PAY_LATER, ProgramTypes.PREPAID].includes(
              getProgramTypeFromSession(sessionInfo.programType),
            ) ||
            ((config?.version === PwaVersions.V2 ||
              config?.version === PwaVersions.V2_1) &&
              screen?.home?.isCardControlsInActivationFlow)
              ? process.env.REACT_APP_URL + Paths.ACTIVATION_CARD_CONTROLS
              : process.env.REACT_APP_URL + Paths.HOME_WITH_ACTIVATE,
        },
      )
      const result = response.data
      if (result?.success) {
        localStorage.setItem("activationCode", activationCode)
        window.location.href = result.data
      } else {
        consoleError(result?.errors)
        toast("An error occurred while setting pin")
      }
    } catch (error) {
      consoleError(error)
      toast("An error occurred while setting pin")
    }

    if (!isNavigated) {
      navigate(location.pathname, { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    console.log(error)
    message && toast(message)
    if (!isNavigated) {
      navigate(location.pathname, { replace: true })
    }
  }

  const onAuthCancel = async message => {
    message && toast(message)
    if (!isNavigated) {
      navigate(location.pathname, { replace: true })
    }
  }

  useEffect(() => {
    if (session.sessionExpired || error) setPreloader(false)
  }, [session, error])

  return (
    // adding NativeBaseProvider in App component so its theme has access to store
    // <NativeBaseProvider>
    <BottomSheetProvider>
      <View bgColor={theme?.deskTopBackgroundColor || "#FFFFFF"}>
        <View
          w='100%'
          maxW={DisplayConfig.MAX_WIDTH}
          marginLeft='auto'
          marginRight='auto'
        >
          {preloader ? (
            versioned_preloader(localStorage.getItem("version"))
          ) : session.sessionExpired ? (
            <SessionExpired />
          ) : error ? (
            <ErrorScreen />
          ) : loading ? (
            <>
              {localStorage.getItem("version") === PwaVersions.V2 ||
              localStorage.getItem("version") === PwaVersions.V2_1 ? (
                <View>
                  <Preloader_v2 />
                </View>
              ) : (
                <></>
              )}
            </>
          ) : isExternalPath ? (
            <ExternalRoutes />
          ) : (
            <Routes />
          )}
          <ToastContainer />
        </View>
      </View>
      <GlobalV3Theme themeConfig={v3ThemeConfig} />
    </BottomSheetProvider>
    // </NativeBaseProvider>
  )
}

export default Sentry.withProfiler(App)
