import React, { useEffect, useRef, useState } from "react"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { BottomSheet } from "react-spring-bottom-sheet"
import { Header } from "../../core"
import { ColorButton } from "../../core/buttons"
import {
  consoleError,
  tempFormatTransactionsData,
} from "../../../utils/functions"
import {
  FilterBottomSheetTabs,
  FilterType,
  REWARD_TRANSACTIONS_PER_PAGE,
  RewardsRedirectionType,
} from "../../../utils/constants"
import AccountService from "../../../services/AccountService"
import Filter from "../../core/FIlter/v3/Filter"
import BottomSheetHeader from "./BottomSheetHeader"
import FiltersBottomSheet from "./FiltersBottomSheet"
import RewardsCashbackBanner from "./RewardsCashbackBanner"
import RewardTransactionListWithBottomSheet from "./RewardTransactionListWithBottomsheet"
import RewardTransactionList from "./RewardTransactionList"
import TransactionListLoader from "../../core/SkeletonLoader/TransactionListLoader"
import InfiniteScroll from "../../core/InfiniteScrollV3"
import { clearCommonState, setCommonState } from "../../../store/actions/Common"

const Rewards_V3 = () => {
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)

  const dispath = useDispatch()
  const hasScrolledRef = useRef(false)

  const [selectedFilter, setSelectedFilter] = useState("")
  const [selectedTab, setSelectedTab] = useState(FilterBottomSheetTabs.CYCLE)
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)
  const [bottomSheetContent, setBottomSheetContent] = useState(null)
  const [isFiltersBottomSheetOpen, setIsFiltersBottomSheetOpen] =
    useState(false)
  const [selectedDuration, setSelectedDuration] = useState(null)
  const [cycles, setCycles] = useState([])

  const rewardRedirectionType = screen?.home?.reward?.redirectionTypesV3

  const {
    secondary: { color1: secondaryColor1 },
    leadingWhite,
  } = theme?.v3?.rawColors

  const rewardsData = rewardRedirectionType?.includes(
    RewardsRedirectionType.LR_TRANSACTIONS,
  )
    ? {
        lifetimeEarned:
          Number(user?.rewardSummary?.metadata?.balance) +
          Number(user?.rewardSummary?.metadata?.redeemed),
        redeemed: Number(user?.rewardSummary?.metadata?.redeemed),
        expired: Number(user?.rewardSummary?.metadata?.lapsed),
      }
    : user?.aggregate

  const transactionFilters = [
    {
      filterName: FilterType.DATE,
      showBtnArrow: true,
      onFilterClick: () => setFilter(FilterType.DATE),
      isActive: selectedDuration?.length !== 0,
    },
    // {
    //   filterName: FilterType.SORT_BY,
    //   showBtnArrow: true,
    //   onFilterClick: () => setFilter(FilterType.SORT_BY),
    //   isActive: selectedFilters.includes(FilterType.SORT_BY),
    // },
    // {
    //   filterName: FilterType.CATEGORIES,
    //   showBtnArrow: true,
    //   onFilterClick: () => setFilter(FilterType.CATEGORIES),
    //   isActive: selectedFilters.includes(FilterType.CATEGORIES),
    // },
    {
      filterName: FilterType.CREDITED,
      showBtnArrow: false,
      onFilterClick: () => setFilter(FilterType.EARNED),
      isActive: selectedFilter === FilterType.EARNED,
    },
    {
      filterName: FilterType.REDEEMED,
      showBtnArrow: false,
      onFilterClick: () => setFilter(FilterType.REDEEMED),
      isActive: selectedFilter === FilterType.REDEEMED,
    },
    {
      filterName: FilterType.REVERSED,
      showBtnArrow: false,
      onFilterClick: () => setFilter(FilterType.REVERSED),
      isActive: selectedFilter === FilterType.REVERSED,
    },
    {
      filterName: FilterType.PENDING,
      showBtnArrow: false,
      onFilterClick: () => setFilter(FilterType.PENDING),
      isActive: selectedFilter === FilterType.PENDING,
    },
    {
      filterName: FilterType.EXPIRED,
      showBtnArrow: false,
      onFilterClick: () => setFilter(FilterType.EXPIRED),
      isActive: selectedFilter === FilterType.EXPIRED,
    },
  ]

  useEffect(() => {
    if (
      !user?.summary ||
      !user?.summary?.lastStatement ||
      !user?.account.id ||
      !config?.version
    )
      return
    const lastStatement = user?.summary?.lastStatement
    const lastStatementBillingCycle = {
      from: moment(lastStatement?.fromDate).format("YYYY-MM-DD"),
      to: moment(lastStatement?.toDate).format("YYYY-MM-DD"),
    }

    let billingCycles = []
    if (lastStatementBillingCycle?.to !== moment().format("YYYY-MM-DD")) {
      billingCycles.push({
        from: moment(lastStatement?.toDate).add(1, "days").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      })
    }

    billingCycles.push(lastStatementBillingCycle)
    ;(async function () {
      const toDate = moment(lastStatementBillingCycle?.to).format("YYYY-MM-DD")
      const fromDate = moment(lastStatementBillingCycle?.to)
        .subtract(210, "days")
        .format("YYYY-MM-DD")

      const statementBillingCyclesResponse =
        await AccountService.getBillingCycles({
          accountId: user?.account?.id,
          from: fromDate,
          to: toDate,
        })
      const result = statementBillingCyclesResponse?.data
      if (result?.success) {
        result?.data?.statements.map((cycle, index) => {
          billingCycles.push({
            from: moment(cycle.fromDate).format("YYYY-MM-DD"),
            to: moment(cycle.toDate).format("YYYY-MM-DD"),
          })
        })
        setCycles(billingCycles)
      }
    })()

    setSelectedDuration(billingCycles?.[0])
  }, [user, config])

  useEffect(() => {
    if (hasScrolledRef.current) {
      dispath(setCommonState({ isRewardsLoaded: true }))
    } else {
      hasScrolledRef.current = true
    }

    return () => {
      dispath(clearCommonState())
    }
  }, [selectedFilter])

  const fetchRewardTransactions = async (
    accountId,
    count,
    offset,
    fromDate,
    toDate,
  ) => {
    if (!selectedDuration) return { success: false }
    try {
      let payload
      let response
      let result

      if (
        rewardRedirectionType?.includes(RewardsRedirectionType.LR_TRANSACTIONS)
      ) {
        payload = {
          id: user.account.id,
          tag: "REWARDS",
          accountId: user.account.id,
          page: 0,
          startDate: selectedDuration?.from,
          endDate: selectedDuration?.to,
        }

        response = await AccountService.getSsoRewards(payload)
        result = response?.data

        if (result?.success) {
          return {
            data: {
              success: true,
              data: {
                transactions: result?.data?.data,
                hasMore: false,
              },
            },
          }
        } else {
          consoleError(response?.error)
          return { success: false }
        }
      } else if (
        rewardRedirectionType?.includes(RewardsRedirectionType.HF_REWARDS)
      ) {
        payload =
          selectedFilter !== ""
            ? {
                accountId: user.account.id,
                fromDate: selectedDuration?.from,
                toDate: selectedDuration?.to,
                count: REWARD_TRANSACTIONS_PER_PAGE,
                offset: offset / REWARD_TRANSACTIONS_PER_PAGE,
                recordTypes: [selectedFilter],
              }
            : {
                accountId: user.account.id,
                fromDate: selectedDuration?.from,
                toDate: selectedDuration?.to,
                count: REWARD_TRANSACTIONS_PER_PAGE,
                offset: offset / REWARD_TRANSACTIONS_PER_PAGE,
              }

        response = await AccountService.getHfRewardTransactions(payload)
        result = response?.data

        if (result?.success) {
          let rewardsData = result?.data?.data

          const benefitIds = rewardsData
            .filter(item => item?.benefitId)
            .map(item => item?.benefitId)

          if (benefitIds?.length) {
            const benefitDetailsResponse = await AccountService.getBulkBenefits(
              {
                benefitIds: benefitIds,
              },
            )

            if (benefitDetailsResponse?.data?.success) {
              rewardsData = rewardsData.map(reward => ({
                ...reward,
                benefitDetails: reward?.benefitId
                  ? benefitDetailsResponse?.data?.data?.find(
                      item => item?.benefitId === reward?.benefitId,
                    )
                  : null,
              }))
            }
          }

          const transactionIds = rewardsData
            .filter(item => item?.sourceTxnId)
            .map(item => item?.sourceTxnId)

          if (transactionIds?.length) {
            const transactionDetailsResponse =
              await AccountService.getBulkTransactions({
                accountId: user?.account?.id,
                transactionIdList: transactionIds,
              })

            if (transactionDetailsResponse?.data?.success) {
              rewardsData = rewardsData.map(reward => ({
                ...reward,
                transactionDetails: reward?.sourceTxnId
                  ? transactionDetailsResponse?.data?.data?.find(
                      item => item?.id === reward?.sourceTxnId,
                    )
                  : null,
              }))
            }
          }

          return tempFormatTransactionsData(
            rewardsData,
            result?.data,
            REWARD_TRANSACTIONS_PER_PAGE,
          )
        } else {
          consoleError(response?.error)
          return { success: false }
        }
      }
    } catch (error) {
      consoleError(error)
      return { success: false }
    }
  }

  const fetchExpiringTransactions = async (
    accountId,
    count,
    offset,
    fromDate,
    toDate,
  ) => {
    try {
      let payload = {
        accountId: user?.account?.id,
        expirationDaysRemaining: 7,
        count: REWARD_TRANSACTIONS_PER_PAGE,
        offset: offset / REWARD_TRANSACTIONS_PER_PAGE,
      }
      const response =
        await AccountService.getExpiringSoonRewardTransactions(payload)
      const result = response?.data

      if (result?.success) {
        let expiringTransactions = result?.data?.data

        const benefitIds = result?.data?.data
          ?.filter(item => item?.benefitId)
          .map(item => item?.benefitId)

        if (benefitIds?.length) {
          const benefitDetailsResponse = await AccountService.getBulkBenefits({
            benefitIds: benefitIds,
          })
          const benefitDetailsResult = benefitDetailsResponse?.data

          if (benefitDetailsResult?.success) {
            expiringTransactions = expiringTransactions?.map(transaction => {
              return {
                ...transaction,
                benefitDetails: benefitDetailsResult?.data?.filter(
                  benefit => transaction?.benefitId === benefit?.benefitId,
                )?.[0],
              }
            })
          } else {
            consoleError(benefitDetailsResponse?.error)
            return { success: false }
          }

          const transactionIds = result?.data?.data
            ?.filter(item => item?.sourceTxnId)
            .map(item => item?.sourceTxnId)

          if (transactionIds?.length) {
            const transactionDetailsResponse =
              await AccountService.getBulkTransactions({
                accountId: user?.account?.id,
                transactionIdList: transactionIds,
              })
            const transactionDetailsResult = transactionDetailsResponse?.data

            if (transactionDetailsResult?.success) {
              expiringTransactions = expiringTransactions?.map(reward => ({
                ...reward,
                transactionDetails: reward?.sourceTxnId
                  ? transactionDetailsResult?.data?.filter(
                      item => item?.id === reward?.sourceTxnId,
                    )?.[0]
                  : null,
              }))
            } else {
              consoleError(transactionDetailsResponse?.error)
              return { success: false }
            }
          }
        }
        return tempFormatTransactionsData(
          expiringTransactions,
          result?.data,
          REWARD_TRANSACTIONS_PER_PAGE,
        )
      }
    } catch (error) {
      consoleError(error)
    }
  }

  const setFilter = filter => {
    if (filter === FilterType.DATE) {
      setIsFiltersBottomSheetOpen(true)
      return
    }

    if (selectedFilter === filter) {
      setSelectedFilter("")
    } else {
      setSelectedFilter(filter)
    }
  }

  const handleBottomSheetCloseButtonClick = () => {
    setIsBottomSheetOpen(false)
  }

  const handleFiltersBottomSheetCloseButtonClick = () => {
    setIsFiltersBottomSheetOpen(false)
  }

  const handleLifetimePointsClick = () => {
    setBottomSheetContent(
      <div>
        <BottomSheetHeader
          text='You curently have'
          points={rewardsData?.lifetimeEarned}
          variant='LIFETIME_POINTS'
          onCloseBtnClick={() => setIsBottomSheetOpen(false)}
        />
        <div className='v3-rewards-lifetime-points-details'>
          <div>
            <div>Lifetime Earned Points</div>
            <div>{rewardsData?.lifetimeEarned} pts</div>
          </div>
          <div>
            <div>Redeemed Points</div>
            <div>{rewardsData?.redeemed} pts</div>
          </div>
          {!rewardRedirectionType?.includes(
            RewardsRedirectionType.LR_TRANSACTIONS,
          ) && (
            <div>
              <div>Reversed Points</div>
              <div>{rewardsData?.reversed} pts</div>
            </div>
          )}
          <div>
            <div>Expired Points</div>
            <div>{rewardsData?.expired} pts</div>
          </div>
        </div>
        <div className='v3-rewards-lieftime-points-cta-container'>
          <ColorButton
            width='100%'
            text='Okay, Got It!'
            onPress={handleBottomSheetCloseButtonClick}
          />
        </div>
      </div>,
    )
    setIsBottomSheetOpen(true)
  }

  const handleExpiringSoonClick = () => {
    setBottomSheetContent(
      <div>
        <div className='v3-rewards-cashback-bottom-sheet-header-container'>
          <BottomSheetHeader
            text='Expiring soon'
            points={rewardsData?.expiring}
            variant='EXPIRING_SOON'
            onCloseBtnClick={() => setIsBottomSheetOpen(false)}
          />
        </div>
        <div className='v3-rewards-expiring-transactions-container'>
          <InfiniteScroll
            displayElement={data => (
              <RewardTransactionList
                transactionList={data}
                isExpiring
                isReward
              />
            )}
            next={fetchExpiringTransactions}
            dataLength={11}
            fromDate={selectedDuration?.from}
            toDate={selectedDuration?.to}
            loadingDelay={200}
            TransactionLoader={TransactionListLoader}
            zeroStateText={"No reward pt. history found with applied filters"}
          />
        </div>
        <div className='v3-rewards-cashback-bottom-sheet-cta-container'>
          <ColorButton
            width='100%'
            primaryColor={secondaryColor1}
            text='Okay, Got It!'
            onPress={handleBottomSheetCloseButtonClick}
          />
        </div>
      </div>,
    )
    setIsBottomSheetOpen(true)
  }

  const getFilters = () => {
    if (
      rewardRedirectionType?.includes(RewardsRedirectionType.LR_TRANSACTIONS)
    ) {
      return [transactionFilters[0]]
    } else {
      return [
        ...transactionFilters.filter(element => element.isActive),
        ...transactionFilters.filter(element => !element.isActive),
      ]
    }
  }

  return (
    <div
      style={{
        minHeight: window.innerHeight,
        backgroundColor: leadingWhite,
        position: "relative",
        className: "v3-rewards-cashback-container",
      }}
    >
      <Header
        text='Reward Points'
        onBack={async () => {
          window.history.go(-1)
        }}
      />

      <div className='v3-rewards-cashback-points-section'>
        <div className='v3-rewards-cashback-points-section-background-btm'></div>
        <RewardsCashbackBanner
          isRewards
          onLifetimePointsClick={handleLifetimePointsClick}
          onExpiringSoonClick={handleExpiringSoonClick}
        />
      </div>

      {!rewardsData?.lifetimeEarned && !rewardsData?.pending ? (
        <div className='v3-rewards-cahsback-no-lifetime-points'>
          Reward pt. history will show here as soon as you earn your first
          reward point.
        </div>
      ) : (
        <div className='v3-rewards-cashback-history'>
          <div className='v3-rewards-cashback-history-sticky-header'>
            <div className='v3-rewards-cashback-history-header'>
              Reward Pt. History
            </div>
            <div className='v3-rewards-cashback-history-filters'>
              {getFilters().map((element, index) => {
                return <Filter data={element} key={index} />
              })}
            </div>
            <div className='v3-rewards-cashback-history-filters-text'>
              Showing results from{" "}
              <span>
                {moment(selectedDuration?.from).format("Do MMM")} to{" "}
                {moment(selectedDuration?.to).format("Do MMM")}
              </span>
            </div>
          </div>
          <div className='v3-rewards-cashback-history-transactions'>
            <InfiniteScroll
              displayElement={data => (
                <RewardTransactionListWithBottomSheet
                  transactionList={data}
                  isReward
                />
              )}
              next={fetchRewardTransactions}
              dataLength={11}
              fromDate={selectedDuration?.from}
              toDate={selectedDuration?.to}
              loadingDelay={200}
              selectedFilter={selectedFilter}
              TransactionLoader={TransactionListLoader}
              zeroStateText={"No reward pt. history found with applied filters"}
            />
          </div>
        </div>
      )}

      <BottomSheet
        open={isBottomSheetOpen || isFiltersBottomSheetOpen}
        onDismiss={
          isBottomSheetOpen
            ? handleBottomSheetCloseButtonClick
            : handleFiltersBottomSheetCloseButtonClick
        }
      >
        {isBottomSheetOpen ? (
          bottomSheetContent
        ) : isFiltersBottomSheetOpen ? (
          <FiltersBottomSheet
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            handleCloseButtonClick={handleFiltersBottomSheetCloseButtonClick}
            cycles={cycles}
            selectedCycle={selectedDuration}
            setSelectedCycle={setSelectedDuration}
          />
        ) : null}
      </BottomSheet>
    </div>
  )
}

export default Rewards_V3
