import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { debounceFunction } from "../../utils/functions"
import { useSelector } from "react-redux"
import noTransactionImage from "../../assets/images/v3/noTransactions.svg"
import BilledTransactionLoader from "./SkeletonLoader/BilledTransaction"
import ZeroStateTransactionsV3 from "./ZeroStateTransactionsV3"
import TransactionListLoader from "./SkeletonLoader/TransactionListLoader"

const InfiniteScroll = ({
  dataLength,
  next,
  displayElement,
  style,
  hasPullDownToRefresh,
  hasScrollTopTopOption,
  scrollToTopComponent,
  errorComponent,
  loadingDelay,
  selectedWallets,
  selectedFilter,
  TransactionLoader,
  zeroStateText,
  fromDate,
  toDate,
}) => {
  const { t } = useTranslation()
  const session = useSelector(state => state.session)
  const user = useSelector(state => state.user)
  const [hasMore, setHasMore] = useState(true)
  const [items, setItems] = useState()
  const pageRef = useRef(0)
  const mouseDownRef = useRef(false)
  const [stateY, setStateY] = useState(null)
  const refreshInProgressRef = useRef(false)
  const [takeToTop, setTakeToTop] = useState(false)
  const elementRef = useRef(null)
  const [apiError, setApiError] = useState(false)
  const pageRenderRef = useRef(true)
  const [isLoading, setIsLoading] = useState(true)

  const nextApiCall = async () => {
    try {
      setIsLoading(true)
      const response = await next(
        user.account?.id,
        dataLength,
        pageRef.current,
        fromDate,
        toDate,
      )
      if (!response.data.success) {
        throw Error("Error fetching transactions")
      }
      if (response?.data?.data?.transactions.length > 0) {
        setItems(prevItems => [
          ...(!prevItems ? [] : prevItems),
          ...response.data.data.transactions,
        ])
        pageRef.current += dataLength
      }
      setIsLoading(false)
      setHasMore(response?.data?.data?.hasMore)
    } catch (err) {
      setIsLoading(false)
      setHasMore(false)
      setApiError(true)
    }
  }
  const debouncedApiCall = debounceFunction(nextApiCall, loadingDelay)

  const onIntersect = entries => {
    if (!session) return
    const firstEntry = entries[0]
    if (firstEntry.isIntersecting && hasMore && !refreshInProgressRef.current) {
      debouncedApiCall()
    }
  }

  useEffect(() => {
    if (!session) return
    const observer = new IntersectionObserver(onIntersect)
    if (observer && elementRef.current) {
      observer.observe(elementRef.current)
    }

    return () => {
      if (observer) observer.disconnect()
    }
  }, [items, session, hasMore])

  const handleMouseDown = e => {
    mouseDownRef.current = true
    setStateY(e.screenY)
  }

  const handleMouseLeave = e => {
    mouseDownRef.current = false
  }

  const handleMouseMove = e => {
    if (mouseDownRef.current && window.scrollY === 0) {
      if (e.screenY >= stateY && e.screenY - stateY >= (20 / 100) * 964) {
        mouseDownRef.current = false
        pageRef.current = 1
        setItems([])
        refreshInProgressRef.current = true
        setTimeout(() => {
          nextApiCall()
          refreshInProgressRef.current = false
        }, 1000)
      }
    }
  }

  useEffect(() => {
    if (takeToTop) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      setTakeToTop(false)
    }
  }, [takeToTop])

  useEffect(() => {
    if (!session) return
    if (!pageRenderRef.current) {
      pageRef.current = 0
      setIsLoading(true)
      setItems([])
      refreshInProgressRef.current = true
      setTimeout(() => {
        nextApiCall()
        refreshInProgressRef.current = false
      }, 1000)
    } else {
      pageRenderRef.current = false
    }
  }, [selectedWallets, session, selectedFilter])

  useEffect(() => {
    if (!session) return
    if (fromDate && toDate) {
      pageRef.current = 0
      setItems([])
      refreshInProgressRef.current = true
      setTimeout(() => {
        nextApiCall()
        refreshInProgressRef.current = false
      }, 200)
    }
  }, [fromDate, session])

  if (pageRef.current == 0 && !hasMore && !isLoading) {
    return (
      <div style={{ marginTop: "2rem" }} className='transaction-condition'>
        <ZeroStateTransactionsV3
          text={zeroStateText || "No Transactions Available"}
        />
      </div>
    )
  }

  return (
    <>
      {hasScrollTopTopOption &&
        items.length > 0 &&
        scrollToTopComponent(setTakeToTop)}
      {
        <>
          <div
            style={style}
            onMouseDown={hasPullDownToRefresh ? handleMouseDown : null}
            onMouseLeave={hasPullDownToRefresh ? handleMouseLeave : null}
            onMouseMove={hasPullDownToRefresh ? handleMouseMove : null}
          >
            {items &&
              items.length === 0 &&
              !isLoading &&
              !apiError &&
              !hasMore &&
              !isLoading && (
                <div
                  style={{ marginTop: "2rem" }}
                  className='transaction-condition'
                >
                  {/* <Scroll /> */}
                  <div className='v3-no-transaction-section'>
                    <img src={noTransactionImage} alt='no transactions image' />
                    <div className='v3-no-transaction-text'>
                      {t("transactions.emptyTransactionsText")}
                    </div>
                  </div>
                </div>
              )}
            {items && items.length > 0 && displayElement(items)}
            {apiError && errorComponent}
          </div>

          <div ref={elementRef}>
            {(hasMore || isLoading) &&
              (pageRef.current === 0 ? (
                <>
                  {TransactionLoader ? (
                    <TransactionLoader />
                  ) : (
                    <BilledTransactionLoader />
                  )}
                </>
              ) : (
                <div className='v3-infinite-scroll-transaction-loader'>
                  <TransactionListLoader transactionListLength={1} />
                </div>
              ))}
          </div>
        </>
      }
    </>
  )
}

export default InfiniteScroll
