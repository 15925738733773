import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import Confetti from "react-confetti"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import ApiIds from "../../auth/ApiIds"
import Paths from "../../routes/Paths"
import AccountService from "../../services/AccountService"
import CardService from "../../services/CardService"
import { clearCommonState, setCommonState } from "../../store/actions/Common"
import { setFirstTimeLoaded, setAllNudges } from "../../store/actions/Session"
import {
  setAccount,
  setAggregateRewardsSummary,
  setCards,
  setCashback,
  setCustomer,
  setRewardPoints,
  setSummary,
  setactivationCodeFromURL,
} from "../../store/actions/User"
import { handleApiAuth } from "../../utils/auth"
import {
  AuthChallenges,
  CardOrientation,
  DisplayConfig,
  ProgramTypes,
  RewardsRedirectionType,
  offerType,
} from "../../utils/constants"
import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
  PwaVersions,
  HomeTabs,
  rewardRedirectionType,
  cardDisplayMode,
  scrollAnimationStates,
} from "../../utils/enums"
import {
  captureEvents,
  consoleError,
  goToRedirectUrl,
  hexToRGBA,
} from "../../utils/functions"
import Preloader_v2 from "../PreLoader/preloader_v2"
import SmartNudges from "../core/Nudge/smartNudges"
import SkeletonLoader from "../core/SkeletonLoader/homeLoader"
import NudgeLoader from "../core/SkeletonLoader/nudgeLoader"
import { Header } from "../core/headers"
import { showToast1 } from "../core/toast"
import ErrorScreen from "../messages/errorScreen"
import ArrowRight from "../svg/arrowRight"
import CardInHand from "../svg/cardInHand"
import Settings from "../svg/settings"
import CardAndBalance_v2 from "./Components/CardAndBalance/CardAndBalance_v2"
import moment from "moment"
import CardDetailsWidget_v3 from "./Components/CardDetailsWidget/CardDetailsWidget_v3"
import drawerHandle from "../../assets/images/v3/active/homeDrawerBitNew.svg"
import ActivitySection_v3 from "./Components/ActivitySection/ActivitySection_v3"
import Offers_v3 from "./Components/OffersSection/v3/offers"
import hfLogo from "../../assets/images/v3/active/hfLogo.svg"
import { BottomSheet } from "react-spring-bottom-sheet"
import BottomSheet_v3 from "./Components/ActivitySection/SettingsBottomSheet_v3"
import FreezeInsteadScreen from "../ManageCard/screens/BlockCard/blockCardBottomSheets/freezeInsteadScreen"
import IdealMultiWalletWidget from "./Components/MultiWalletWidget/IdealMultiWalletWidget"
import TransactionSection_v2 from "./Components/TransactionSection/TransactionSection_v2"
import YourCardV3 from "../svg/v3/yourCard"
import YourPerksV3 from "../svg/v3/yourPerks"
import useWindowDimensions from "../../hooks/useWindowDimensionsWeb"
import {
  setCampaignBenefits,
  setCvpBenefits,
  setMerchantBenefits,
  setThirdPartyBenefits,
} from "../../store/actions/Benefits"
import { useToast } from "../../nativeBaseReplacements/useToast"
import InactiveGuideOverlay from "./Components/ActivitySection/InactiveGuideOverlay"

const colors = [
  "Enable Contactless Transactions",
  "Disable Contactless Transactions",
  "Enable Contactless Transactions 1",
]

const offersDispatchFunctions = {
  [offerType.CAMPAIGN_OFFERS]: setCampaignBenefits,
  [offerType.PROGRAM_OFFERS]: setCvpBenefits,
  [offerType.MERCHANT_OFFERS]: setMerchantBenefits,
  [offerType.THIRD_PARTY_OFFERS]: setThirdPartyBenefits,
}

const Home = () => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const common = useSelector(state => state.common)
  const session = useSelector(state => state.session)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)
  const authStore = useSelector(state => state.auth)
  const benefits = useSelector(state => state.benefits)
  const [cardVisibleV3, setCardVisibleV3] = useState(false)
  const selectedTabV3 = common?.SelectedHomePageTab
  const [InactiveGuideOverlayVisible, setInactiveGuideOverlayVisible] =
    useState(false)
  const isFirstTimeInactive = common?.firstTimeInactive
  const timerId = useRef(null)
  const expandedSessionCount =
    screen?.home?.homeMiscellaneous?.expandedSessionCount || 0
  const InactiveGuideOverlayTimer =
    screen?.home?.homeMiscellaneous?.idleAnimationDelay * 1000 || 10000
  const sessionCount = screen?.home?.homeMiscellaneous?.sessionCount || 3
  const sessionTokenCount = localStorage.getItem("sessionTokenCount") || 0

  const dispatch = useDispatch()

  const handleActivity = () => {
    dispatch(setCommonState({ firstTimeInactive: scrollAnimationStates.HIDE }))
    setInactiveGuideOverlayVisible(false)
  }

  const handleScrollAndClick = () => {
    handleActivity()

    if (window.scrollY > 10) {
      setInactiveGuideOverlayVisible(false)
    }
  }

  useEffect(() => {
    if (isFirstTimeInactive === scrollAnimationStates.SHOW) {
      timerId.current = setTimeout(() => {
        setInactiveGuideOverlayVisible(true)
      }, InactiveGuideOverlayTimer)
    } else {
      clearTimeout(timerId.current)
    }
  }, [isFirstTimeInactive])

  useEffect(() => {
    window.addEventListener("scroll", handleScrollAndClick)
    window.addEventListener("click", handleScrollAndClick)

    return () => {
      window.removeEventListener("scroll", handleScrollAndClick)
      window.removeEventListener("click", handleScrollAndClick)
    }
  }, [])

  useEffect(() => {
    if (expandedSessionCount === 0) {
      setCardVisibleV3(true)
    } else if (expandedSessionCount >= sessionTokenCount) {
      setCardVisibleV3(false)
    } else {
      setCardVisibleV3(true)
    }
    if (!isFirstTimeInactive && sessionCount >= sessionTokenCount) {
      dispatch(
        setCommonState({ firstTimeInactive: scrollAnimationStates.SHOW }),
      )
    }
  }, [
    expandedSessionCount,
    sessionTokenCount,
    isFirstTimeInactive,
    sessionCount,
    dispatch,
  ])

  useEffect(() => {
    if (!selectedTabV3) {
      dispatch(
        setCommonState({
          SelectedHomePageTab:
            screen?.home?.isCardTabEnabled && screen?.home?.isPerkTabEnabled
              ? screen?.home?.primaryTab === "card"
                ? HomeTabs.CARD
                : HomeTabs.PERKS
              : screen?.home?.isCardTabEnabled
                ? HomeTabs.CARD
                : screen?.home?.isPerkTabEnabled
                  ? HomeTabs.PERKS
                  : null,
        }),
      )
    }
  }, [screen])

  const handleTabClick = tab => {
    dispatch(setCommonState({ SelectedHomePageTab: tab }))
  }
  const [settingsOptionsOpenV3, setSettingsOptionsOpenV3] = useState(false)
  const [bottomSheetType, setBottomSheetType] = useState("settings")
  const [bottomSheetContent, setBottomSheetContent] = useState(null)

  const cardOrientation = theme?.cardOrientation

  const windowDimensions = useWindowDimensions()
  const nativeToast = useToast()
  const location = useLocation()
  const navigate = useNavigate()
  const { jsonConfig = {} } = config
  const isConfettiEnabled = jsonConfig?.isConfettiEnabled || false

  const isHfBrandingEnabled = screen.preLoader.isHfBrandingEnabled
  const account = user.account

  const options = {
    ALL: "ALL",
    UNBILLED: "UNBILLED",
  }
  const isAll = theme?.transactionViewType === options.ALL
  const card = user.cards?.[0]
  const isVirtualCardEnabled =
    screen?.home?.homePageLayout?.isVirtualCardEnabled
  const isCardSettingsEnabled =
    screen?.home?.homePageLayout?.isCardSettingsEnabled
  const [viewStatementByBlockCode, setViewStatementByBlockCode] = useState(true)

  const homeContainerRef = useRef(null)
  const isV3 = config?.version === PwaVersions.V3

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.LOGIN_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.REGISTRATION_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
      )
    ) {
      navigate("/blocked")
    }

    if (
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
      )
    ) {
      navigate("/blocked")
    }
    const element = homeContainerRef.current
    element.classList.add("active")

    return () => {
      element.classList.remove("active")
    }
  }, [])
  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.VIEW_STATEMENT_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.VIEW_STATEMENT_DISABLE,
      )
    ) {
      if (user.programType !== ProgramTypes.PREPAID) {
        setViewStatementByBlockCode(false)
      }
    }
  }, [])

  const commonBenefitsRequestProps = {
    accountId: user.account?.id,
    programId: user?.account?.programId,
    status: "ACTIVE",
    preferredTagsSort: true,
    sortBy: "activeTo",
    order: "ASC",
  }
  const getOffersByType = async offerType => {
    try {
      const response = await AccountService.getBenefitsForAccount({
        offerType: offerType,
        ...commonBenefitsRequestProps,
      })
      if (response.status === 200) {
        if (Array.isArray(response?.data?.data))
          dispatch(offersDispatchFunctions[offerType](response?.data?.data))
      } else {
        consoleError(response?.errors)
      }
    } catch (err) {
      consoleError(err)
    }
  }

  const getAggregateSummary = async () => {
    try {
      const rewardSummaryResponse = await AccountService.getAggregateSummary({
        accountId: user.account?.id,
        tag: "REWARDS",
      })
      if (rewardSummaryResponse.data.success) {
        if (rewardSummaryResponse.data?.data?.count > 0) {
          dispatch(
            setAggregateRewardsSummary({
              rewardSummary: rewardSummaryResponse.data?.data?.aggregates[0],
            }),
          )
        }
      }
    } catch (err) {
      consoleError(err)
    }
  }

  useEffect(() => {
    if (
      config?.version === PwaVersions.V3 &&
      screen?.home?.reward?.redirectionTypesV3?.includes(
        rewardRedirectionType.LR_TRANSACTIONS,
      )
    ) {
      getAggregateSummary()
    } else if (
      config?.version !== PwaVersions.V3 &&
      screen?.home?.reward?.redirectionType ===
        rewardRedirectionType.LR_TRANSACTIONS
    ) {
      getAggregateSummary()
    }

    if (user.account?.id && config?.version === PwaVersions.V3) {
      if (!benefits?.isCvpBenefitsFetched) {
        getOffersByType(offerType.PROGRAM_OFFERS)
      }
      if (!benefits?.isCampaignBenefitsFetched) {
        getOffersByType(offerType.CAMPAIGN_OFFERS)
      }
      if (!benefits?.isThirdPartyBenefitsFetched) {
        getOffersByType(offerType.THIRD_PARTY_OFFERS)
      }
      if (!benefits?.isMerchantBenefitsFetched) {
        getOffersByType(offerType.MERCHANT_OFFERS)
      }
    }
  }, [])

  useEffect(() => {
    let isMpinNeeded = false

    for (const value of Object.values(authStore?.afaDetails?.config)) {
      if (!isMpinNeeded && value.result) {
        if (
          Array.isArray(value.challenges) &&
          value.challenges.find(data => data.challenge === AuthChallenges.MPIN)
        ) {
          isMpinNeeded = true
        } else if (Array.isArray(value.dynamicChallenges)) {
          for (const flow of value.dynamicChallenges) {
            if (
              Array.isArray(flow.challenges) &&
              flow.challenges.find(
                data => data.challenge === AuthChallenges.MPIN,
              )
            ) {
              isMpinNeeded = true
            }
          }
        }
      }
    }
    const isMpinSet = authStore.afaDetails.config.isMpinSet.result
    if (!isMpinSet && isMpinNeeded) {
      window.location.reload()
    }
  }, [])

  if ("virtualKeyboard" in navigator) {
    navigator.virtualKeyboard.overlaysContent = true
  }

  const ViewTransaction = () => {
    if (user.programType === ProgramTypes.PREPAID) navigate("/Transactions")
    else if (isAll) navigate("/ViewTransactions")
    else navigate("/Statement")
  }
  const { isHomeSet, hasConfetti, summaryData } = common

  const isAccountActive =
    account.status === "ACTIVE" || account.status === "DORMANT"

  const [creditDetails, setCreditDetails] = useState({})
  // eslint-disable-next-line
  const [cardDetails, setCardDetails] = useState({})
  // eslint-disable-next-line
  const [showReminder, setShowReminder] = useState({})
  // eslint-disable-next-line
  const [offersList, setOffersList] = useState({})
  // eslint-disable-next-line
  const [recentTransactions, setRecentTransactions] = useState([])

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [nudges, setNudges] = useState(null)
  const [nudgeLoading, setNudgeLoading] = useState(false)
  const [benefitLoading, setBenefitLoading] = useState(false)
  const [unbilledTransaction, setUnbilledTransactions] = useState(null)

  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick: hasTick })
  }
  const rewards = screen?.home?.reward

  useEffect(() => {
    ;(async () => {
      if (!isHomeSet) {
        dispatch(clearCommonState())
        // if user coming from activation flow then first call activate api
        if (
          location.pathname.includes("/activated") ||
          location.state?.from === "CardActivated"
        ) {
          // activate card
          await handleApiAuth({
            apiId: ApiIds.ACTIVATE_CARD,
            onAuthSuccess: onActivateAuthSuccess,
            onAuthFailure: onActivateAuthFailure,
            onAuthCancel: onActivateAuthCancel,
            otpReason: t("HomePage.toActivateCard"),
            mpinReason: t("HomePage.toActivateCard"),
            toast,
            navigate,
          })
        } else {
          // else directly call summary api
          await handleApiAuth({
            apiId: ApiIds.ACCOUNT_SUMMARY,
            onAuthSuccess: onSummaryAuthSuccess(false),
            onAuthFailure: onSummaryAuthFailure,
            onAuthCancel: onSummaryAuthCancel,
            otpReason: t("HomePage.toGetAccSummary"),
            mpinReason: t("HomePage.toGetAccSummary"),
            toast,
            navigate,
          })
        }

        if (config?.version === PwaVersions.V3) {
          if (
            rewards?.redirectionTypesV3?.includes(
              RewardsRedirectionType.HF_CASHBACK,
            )
          ) {
            hfCashbackSummary()
          }
          if (
            rewards?.redirectionTypesV3?.includes(
              RewardsRedirectionType.HF_REWARDS,
            )
          ) {
            hfRewardsSummary()
          }
        } else {
          if (
            rewards?.redirectionType === RewardsRedirectionType?.HF_CASHBACK
          ) {
            getHfCashbackSummary()
          } else if (
            rewards?.redirectionType === RewardsRedirectionType.HF_REWARDS
          ) {
            HfRewardPoints()
          }
        }
      } else {
        dispatch(
          setSummary({
            summary: summaryData,
          }),
        )

        const customer = summaryData?.account?.customer
        const account = summaryData?.account
        const card = summaryData?.primaryCard

        // update user store
        dispatch(setCustomer({ customer }))
        dispatch(setAccount({ account }))
        dispatch(setCards({ cards: [card] }))

        setRecentTransactions(summaryData.latestTransactions)

        setCreditDetails({
          availableCredit: account.availableCreditLimit,
          totalCredit: account.approvedCreditLimit,
          availableCash: account.availableCashLimit,
          currentBalance: account.currentBalance,
        })
        setOffersList(summaryData.offers)
        if (summaryData.lastStatement?.dueDate) {
          setShowReminder({
            show: parseFloat(summaryData.lastStatement.balanceAmountDue) > 0,
            amount: summaryData.lastStatement.balanceAmountDue,
            dueDate: summaryData.lastStatement.dueDate,
            graceDate: summaryData.lastStatement.graceDate,
            fromDate: summaryData.lastStatement.fromDate,
            toDate: summaryData.lastStatement.toDate,
            totalAmountDue: summaryData.lastStatement.totalAmountDue,
          })
        }

        setCardDetails({
          orientation: theme.cardOrientation,
          image: theme.cardDesignUrl,
          name:
            customer?.firstname +
            (customer?.middlename && " " + customer?.middlename) +
            (customer?.lastname && " " + customer?.lastname),
          cardNo: card.maskedCardNumber,
          CVV: "655",
          Expiry: card.cardExpiry,
        })
      }
      dispatch(
        setFirstTimeLoaded({
          isLoadedFirstTime: false,
        }),
      )

      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHomeSet])

  useEffect(() => {
    return () => {
      // on leaving summary page clear common state, we don't want summary data to be cached
      dispatch(clearCommonState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addActivatePhysicalCardNudge = arr => {
    let activatePhysicalCardNudge = {
      name: t("HomePage.activatePhyCardName"),
      description: t("HomePage.activatePhyCardDesc"),
      displayOptions: {
        displayOrder: 10000,
        id: "",
        nudgeDetails: `{\"pwaVersion\":\"v2\",\"location\":\"TopBorder\",\"primaryColor\":\"${
          theme.appTextColor
        }\",\"secondaryColor\":\"${theme.appTextColor}\",\"displayColor\":\"${
          theme.widgetBackgroundColor
        }\",\"redirectionUrl\":\"${onClickNavigation()}\",\"illustrationUrl\":\"${(
          <CardInHand />
        )}\"}`,
        requireType: "MANDATORY",
      },
    }
    arr.push(activatePhysicalCardNudge)
    return arr
  }

  const onClickNavigation = () => {
    if (
      (config?.version === PwaVersions.V2 ||
        config?.version === PwaVersions.V2_1) &&
      screen?.home?.isQrCodeRequiredForActivation
    ) {
      return "/ActivateCard/Scanner"
    } else {
      return Paths.HOME_WITH_ACTIVATE
    }
  }

  const onNavigationReplaceCard = () => {
    return session.disabledFunctionsByBlockCode.includes(
      AU_BlockCodes.REPLACE_CARD_DISABLE,
    ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.REPLACE_CARD_DISABLE,
      )
      ? ""
      : "/ManageCard/NewCardRequest"
  }
  const onActivateAuthSuccess = async apiToken => {
    let isActivateSuccess = false
    try {
      const activationCode = localStorage.getItem("activationCode")
      localStorage.removeItem("activationCode")

      const response = await CardService.activateCard(
        apiToken,
        user.cards[0]?.id,
        {
          activationCode,
        },
      )
      const result = response.data

      if (result?.success) {
        // card state will be updated by summary api later

        isActivateSuccess = true
      } else {
        consoleError(result?.errors)
        toast(t("HomePage.activatingCardErrToast"))
      }
    } catch (error) {
      consoleError(error)
      toast(t("HomePage.activatingCardErrToast"))
    }

    // call summary api
    await handleApiAuth({
      apiId: ApiIds.ACCOUNT_SUMMARY,
      onAuthSuccess: onSummaryAuthSuccess(isActivateSuccess),
      onAuthFailure: onSummaryAuthFailure,
      onAuthCancel: onSummaryAuthCancel,
      otpReason: t("HomePage.toGetAccSummary"),
      mpinReason: t("HomePage.toGetAccSummary"),
      toast,
      navigate,
    })
  }

  const nudgesDisplayHandling = nudgesResponse => {
    setNudgeLoading(true)
    let removedNudges = JSON.parse(localStorage.getItem("removedNudges"))
    if (removedNudges) {
      removedNudges = removedNudges[account.id]
    }
    let nudgesDelivery = JSON.parse(localStorage.getItem("nudgesDelivery"))

    if (nudgesDelivery) {
      nudgesDelivery = nudgesDelivery[account.id]
    }
    let nudgesToBeRemoved = []
    if (nudgesDelivery) {
      const nudgeValueInLocalStorage = {
        ...nudgesDelivery,
      }
      const nudgeIds = Object.keys(nudgesDelivery)
      nudgeIds.forEach(nudgeId => {
        const nudgeDataFromResponse =
          nudgesResponse?.filter?.(nudge => nudge.id === nudgeId)?.[0] || {}

        const sessions = nudgeValueInLocalStorage[nudgeId]?.sessions || []
        const clickCount = nudgeValueInLocalStorage[nudgeId]?.clickCount || 0

        const nudgeDeliveryEligibilityList =
          nudgeDataFromResponse?.displayConstraints
        if ((nudgeDeliveryEligibilityList || [])?.length > 0) {
          nudgeDeliveryEligibilityList?.forEach(nudgeDelivery => {
            if (nudgeDelivery.entityType === "DELIVERY_LOGIC") {
              if (nudgeDelivery.entityAttribute === "CTR_COUNT") {
                if (nudgeDelivery.filter.type === "LT") {
                  if (clickCount >= nudgeDelivery.filter.value) {
                    nudgesToBeRemoved.push(nudgeId)
                  }
                }
                if (nudgeDelivery.filter.type === "LTE") {
                  if (clickCount + 1 > nudgeDelivery.filter.value) {
                    nudgesToBeRemoved.push(nudgeId)
                  }
                }
              }

              if (nudgeDelivery.entityAttribute === "IMPRESSION_COUNT") {
                if (nudgeDelivery.filter.type === "LT") {
                  if (sessions >= nudgeDelivery.filter.value) {
                    nudgesToBeRemoved.push(nudgeId)
                  }
                }
                if (nudgeDelivery.filter.type === "LTE") {
                  if (sessions + 1 > nudgeDelivery.filter.value) {
                    nudgesToBeRemoved.push(nudgeId)
                  }
                }
              }
            }
          })
        }
      })
    }

    if (nudgesToBeRemoved.length > 0) {
      nudgesToBeRemoved = Array.from(new Set(nudgesToBeRemoved))
    }

    if (removedNudges && removedNudges?.length > 100) {
      removedNudges = nudgesResponse?.filter(nudge =>
        removedNudges.includes(nudge.id),
      )
      removedNudges.forEach(nudge => {
        removedNudges.push(nudge.id)
      })
      localStorage.setItem("removedNudges", JSON.stringify(removedNudges))
    }
    const result = nudgesResponse?.filter(
      nudge =>
        !removedNudges?.includes(nudge.id) &&
        !nudgesToBeRemoved.includes(nudge.id),
    )
    setNudges(result)
    setNudgeLoading(false)
  }

  const onSummaryAuthSuccess = isCardActivated => async apiToken => {
    try {
      if (user.activationCode) {
        navigate("/ActivateCard/Scanner", {
          state: { activationCode: user.activationCode },
        })
        dispatch(
          setactivationCodeFromURL({
            activationCode: null,
          }),
        )
        return false
      }
      let response = null
      if (isV3) {
        response = await AccountService.getSummaryWithoutNudges(
          apiToken,
          user.account.id,
          config?.version,
        )
      } else {
        setNudgeLoading(true)
        response = await AccountService.getSummary(
          apiToken,
          user.account.id,
          config?.version,
        )
      }
      const result = response.data

      if (result?.success) {
        dispatch(
          setCommonState({
            summaryData: result.data,
            isHomeSet: true,
          }),
        )
        // nudgesDisplayHandling(result.data.nudges)
        // navigate and show confetti
        if (isCardActivated) {
          toast(t("HomePage.cardActivatedSuccessToast"))
          if (isConfettiEnabled) {
            dispatch(setCommonState({ hasConfetti: true }))
            setTimeout(() => {
              dispatch(setCommonState({ hasConfetti: false }))
            }, 5000)
          }
        }
        navigate("/", { replace: true })
      } else {
        consoleError(result?.errors)
        setError(true)
      }
    } catch (error) {
      consoleError(error)
      setError(true)
    }
    !isV3 && setNudgeLoading(false)
  }

  const getProgramType = user => {
    if (user.programType === ProgramTypes.CREDIT_CARD) return " credit "
    else if (user.programType === ProgramTypes.PAY_LATER) return " pay later "
    else return " "
  }
  const getHfCashbackSummary = async () => {
    try {
      const response = await AccountService.getHfCashback({
        accountId: user.account.id,
      })
      const result = response?.data
      if (result?.success) {
        dispatch(setCashback(response.data.data))
      } else {
        consoleError(response?.errors)
        // setError(true)
      }
    } catch (error) {
      consoleError(error)
      // setError(true)
    }
  }
  const hfCashbackSummary = async () => {
    try {
      const response = await AccountService.getHfCashbackSummary({
        accountId: user?.account?.id,
      })
      const result = response?.data
      if (result?.success) {
        dispatch(setCashback(result?.data))
      } else {
        consoleError(response?.errors)
      }
    } catch (error) {
      consoleError(error)
    }
  }
  useEffect(() => {
    if (
      (unbilledTransaction || unbilledTransaction == 0) &&
      common &&
      JSON.stringify(common).length > 5
    ) {
      handleCaptureEvents(
        common?.summaryData,
        common?.summaryData?.nudges,
        common?.summaryData?.offers,
      )
    }
  }, [unbilledTransaction, common])

  const handleCaptureEvents = (dataSummary, nudges, offers) => {
    let data = {
      nudge: nudges,
      cardStatus: user?.cards[0]?.isHotlisted
        ? "Blocked"
        : user?.cards[0]?.isLocked
          ? "Temp Blocked"
          : user?.cards[0]?.cardStatus,
      billDue: dataSummary?.lastStatement?.totalAmountDue > 0 ? "Yes" : "No",
      dueDate:
        dataSummary?.lastStatement?.totalAmountDue > 0
          ? moment(dataSummary?.lastStatement?.dueDate).format("DD-MM-YYYY")
          : "NA",
      unbilledTransactions:
        unbilledTransaction > 10 ? "10+" : unbilledTransaction,
    }
    captureEvents({ eventName: EventName.HOME, metadata: data })
  }

  const HfRewardPoints = async () => {
    try {
      const response = await AccountService.getHfRewardPoints({
        accountId: user.account.id,
      })
      const result = response.data
      if (result?.success) {
        dispatch(
          setRewardPoints({
            aggregate: result?.data,
          }),
        )
      } else {
        consoleError(result?.errors)
        // setError(true)
      }
    } catch (error) {
      consoleError(error)
      // setError(true)
    }
  }
  const hfRewardsSummary = async () => {
    try {
      const response = await AccountService.getHfRewardSummary({
        accountId: user?.account?.id,
        expirationDaysRemaining: 7,
      })
      const result = response?.data
      if (result?.success) {
        dispatch(
          setRewardPoints({
            aggregate: result?.data?.rewardPointsInfo,
          }),
        )
      } else {
        consoleError(result?.errors)
      }
    } catch (error) {
      consoleError(error)
    }
  }

  useEffect(() => {
    if (isV3) return
    nudgesDisplayHandling(common?.summaryData?.nudges)
  }, [common])

  useEffect(() => {
    if (!isV3) return
    if (!session?.nudges) getAllNudges()
    else nudgesDisplayHandling(session?.nudges)
  }, [])

  const getAllNudges = async () => {
    try {
      setNudgeLoading(true)
      const response = await AccountService.getAllNudges(user.account.id)
      const result = response.data
      if (result?.success) {
        dispatch(setAllNudges({ nudges: result.data }))
        nudgesDisplayHandling(result.data)
      }
    } catch (error) {
      consoleError(error)
      setError(true)
    }
    setNudgeLoading(false)
  }

  const handleSetUnbilledTransaction = data => {
    setUnbilledTransactions(data)
  }
  const onSummaryAuthFailure = async (error, message) => {
    error && consoleError(error)
    // message && toast(message)
    navigate("/Error", { replace: true })
  }

  const onSummaryAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onActivateAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onActivateAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  ///////////////////////////////////////////////////////////////////////////nudges section code ///////////////////////////////////////////////////////////
  const [activeIndex, setActiveIndex] = useState(0) // assuming theres atleast one item in the carousel
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [initialPosition, setInitialPosition] = useState()

  const handleMouseDown = e => {
    console.log(e.clientX)

    if (!isMouseDown) {
      setIsMouseDown(true)
      setInitialPosition(e.clientX)
    }
  }

  const handleMouseMove = e => {
    if (isMouseDown) {
      if (Math.abs(e.clientX - initialPosition) > 100) {
        if (e.clientX > initialPosition) {
          if (activeIndex > 0) setActiveIndex(prev => prev - 1)
          setIsMouseDown(false)
          setInitialPosition(null)
        } else if (e.clientX < initialPosition) {
          console.log("Prev")
          if (activeIndex < colors.length - 1) {
            setActiveIndex(prev => prev + 1)
          }
          setIsMouseDown(false)
          setInitialPosition(null)
        }
      }
    }
  }

  const handleMouseLeave = e => {
    if (isMouseDown) {
      setIsMouseDown(false)
      setInitialPosition()
    }
  }

  const handleDotClick = ind => {
    setActiveIndex(ind)
  }

  const getDrawerMargin = () => {
    if (!isVirtualCardEnabled && !isCardSettingsEnabled) {
      return "15px"
    } else if (!cardVisibleV3 && !isCardSettingsEnabled) {
      return "0px"
    } else if (!cardVisibleV3) {
      return "-16px"
    } else if (isVirtualCardEnabled && isCardSettingsEnabled) {
      return cardOrientation === CardOrientation.VERTICAL ? "-393px" : "-284px"
    } else if (!isCardSettingsEnabled) {
      return cardOrientation === CardOrientation.VERTICAL ? "-210px" : "-165px"
    } else {
      return "-130px"
    }
  }

  const CardTab = () => {
    return (
      <div
        className='v3-home-drawer-tabs-item'
        onClick={() => handleTabClick(HomeTabs.CARD)}
        style={{
          borderBottom: `${selectedTabV3 === HomeTabs.CARD ? theme.v3.cssVars.secondary.color1 : "transparent"} 3px solid`,
        }}
      >
        <div className='v3-home-drawer-tabs-item-icon'>
          <YourCardV3
            color={
              selectedTabV3 === HomeTabs.CARD
                ? theme.v3.cssVars.secondary.color1
                : theme.v3.cssVars.tertiaryNeutral.color2
            }
          />
        </div>
        <div
          className='v3-home-drawer-tabs-item-text'
          style={{
            color:
              selectedTabV3 === HomeTabs.CARD
                ? theme.v3.cssVars.secondary.color1
                : theme.v3.cssVars.tertiaryNeutral.color2,
            fontWeight: selectedTabV3 === HomeTabs.CARD ? "800" : "500",
          }}
        >
          Your Card
        </div>
      </div>
    )
  }

  const PerksTab = () => {
    return (
      <div
        className='v3-home-drawer-tabs-item'
        onClick={() => handleTabClick(HomeTabs.PERKS)}
        style={{
          borderBottom: `${selectedTabV3 === HomeTabs.PERKS ? theme.v3.cssVars.secondary.color1 : "transparent"} 3px solid`,
        }}
      >
        <div className='v3-home-drawer-tabs-item-icon'>
          <YourPerksV3
            selectedTabV3={selectedTabV3}
            color={
              selectedTabV3 === HomeTabs.PERKS
                ? theme.v3.cssVars.secondary.color1
                : theme.v3.cssVars.tertiaryNeutral.color2
            }
          />
        </div>
        <div
          className='v3-home-drawer-tabs-item-text'
          style={{
            color:
              selectedTabV3 === HomeTabs.PERKS
                ? theme.v3.cssVars.secondary.color1
                : theme.v3.cssVars.tertiaryNeutral.color2,
            fontWeight: selectedTabV3 === HomeTabs.PERKS ? "800" : "500",
          }}
        >
          Your Perks
        </div>
      </div>
    )
  }

  const Tabs = {
    CARD: <CardTab />,
    PERKS: <PerksTab />,
  }

  const bottomSheetItems = {
    settings: {
      component: <BottomSheet_v3 setOptionsOpen={setSettingsOptionsOpenV3} />,
    },
    freeze: {
      component: (
        <FreezeInsteadScreen
          reason={"freeze card"}
          setBottomSheetContent={setBottomSheetContent}
          setOptionsOpen={setSettingsOptionsOpenV3}
          type={"home"}
        />
      ),
    },
  }

  const renderTabs = () => {
    let orderedTabs = [HomeTabs.CARD, HomeTabs.PERKS]
    if (screen?.home?.primaryTab !== "card") {
      orderedTabs.reverse()
    }

    return orderedTabs.map((tab, index) => {
      return Tabs[tab]
    })
  }

  const versioned_home = () => {
    switch (config.version) {
      case PwaVersions.V2:
        return (
          <div
            className='home-v2'
            style={{
              minHeight: windowDimensions.height,
              backgroundColor: theme.backgroundColor,
              maxWidth: DisplayConfig.MAX_WIDTH,
            }}
          >
            {hasConfetti && (
              <Confetti
                width={windowDimensions.width}
                height={windowDimensions.height}
                tweenDuration={2000}
              />
            )}

            <Header
              text={theme.title}
              isHomePage={true}
              customClassName='header-heading'
              onBack={async () => {
                goToRedirectUrl()
              }}
              rightNavigateURL={isAccountActive && card ? "/ManageCard" : null}
              rightProp={<Settings />}
            />
            {/* <View
              py="12px"
              px="12px"
              flexDirection="row"
              justifyContent={"space-between"}
              backgroundColor={theme.headerBackgroundColor}
            >
              <View
                onClick={() => (window.location.href = session.redirectUrl)}
              >
                <ChevronLeftIcon size="6" color={theme.titleColor} />
              </View>
              <View {...styles.centering}>
                <Text
                  {...styles.heading}
                  fontFamily={theme.fontFamily}
                  color={theme.titleColor}
                >
                  {theme.title || "My Credit Card"}
                </Text>
              </View>
              <View>
                <View
                  onClick={() =>
                    isAccountActive && card ? navigate("/ManageCard") : null
                  }
                >
                  <Settings />
                </View>
              </View>
            </View> */}

            {nudgeLoading ? (
              <NudgeLoader />
            ) : nudges?.length > 0 ? (
              <SmartNudges nudgesData={nudges} />
            ) : (
              <></>
            )}
            <div className='home-cardAndBalance'>
              <CardAndBalance_v2
                showReminder={showReminder}
                cardDetails={cardDetails}
                creditDetails={creditDetails}
                offersList={offersList}
              />
            </div>

            <div className='home-transactionContainer'>
              <TransactionSection_v2
                setUnbilledTransactions={handleSetUnbilledTransaction}
                recentTransactions={recentTransactions}
              />
            </div>
          </div>
        )

      case PwaVersions.V2_1:
        return (
          <div
            className='home-v2'
            style={{
              minHeight: windowDimensions.height,
              backgroundColor: theme.backgroundColor,
              maxWidth: DisplayConfig.MAX_WIDTH,
            }}
          >
            {hasConfetti && (
              <Confetti
                width={windowDimensions.width}
                height={windowDimensions.height}
                tweenDuration={2000}
              />
            )}

            <Header
              text={theme.title}
              isHomePage={true}
              onBack={async () => {
                goToRedirectUrl()
              }}
              rightNavigateURL={isAccountActive && card ? "/ManageCard" : null}
              rightProp={<Settings />}
            />
            {/* <View
                py="12px"
                px="12px"
                flexDirection="row"
                justifyContent={"space-between"}
                backgroundColor={theme.headerBackgroundColor}
              >
                <View
                  onClick={() => (window.location.href = session.redirectUrl)}
                >
                  <ChevronLeftIcon size="6" color={theme.titleColor} />
                </View>
                <View {...styles.centering}>
                  <Text
                    {...styles.heading}
                    fontFamily={theme.fontFamily}
                    color={theme.titleColor}
                  >
                    {theme.title || "My Credit Card"}
                  </Text>
                </View>
                <View>
                  <View
                    onClick={() =>
                      isAccountActive && card ? navigate("/ManageCard") : null
                    }
                  >
                    <Settings />
                  </View>
                </View>
              </View> */}
            {/* <div className='home-activityContainer'>
              <ActivitySection_v2 />
            </div> */}
            {nudgeLoading ? (
              <NudgeLoader />
            ) : nudges?.length > 0 ? (
              <SmartNudges nudgesData={nudges} />
            ) : (
              <></>
            )}
            <div className='home-cardAndBalance'>
              <CardAndBalance_v2
                showReminder={showReminder}
                cardDetails={cardDetails}
                creditDetails={creditDetails}
                offersList={offersList}
              />
            </div>
            {config?.jsonConfig?.isMultiWalletEnabled === "true" && (
              <IdealMultiWalletWidget />
            )}
            <div className='home-transactionContainer'>
              <TransactionSection_v2
                setUnbilledTransactions={handleSetUnbilledTransaction}
                recentTransactions={recentTransactions}
              />
            </div>
            {config?.version === PwaVersions.V2_1 &&
            theme?.transactionViewType === options.UNBILLED ? (
              <div
                onClick={() => {
                  captureEvents({
                    eventName: EventName.VIEW_STATEMENT_SUMMARY,
                    metadata: {},
                  })
                  viewStatementByBlockCode && ViewTransaction()
                }}
                className='home-transactionSection-unbilled-text-v2_1'
              >
                {t("HomePage.viewBilledTrxns")}
                <span
                  style={{ color: theme.color1, cursor: "pointer" }}
                  className='home-transactionSection-unbilled-text-v2_1-viewHere'
                >
                  {" "}
                  {t("HomePage.here")}{" "}
                  <span style={{ display: "inline-block" }}>
                    {" "}
                    <ArrowRight color={theme.color1} size={"9px"} />{" "}
                  </span>
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        )
      case PwaVersions.V3:
        return (
          <div
            className='home-v3'
            style={{
              minHeight: windowDimensions.height,
              maxWidth: DisplayConfig.MAX_WIDTH,
            }}
          >
            {InactiveGuideOverlayVisible && (
              <InactiveGuideOverlay cardVisibleV3={cardVisibleV3} />
            )}
            {hasConfetti && (
              <Confetti
                width={windowDimensions.width}
                height={windowDimensions.height}
                tweenDuration={2000}
              />
            )}
            <Header
              text={null}
              isHomePage={true}
              onBack={async () => {
                goToRedirectUrl()
              }}
            />
            {(isVirtualCardEnabled || isCardSettingsEnabled) && (
              <div className='v3-home-cardDetails'>
                <CardDetailsWidget_v3
                  cardOpen={cardVisibleV3}
                  setCardOpen={setCardVisibleV3}
                  optionsOpen={settingsOptionsOpenV3}
                  setOptionsOpen={setSettingsOptionsOpenV3}
                  setBottomSheetType={setBottomSheetType}
                />
                <div
                  className='v3-home-cardDetails-overlay'
                  style={{
                    display: cardVisibleV3 ? "block" : "none",
                    zIndex: 9,
                  }}
                />
              </div>
            )}

            <div
              className={`v3-home-drawer ${!isVirtualCardEnabled && !isCardSettingsEnabled ? "v3-home-page" : ""}`}
              style={{
                position: "relative",
                marginTop: getDrawerMargin(),
                transition: "margin 0.4s ease",
                zIndex: 999,
              }}
            >
              <div className='v3-home-drawer-section'>
                {(isVirtualCardEnabled || isCardSettingsEnabled) && (
                  <div
                    onClick={() => setCardVisibleV3(!cardVisibleV3)}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    <img src={drawerHandle} />
                  </div>
                )}
                {nudgeLoading ? (
                  <NudgeLoader />
                ) : nudges?.length > 0 ? (
                  <SmartNudges nudgesData={nudges} />
                ) : (
                  <></>
                )}

                {screen?.home?.isCardTabEnabled &&
                  screen?.home?.isPerkTabEnabled && (
                    <div className='v3-home-drawer-tabs'>{renderTabs()}</div>
                  )}
              </div>

              <div className='v3-home-drawer-content'>
                {selectedTabV3 === HomeTabs.PERKS ? (
                  <div style={{ width: "100%" }}>
                    <Offers_v3 />
                  </div>
                ) : selectedTabV3 === HomeTabs.CARD ? (
                  <div style={{ width: "100%" }}>
                    <ActivitySection_v3
                      showReminder={showReminder}
                      cardDetails={cardDetails}
                      creditDetails={creditDetails}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>

              {isHfBrandingEnabled ? (
                <div className='v3-home-hf-branding'>
                  <div className='v3-home-hf-branding-text'>
                    {t("HomePage.poweredBy")}
                  </div>
                  <div className='v3-home-hf-branding-logo'>
                    <img src={hfLogo} />
                  </div>
                </div>
              ) : (
                <></>
              )}
              <BottomSheet
                open={settingsOptionsOpenV3}
                onDismiss={() => setSettingsOptionsOpenV3(false)}
              >
                {bottomSheetItems[bottomSheetType].component}
              </BottomSheet>
              {bottomSheetContent && (
                <BottomSheet
                  open={true}
                  onDismiss={() => setBottomSheetContent(null)}
                >
                  {bottomSheetContent}
                </BottomSheet>
              )}
            </div>
          </div>
        )
      default:
        return (
          <div
            className='home-v2'
            style={{
              minHeight: windowDimensions.height,
              backgroundColor: theme.backgroundColor,
              maxWidth: DisplayConfig.MAX_WIDTH,
            }}
          >
            {hasConfetti && (
              <Confetti
                width={windowDimensions.width}
                height={windowDimensions.height}
                tweenDuration={2000}
              />
            )}

            <Header
              text={theme.title}
              isHomePage={true}
              onBack={async () => {
                goToRedirectUrl()
              }}
              rightNavigateURL={isAccountActive && card ? "/ManageCard" : null}
              rightProp={<Settings />}
            />
            {/* <View
                py="12px"
                px="12px"
                flexDirection="row"
                justifyContent={"space-between"}
                backgroundColor={theme.headerBackgroundColor}
              >
                <View
                  onClick={() => (window.location.href = session.redirectUrl)}
                >
                  <ChevronLeftIcon size="6" color={theme.titleColor} />
                </View>
                <View {...styles.centering}>
                  <Text
                    {...styles.heading}
                    fontFamily={theme.fontFamily}
                    color={theme.titleColor}
                  >
                    {theme.title || "My Credit Card"}
                  </Text>
                </View>
                <View>
                  <View
                    onClick={() =>
                      isAccountActive && card ? navigate("/ManageCard") : null
                    }
                  >
                    <Settings />
                  </View>
                </View>
              </View> */}
            {/* <div className='home-activityContainer'>
              <ActivitySection_v2 />
            </div> */}
            {nudgeLoading ? (
              <NudgeLoader />
            ) : nudges?.length > 0 ? (
              <SmartNudges nudgesData={nudges} />
            ) : (
              <></>
            )}
            <div className='home-cardAndBalance'>
              <CardAndBalance_v2
                showReminder={showReminder}
                cardDetails={cardDetails}
                creditDetails={creditDetails}
                offersList={offersList}
              />
            </div>
            {config?.jsonConfig?.isMultiWalletEnabled === "true" && (
              <IdealMultiWalletWidget />
            )}
            <div className='home-transactionContainer'>
              <TransactionSection_v2
                setUnbilledTransactions={handleSetUnbilledTransaction}
                recentTransactions={recentTransactions}
              />
            </div>
            {config?.version === PwaVersions.V2_1 &&
            theme?.transactionViewType === options.UNBILLED ? (
              <div
                onClick={() => {
                  captureEvents({
                    eventName: EventName.VIEW_STATEMENT_SUMMARY,
                    metadata: {},
                  })
                  viewStatementByBlockCode && ViewTransaction()
                }}
                className='home-transactionSection-unbilled-text-v2_1'
              >
                {t("HomePage.viewBilledTrxns")}
                <span
                  style={{ color: theme.color1, cursor: "pointer" }}
                  className='home-transactionSection-unbilled-text-v2_1-viewHere'
                >
                  {" "}
                  {t("HomePage.here")}{" "}
                  <span style={{ display: "inline-block" }}>
                    {" "}
                    <ArrowRight color={theme.color1} size={"9px"} />{" "}
                  </span>
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        )
    }
  }
  return (
    <div className='home'>
      <div
        ref={homeContainerRef}
        className='home-container'
        style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
      >
        {error ? (
          <ErrorScreen />
        ) : loading &&
          session.isLoadedFirstTime &&
          (config?.version === PwaVersions.V2 ||
            config?.version === PwaVersions.V2_1) ? (
          <div
            className='home-preloaderContainer'
            style={{
              backgroundColor: `${
                config?.version === PwaVersions.V2 ||
                config?.version === PwaVersions.V2_1
                  ? theme.backgroundColor
                  : "white"
              }`,
              height: windowDimensions.height,
            }}
          >
            <div className='home-preloader'>
              <Preloader_v2 />
            </div>
          </div>
        ) : loading ? (
          <div style={{ backgroundColor: theme.backgroundColor }}>
            <SkeletonLoader
              orientation={theme.cardOrientation}
              showRewards={screen?.home?.reward?.isEnabled}
            />
          </div>
        ) : benefitLoading ? (
          <div style={{ backgroundColor: theme.backgroundColor }}>
            <SkeletonLoader
              orientation={theme.cardOrientation}
              showRewards={screen?.home?.reward?.isEnabled}
            />
          </div>
        ) : (
          <div className='home-versionContainer'>{versioned_home()}</div>
        )}
      </div>
    </div>
  )
}

export default Home
