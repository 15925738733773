import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import couponHeart from "../../../assets/images/v3/couponHeart.svg"
import { Header } from "../../core"
import CvpOfferCard from "./Components/cvpOfferCard"
import hflogo from "../../../assets/images/v3/active/hfLogo.svg"

const AllCvpOffers = () => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const benefits = useSelector(state => state.benefits)
  const screen = useSelector(state => state.screen)

  const cvpOffers = benefits?.cvpBenefits?.sort(
    (a, b) => a?.displayOrder - b?.displayOrder,
  )
  const isHfLogoEnabled = screen?.preLoader?.isHfBrandingEnabled
  return (
    <div
      style={{
        minHeight: window.innerHeight,
        backgroundColor: theme.v3.cssVars.primary.color5,
      }}
    >
      <Header
        text='Your Card Features'
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <div className='v3-allCvpOffers'>
        <div>
          <img src={couponHeart} alt='coupon-heart' />
          <div style={{ color: theme.v3.cssVars.tertiaryNeutral.color1 }}>
            {t("offers.cvpOfferText")}
          </div>
        </div>
        <div className='v3-allCvpOffers-offers-container'>
          {cvpOffers?.map((offer, index) => {
            return <CvpOfferCard key={index} offer={offer} />
          })}
        </div>
      </div>
      {isHfLogoEnabled && (
        <div className='v3-allCvpOffers-offers-hf-branding'>
          <div className='v3-home-hf-branding-text'>Powered by</div>
          <img src={hflogo} width={"109px"} height={"19px"} />
        </div>
      )}
    </div>
  )
}

export default AllCvpOffers
