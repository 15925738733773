import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { captureEvents, hexToRGBA } from "../../../../utils/functions"
import { EventName } from "../../../../utils/enums"
import { Header } from "../../../core"
import InfoCard from "../../../core/InfoCard/v3/InfoCard"
import Divider from "../../../core/Divider/v3/Divider"
import FaqsAccordian_v3 from "./FaqsAccordian_v3"
import { ColorButton, WhiteButton } from "../../../core/buttons"
import { BottomSheet } from "react-spring-bottom-sheet"
import Cross from "../../../svg/v3/Cross"
import Ellipse from "../../../svg/v3/Ellipse"
import "react-spring-bottom-sheet/dist/style.css"
import telephone from "../../../../assets/images/v3/telephone.svg"
import mail from "../../../../assets/images/v3/mail.svg"
import crossBtn from "../../../../assets/images/v3/closeBtn.svg"
import FAQ_Img1 from "../../../svg/FAQ_Img1"
import FAQ_Img2 from "../../../svg/FAQ_Img2"
import FAQ_Img3 from "../../../svg/FAQ_Img3"
import CS_blockCard from "../../../svg/CS_blockCard"
import CS_privacy from "../../../svg/v3/CS_privacy"
import CS_bell from "../../../svg/CS_bell"
import CS_creditCard from "../../../svg/CS_creditCard"

const CustomerCare_v3 = () => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const [text, setText] = useState("")
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)
  const [selectedSheet, setSelectedSheet] = useState()
  const [cardFaqs, setCardFaqs] = useState([])
  const [listFaqs, setListFaqs] = useState([])
  const [selectedFaq, setSelectedFaq] = useState()

  const customerSupportContainerRef = useRef(null)

  const cardDefaultImage = [
    <CS_blockCard color={theme.v3.cssVars.primary.color1} />,
    <CS_privacy color={theme.v3.cssVars.tertiaryPositive.color1} />,
    <CS_bell />,
    <CS_creditCard />,
  ]
  const cardBackground = [
    theme.v3.cssVars.tertiaryNegative.color3,
    "#F1FFF0",
    theme.v3.cssVars.tertiaryCaution.color3,
    theme.v3.cssVars.tertiaryAltFeedback.color3,
  ]
  const FAQsDefaultImageColor = theme.v3.cssVars.primary.color1
  const FAQsDefaultImage = [
    <FAQ_Img1 color={FAQsDefaultImageColor} />,
    <FAQ_Img2 color={FAQsDefaultImageColor} />,
    <FAQ_Img3 color={FAQsDefaultImageColor} />,
  ]

  const faqs = screen?.aboutProgram?.faq //program config part to be removed later

  useEffect(() => {
    captureEvents({ eventName: EventName.CUSTOMER_CARE, metadata: {} })
    const element = customerSupportContainerRef.current
    setTimeout(() => {
      element.classList.add("active")
    }, 3)
    return () => {
      element.classList.remove("active")
    }
  }, [])

  useEffect(() => {
    setCardFaqs(faqs.filter(faq => faq.type === "card"))
    setListFaqs(faqs.filter(faq => faq.type === "list"))
  }, [faqs])

  const handleOnTextInput = event => {
    setText(event.target.value)
  }

  const handleSendEmail = () => {
    const recipient = screen?.customerCare?.email
    const body = encodeURIComponent(text)
    const mailtoLink = `mailto:${recipient}?body=${body}`

    window.location.href = mailtoLink
  }

  const handleAssistanceButtonClick = () => {
    setSelectedSheet("customerSupport")
    setIsBottomSheetOpen(true)
  }

  const onClose = () => {
    setIsBottomSheetOpen(false)
  }

  const onInfoCardClick = element => {
    setIsBottomSheetOpen(true)
    setSelectedSheet("InfoSheet")
    setSelectedFaq(element)
  }

  const bottomSheetDetails = () => {
    if (selectedSheet === "customerSupport") {
      return (
        <div className='v3-customerSupport-bottomSheet'>
          <div
            className='v3-customerSupport-bottomSheet-header'
            style={{
              color: theme.v3.cssVars.tertiaryNeutral.color1,
              marginBottom: "23px",
              marginTop: "24px",
            }}
          >
            <div className='v3-customerSupport-bottomSheet-title'>
              {screen?.customerCare?.descText ||
                t("customerSupport.supportDescription")}
            </div>
            <div
              onClick={onClose}
              className='v3-customerSupport-bottomSheet-closeButton'
            >
              <img src={crossBtn} />
            </div>
          </div>
          <div
            className='v3-customerSupport-bottomSheet-contacts'
            onClick={() =>
              (window.location.href = `tel:${screen?.customerCare?.moblieNo}`)
            }
          >
            <img
              className='v3-customerSupport-bottomSheet-contacts-image'
              alt='telephone'
              src={telephone}
            />
            <div className='v3-customerSupport-bottomSheet-contacts-text'>
              <div style={{ color: theme.v3.cssVars.primaryBase.color2 }}>
                {screen?.customerCare?.moblieNoTitle}
              </div>
              <div style={{ color: theme.v3.cssVars.secondary.color1 }}>
                {screen?.customerCare?.moblieNo}
              </div>
            </div>
          </div>
          <div className='v3-customerSupport-bottomSheet-contacts-divider'>
            {"Or"}
          </div>
          <div
            className='v3-customerSupport-bottomSheet-contacts'
            onClick={() =>
              (window.location.href = `mailto:${screen?.customerCare?.email}`)
            }
          >
            <img
              className='v3-customerSupport-bottomSheet-contacts-image'
              alt='mail'
              src={mail}
            />
            <div className='v3-customerSupport-bottomSheet-contacts-text'>
              <div style={{ color: theme.v3.cssVars.primaryBase.color2 }}>
                {screen?.customerCare?.emailTitle}
              </div>
              <div style={{ color: theme.v3.cssVars.secondary.color1 }}>
                {screen?.customerCare?.email}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className='bottom-sheet'>
          <div
            className='v3-customerSupport-faq-bottomSheet-closeButton'
            onClick={onClose}
          >
            <Cross />
          </div>
          <div className='v3-customerSupport-bottomSheet-ellipse-container'>
            <Ellipse
              className='v3-blockCardReason-bottomSheet-ellipse-svg'
              color1={selectedFaq?.backgroundColor}
            />
            {selectedFaq?.imageUrl ? (
              <img
                src={selectedFaq?.imageUrl}
                className='v3-customerSupport-bottomSheet-PrivacyIcon-svg'
                alt='lock'
              />
            ) : (
              <div
                className='v3-customerSupport-bottomSheet-PrivacyIcon-svg'
                style={{ transform: "translateX(-30%)" }}
              >
                {selectedFaq?.defaultImage}
              </div>
            )}
          </div>
          <div className='v3-customerSupport-bottomSheet'>
            <div
              className='v3-customerSupport-bottomSheet-header'
              style={{ color: theme.v3.cssVars.tertiaryNeutral.color1 }}
            >
              <div className='v3-customerSupport-bottomSheet-title'>
                {selectedFaq?.question}
              </div>
            </div>
            <Divider />
            <div
              className='v3-customerSupport-bottomSheet-informationSafety'
              style={{ color: theme.v3.cssVars.primaryBase.color2 }}
            >
              {selectedFaq?.answer}
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div
      style={{
        minHeight: window.innerHeight,
      }}
      ref={customerSupportContainerRef}
      className='customerSupport-container'
    >
      <Header
        text={t("customerSupport.supportHeader")}
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <div className='v3-customerSupport'>
        {screen?.aboutProgram?.faqCard?.isEnabled && (
          <>
            <div
              className='v3-customerSupport-faqsTitle'
              style={{ color: theme.v3.cssVars.tertiaryNeutral.color1 }}
            >
              {screen?.aboutProgram?.faqCard?.header ||
                t("customerSupport.faqTitle")}
            </div>
            {cardFaqs.length > 0 && (
              <div className='v3-customerSupport-faqs'>
                {cardFaqs?.map((element, index) => {
                  return (
                    <InfoCard
                      details={element}
                      faq={element}
                      onCardClick={onInfoCardClick}
                      key={index}
                      defaultImage={
                        cardDefaultImage[index % cardDefaultImage.length]
                      }
                      cardBackgroundColor={
                        cardBackground[index % cardBackground.length]
                      }
                    />
                  )
                })}
              </div>
            )}
          </>
        )}

        {screen?.aboutProgram?.faqCard?.isEnabled &&
          screen?.aboutProgram?.faqList?.isEnabled && (
            <Divider showCenterMarker />
          )}

        {screen?.aboutProgram?.faqList?.isEnabled && (
          <div
            className='v3-customerSupport-otherFaqs'
            style={{ color: theme.v3.cssVars.tertiaryNeutral.color1 }}
          >
            <div className='v3-customerSupport-otherFaqsTitle'>
              {screen?.aboutProgram?.faqList?.header || "Other FAQs"}
            </div>
            <div className='v3-customerSupport-otherFaqs-accordian-container'>
              {listFaqs.length > 0 &&
                listFaqs?.map((item, index) => (
                  <div key={index}>
                    <FaqsAccordian_v3
                      item={item}
                      index={index}
                      FAQsDefaultImage={
                        FAQsDefaultImage[index % FAQsDefaultImage.length]
                      }
                    />

                    <Divider
                      showCenterSquare={
                        index == screen.aboutProgram.faq.length - 1
                      }
                      color={theme.v3.rawColors.primaryBase.color5}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
        {screen?.customerCare?.isEnabled && (
          <div className='v3-customerSupport-question'>
            <div
              className='v3-customerSupport-title'
              style={{ color: theme.v3.cssVars.tertiaryNeutral.color1 }}
            >
              {t("customerSupport.helpTitle")}
            </div>
            <div
              className='v3-customerSupport-headline'
              style={{ color: theme.v3.cssVars.tertiaryNeutral.color2 }}
            >
              {t("customerSupport.queryHeadline")}
            </div>
            <textarea
              className='v3-customerSupport-textInput'
              placeholder={t("customerSupport.faqPlaceholder")}
              value={text}
              onChange={handleOnTextInput}
            />
            <ColorButton
              isDisable={!text.length}
              text={t("customerSupport.sendMailButton")}
              width={"100%"}
              marginTop={"24px"}
              onPress={handleSendEmail}
            />
          </div>
        )}
      </div>
      <div className='v3-customerSupport-CTA'>
        <ColorButton
          text={t("customerSupport.assistanceButton")}
          width={"94%"}
          onPress={handleAssistanceButtonClick}
        />
      </div>
      <BottomSheet open={isBottomSheetOpen} onDismiss={onClose}>
        {bottomSheetDetails()}
      </BottomSheet>
    </div>
  )
}

export default CustomerCare_v3
