import React, { forwardRef, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { BottomSheet } from "react-spring-bottom-sheet"
import "react-spring-bottom-sheet/dist/style.css" // Import the styles
import OtpInput from "./OtpInput_v3"
import { ColorButton } from "../../core/buttons"
import {
  captureEvents,
  getAnalyticsProgramType,
  getMaskedMobileNumber,
  isWebKitBrowser,
  handleWebKitTextInputFocus,
} from "../../../utils/functions"
import {
  AuthenticationType,
  EventName,
  PwaVersions,
} from "../../../utils/enums"
import CommonService from "../../../services/CommonService"
import { useDispatch, useSelector } from "react-redux"
import {
  AuthChallenges,
  DEFAULT_OTP_SMS_SIZE,
  OtpErrors,
} from "../../../utils/constants"
import * as analytics from "../../../utils/analytics"
import { useNavigate } from "react-router-dom"
import { onFactorCompletion } from "../../../utils/auth"
import { setVerifiedChallenges } from "../../../store/actions/Auth"
import moment from "moment"
import crossIcon from "../../../assets/images/v3/closeBtn.svg"
import { useBottomSheet } from "../BottomSheetContext"
import Error_v3 from "../../core/Error_v3"
import Divider from "../../core/Divider/v3/Divider"
import DividerV3 from "../../core/Divider/v3/DividerV3"

const numToString = {
  1: "One",
  2: "Two",
  3: "Three",
  4: "Four",
  5: "Five",
  6: "Six",
  7: "Seven",
}

const OtpBottomSheet_v3 = forwardRef(({ isOpen, onClose }, ref) => {
  const { t } = useTranslation()
  const intervalTime = 30
  const sheetRef = useRef()
  const otpId = useRef("")
  const interval = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [otp, setOtp] = useState()
  const [error, setError] = useState("")

  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [resendCount, setResendCount] = useState(0)
  const [seconds, setSeconds] = useState(intervalTime)
  const [resendTriggered, setResendTriggered] = useState(false)
  const [isResendClicked, setIsResendClicked] = useState(false)

  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const authStore = useSelector(state => state.auth)
  const config = useSelector(state => state.config)
  const session = useSelector(state => state.session)
  const bottomSheetContext = useBottomSheet()

  const otpLength =
    config?.auth?.[AuthChallenges.OTP_SMS]?.length || DEFAULT_OTP_SMS_SIZE
  const maxResendCount = config?.auth?.[AuthChallenges.OTP_SMS]?.maxResendCount
  const autoRead = config?.auth?.[AuthChallenges.OTP_SMS]?.autoRead
  const canResend = maxResendCount && maxResendCount + 1 > resendCount

  useEffect(() => {
    ;(async () => {
      try {
        // generate otp for token and scope
        setIsResendClicked(false)
        const response = await CommonService.generateChallengeOTP({
          apiToken: authStore.apiToken,
          challengeScope: authStore.currentFactor.scope,
          mobileNumber: user.customer.mobileNumber || "9911223344",
          mobileNumberCountryCode: user.customer.mobileCountryCode || "91",
        })
        const result = response.data
        if (result?.success) {
          otpId.current = result.data.mobileVerificationRefId
          setSeconds(intervalTime)

          if (resendCount > 0) {
            captureEvents({
              eventName: EventName.RESEND_OTP,
              metadata: {},
            })
            // toast("OTP resent")
          }
        } else {
          // if otp generation failed call failure callback
          let blockedUntilFromApi = result?.errors?.blockedUntil
          bottomSheetContext.closeBottomSheet()
          bottomSheetContext.closeBottomSheet2()
          navigate("/AttemptExhausted", {
            state: {
              type: AuthenticationType.OTP,
              time: blockedUntilFromApi,
            },
          })
        }
      } catch (error) {
        // if exception occurred call failure callback
        await authStore.onAuthFailure(error, t("emailOtp.otpGenerationErrMsg"))
      }
    })()

    if (resendCount < 1) setLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendCount])

  useEffect(() => {
    interval.current = setInterval(() => {
      setSeconds(seconds => seconds - 1)
    }, 1000)
  }, [])

  useEffect(() => {
    document.addEventListener("focusin", handleWebKitTextInputFocus)
    return () => {
      document.removeEventListener("focusin", handleWebKitTextInputFocus)
    }
  }, [])

  const handleOTPChange = otp => {
    setError()
    setOtp(otp)
  }

  const resetStateOnWrongOTP = () => {
    // setOtp()
  }

  const handleOTPVerify = async () => {
    captureEvents({
      eventName: EventName.VERIFY_OTP_CTA,
      metadata: {},
    })
    analytics.track(
      `${getAnalyticsProgramType(user.programType)} - ${t("emailOtp.verifyOtpCta")}`,
    )

    if (otp?.length !== otpLength) {
      setError(t("emailOtp.incorrectOtp"))

      resetStateOnWrongOTP()
      // toast(`Incorrect OTP.`)

      captureEvents({
        eventName: EventName.INCORRECT_INPUT,
        metadata: {},
      })
      return
    }

    setSubmitLoading(true)

    try {
      // verify otp for token and scope
      const response = await CommonService.verifyChallengeOTP({
        apiToken: authStore.apiToken,
        challengeScope: authStore.currentFactor.scope,
        mobileNumber: user.customer.mobileNumber,
        mobileNumberCountryCode: user.customer.mobileCountryCode,
        mobileVerificationRefId: otpId.current,
        otp: otp,
      })
      const result = response.data
      if (result?.success) {
        setError(false)

        // update otp ref id in verified challenges
        const verifiedChallenges = {
          ...authStore.verifiedChallenges,
          otpRefId: otpId.current,
        }
        // set verified challenges
        dispatch(setVerifiedChallenges({ verifiedChallenges }))
        // on successful factor completion
        await onFactorCompletion(
          navigate,
          config?.version,
          null,
          bottomSheetContext,
        )
      } else {
        // setOtp("")

        if (result?.errors?.failureReason === OtpErrors.INVALID_OTP) {
          setError(
            `Incorrect OTP. \nYou have ${result?.errors?.attemptsLeft} attempt${
              result?.errors?.attemptsLeft !== 1 ? "s" : ""
            } remaining`,
          )
          setOtp()
          resetStateOnWrongOTP()

          captureEvents({
            eventName: EventName.INCORRECT_INPUT,
            metadata: {},
          })
        } else if (
          result?.errors?.failureReason === OtpErrors.OTP_EXPIRED &&
          canResend
        ) {
          setError(t("emailOtp.otpExpired"))
          setOtp()
          localStorage.setItem("otpBlockTime", moment())
          localStorage.setItem("otpBlockId", user.customer.mobileNumber)
          if (result?.errors.attemptsLeft == 0) {
            let blockedUntilFromApi = result?.errors?.blockedUntil
            bottomSheetContext.closeBottomSheet()
            bottomSheetContext.closeBottomSheet2()
            navigate("/AttemptExhausted", {
              state: {
                type: AuthenticationType.OTP,
                time: blockedUntilFromApi,
              },
            })
          }
        } else if (
          result?.errors?.failureReason === OtpErrors.OTP_EXPIRED &&
          !canResend
        ) {
          bottomSheetContext.closeBottomSheet()
          await authStore.onAuthFailure(
            result?.errors?.failureReason,
            t("emailOtp.otpExpiredText"),
          )
          navigate("/Error")
        } else if (
          result?.errors?.failureReason === OtpErrors.TEMPORARILY_BLOCK ||
          result?.errors?.failureReason === OtpErrors.BLOCKED_TEMPORARILY
        ) {
          let blockedUntilFromApi = result?.errors?.blockedUntil
          bottomSheetContext.closeBottomSheet()
          bottomSheetContext.closeBottomSheet2()
          navigate("/AttemptExhausted", {
            state: {
              type: AuthenticationType.OTP,
              time: blockedUntilFromApi,
            },
          })
        } else {
          // if otp verification failed due to unknow error then call failure callback
          bottomSheetContext.closeBottomSheet()
          await authStore.onAuthFailure(
            result?.errors?.reason,
            t("emailOtp.otpVerifyErrMsg"),
          )
        }
      }
    } catch (error) {
      // if exception occurred call failure callback
      bottomSheetContext.closeBottomSheet()
      await authStore.onAuthFailure(error, t("emailOtp.genericError"))
    }

    setSubmitLoading(false)
  }

  return (
    <BottomSheet open={isOpen} onDismiss={onClose}>
      <div>
        <div ref={sheetRef} className='bottom-sheet'>
          <div className='bottom-sheet-header'>
            <div className='v3-verify-otp-title v3-verify-otp-title-head'>
              {t("emailOtp.verifyOtpProceedText")}
              <span
                className='v3-verify-otp-close-btn'
                style={{ float: "right" }}
                onClick={onClose}
              >
                <img src={crossIcon} />
              </span>
            </div>

            <div className='v3-verify-otp-subtitle v3-verify-otp-subtitle-text'>
              {t("emailOtp.enterCodeText", { noDigit: 6 })}
              {user?.customer?.mobileCountryCode || "91"}{" "}
              {getMaskedMobileNumber(
                user?.customer?.mobileNumber || "XXXXXXXXXX",
                2,
              )}
            </div>
          </div>
          <Divider />

          <div className='v3-otp-input-bottom-sheet-content'>
            <OtpInput
              isValid={!error}
              isResendClicked={isResendClicked}
              length={otpLength}
              onChange={e => handleOTPChange(e)}
            />
          </div>

          {error && (
            <Error_v3
              errorMessage={error}
              customClassName={"v3-otp-bottomSheet-error"}
            />
          )}

          {canResend && (
            <div
              className='v3-verify-otp-resend-otp-text'
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                style={{ display: "flex", flexDirection: "column" }}
                onClick={() => {
                  setIsResendClicked(true)
                  if (seconds < 1) {
                    setResendCount(count => count + 1)
                  }
                }}
              >
                <div
                  className='authSmsOtp-widget-resendOtp-timer'
                  style={{
                    fontWeight: "normal",
                    display: seconds <= 0 ? "none" : "inline",
                  }}
                >
                  00:{seconds >= 10 ? seconds : `0${seconds}`}
                </div>
                <div
                  style={{
                    color:
                      seconds <= 0
                        ? theme.v3.cssVars.tertiaryCta.textColor
                        : theme.v3.cssVars.tertiaryNeutral.color2,
                    textDecoration: "underline",
                  }}
                >
                  {t("emailOtp.resendOtpText")}
                </div>
              </div>
            </div>
          )}

          <div className='v3-opt-input-cta'>
            <DividerV3 />
            <ColorButton
              isLoading={submitLoading}
              onPress={() => handleOTPVerify()}
              text='Verify'
              isDisable={otpLength !== otp?.length}
            />
          </div>
        </div>
      </div>
    </BottomSheet>
  )
})

export default OtpBottomSheet_v3
