import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Header } from "../../core"
import { useLocation, useNavigate } from "react-router-dom"
import Cross from "../../svg/v3/Cross"
import ArrowDown from "../../svg/arrowDown"
import AllTransactions_v3 from "../../core/AllTransactions_v3"
import AccountService from "../../../services/AccountService"
import InfiniteScroll from "../../core/InfiniteScrollV3"
import { getMonthForIndex } from "../../../utils/functions"
import { EventName, HomeTabs } from "../../../utils/enums"

const availableFilters = [
  {
    label: "filter1",
  },
  {
    label: "filter2",
  },
  {
    label: "filter3",
  },
  {
    label: "filter4",
  },
  {
    label: "filter5",
  },
  {
    label: "filter6",
  },
]

const ShowTransactions_v3 = () => {
  const theme = useSelector(state => state.theme)
  const { state } = useLocation()
  const naviate = useNavigate()
  const [filters, setFilters] = useState([])
  const toggleFilterSelect = filter => {
    if (filters.includes(filter.label)) {
      setFilters(prev => {
        return prev?.filter(label => label !== filter?.label)
      })
    } else {
      setFilters(prev => [...prev, filter?.label])
    }
  }

  const formatedFromDate = state?.cycle?.label?.split("-")[0].substring(0, 6)
  const formatedToDate = state?.cycle?.label?.split("-")[1].substring(0, 7)

  const fetchTransactions = async (accountId, count, offset, from, to) => {
    try {
      const response = await AccountService.getTransactions(accountId, {
        count,
        offset,
        from,
        to,
      })
      if (!response.data.success) {
        throw new Error("Api error, couldn't fetch transactions")
      }
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const fetchUnbilledTransactions = async (accountId, count, offset) => {
    try {
      const response = await AccountService.getUnbilledTransactions(accountId, {
        count,
        offset,
      })
      return response
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className='v3-all-transactions-container'>
      <Header
        text={"Transaction History"}
        onBack={() => naviate("/", { state: { prevScreen: HomeTabs.CARD } })}
      />
      {state.previousScreen !== EventName.HOME && (
        <>
          {/* <div className='v3-filters-section'>
            <div className='v3-filters-container'>
              {availableFilters?.map(data => {
                return !filters?.includes(data?.label) ? (
                  <div
                    className='v3-filters-card'
                    onClick={() => toggleFilterSelect(data)}
                  >
                    {data?.label}
                    <div className='v3-filter-arrow'>
                      <ArrowDown
                        color={theme.v3.rawColors.tertiaryNeutral.color1}
                        size={"8px"}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className='v3-filters-card active'
                    onClick={() => toggleFilterSelect(data)}
                  >
                    {data?.label}
                    <div className='v3-filters-card-cross'>
                      <Cross
                        height={"12"}
                        width={"12"}
                        color={theme.v3.rawColors.secondary.color1}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div> */}
          <div className='v3-all-transactions-cycle-section'>
            Showing results from{" "}
            <span className='v3-all-transaction-cycle-date'>
              {formatedFromDate} to {formatedToDate}
            </span>
          </div>
        </>
      )}
      {/* <Transaction_v3 /> */}
      <InfiniteScroll
        dataLength={11}
        next={
          state.previousScreen !== "HOME"
            ? fetchTransactions
            : fetchUnbilledTransactions
        }
        loadingDelay={100}
        displayElement={data => <AllTransactions_v3 transactions={data} />}
        hasPullDownToRefresh={false}
        hasScrollTopTopOption={false}
        selectedFilters={filters}
        fromDate={state?.from}
        toDate={state?.to}
      />
    </div>
  )
}

export default ShowTransactions_v3
