import React from "react"
import { useTranslation } from "react-i18next"
import { colorPicker, captureEvents } from "../../../../utils/functions"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import analyzeSpendsDefault from "../../../../assets/images/v3/active/analyzeSpendsDefault.svg"
import manageEmiDefault from "../../../../assets/images/v3/active/manageEmiDefault.svg"
import statementdefault from "../../../../assets/images/v3/active/statementdefault.svg"
import paymentHistoryDefault from "../../../../assets/images/v3/active/paymentHistoryDefault.svg"
import { EventName } from "../../../../utils/enums"

const QuickActions = ({ quickActions }) => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const session = useSelector(state => state.session)
  const featureFlags = useSelector(state => state.featureFlags)
  const navigate = useNavigate()
  const textColor = theme.appTextColor
  const textBase1 = colorPicker(textColor).primary1
  const quickActionsConfig = {
    STATEMENT: {
      text1: t("ActivitySectionv3.view"),
      text2: t("ActivitySectionv3.statement"),
      redirectTo: "/statement",
      icon: statementdefault,
    },
    HISTORY: {
      text1: t("ActivitySectionv3.payment"),
      text2: t("ActivitySectionv3.history"),
      redirectTo: "/transactions",
      icon: paymentHistoryDefault,
    },
  }
  return (
    quickActions?.length > 0 && (
      <div className='v3-activity-section-actions'>
        <div className='v3-activity-section-title'>
          {t("ActivitySectionv3.quickActions")}
        </div>
        <div className='v3-activity-section-actions-body'>
          {quickActions.map(action =>
            !!quickActionsConfig[action]?.redirectTo ? (
              <>
                <div className='v3-activity-section-actions-body-item'>
                  <div
                    className='v3-activity-section-actions-body-item-icon'
                    onClick={() =>
                      navigate(quickActionsConfig[action]?.redirectTo, {
                        state: {
                          previousScreen: EventName.HOME,
                        },
                      })
                    }
                  >
                    <img src={quickActionsConfig[action]?.icon} alt='icon' />
                  </div>
                  <div className='v3-activity-section-actions-body-item-text'>
                    <div>{quickActionsConfig[action]?.text1}</div>
                    <div>{quickActionsConfig[action]?.text2}</div>
                  </div>
                </div>
              </>
            ) : null,
          )}
        </div>
      </div>
    )
  )
}

export default QuickActions
