import React, { useEffect, useRef, useState } from "react"
import coin from "../../../../../assets/images/v3/active/coin.svg"
import cardSet1 from "../../../../../assets/images/v3/cardSet1.png"
import "pure-react-carousel/dist/react-carousel.es.css"
import { SwiperSlide, Swiper } from "swiper/react"
import { Pagination } from "swiper/modules"
import SwiperCore from "swiper"
import "swiper/css/pagination"
import "swiper/css"
import { useSelector } from "react-redux"
import CampaignOfferCard from "../../../../OffersList/v3/Components/campaignOfferCard"
import { useNavigate } from "react-router-dom"
import MerchantOffersCarousel from "../../../../OffersList/v3/Components/merchantOfferCarousel"
import BannerCvpCard from "./Components/bannerCvpCard"
import { getSortedComponentsBasedOnOrder } from "../../../../../utils/functions"
import CvpOffer from "./Components/cvpOffer"
import MilestoneOffer from "./Components/milestoneOffer"
import MerchantOffer from "./Components/merchantOffer"
import CrossSellOffer from "./Components/crossSellOffer"
import OtherOffer from "./Components/otherOffer"
// import RewardsAndCashbacks from "./Components/rewardsAndCashbacks"
import LineBreaker from "../../../../core/lineBreaker"
import RewardsAndCashbacks from "./Components/rewardsAndCashbacks"

SwiperCore.use([Pagination])

const Offers_v3 = () => {
  const benefits = useSelector(state => state.benefits)
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const user = useSelector(state => state.user)
  const navigate = useNavigate()
  const [orderedOfferSections, setOrderedOfferSections] = useState([])
  const offersContainerRef = useRef(null)

  const campaignOffers = benefits?.campaignBenefits.sort(
    (a, b) => a?.displayOrder - b?.displayOrder,
  )

  const cvpOffers = benefits?.cvpBenefits
    .filter(benefit => {
      return JSON.parse(benefit?.offerDetails)?.isBanner
    })
    .sort((a, b) => a?.displayOrder - b?.displayOrder)

  const merchantOffers = benefits?.merchantBenefits

  const crossSellOffers = benefits?.crossSellOffers

  const handleViewAllClick = path => {
    navigate(path)
  }

  useEffect(() => {
    const element = offersContainerRef.current
    setTimeout(() => {
      element.classList.add("active")
    }, 3)

    const yourPerksListData = screen.home.homePageLayout.yourPerksList
    const yourPerksList =
      typeof yourPerksListData == "string"
        ? JSON.parse(yourPerksListData)
        : yourPerksListData
    const orderedPerksComponents =
      getSortedComponentsBasedOnOrder(yourPerksList)

    setOrderedOfferSections(orderedPerksComponents)
    return () => {
      element.classList.remove("active")
    }
  }, [])

  const OfferSectionsComponents = {
    REWARDS_AN_CASHBACK: <RewardsAndCashbacks />,
    CVP_OFFERS: <CvpOffer cvpOffers={cvpOffers} />,
    MILESTONE_OFFERS: <MilestoneOffer campaignOffers={campaignOffers} />,
    MERCHANT_OFFERS: <MerchantOffer />,
    CROSS_SELL_OFFERS: <CrossSellOffer crossSellOffers={crossSellOffers} />,
    // OTHER_OFFERS: <OtherOffer />, //Not yet configured
  }

  return (
    <div
      ref={offersContainerRef}
      style={{ width: "100%", overflow: "hidden" }}
      className='offers-container'
    >
      {orderedOfferSections.map((section, index) => {
        return <>{OfferSectionsComponents[section]}</>
      })}
    </div>
  )
}

export default Offers_v3
