import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import txnLogo from "../../assets/images/v3/makeMyTrip.svg"
import { formatAmount, truncateString } from "../../utils/functions"
import {
  EventName,
  transactionNature,
  transactionTypes,
} from "../../utils/enums"
import { useNavigate } from "react-router-dom"
import Sparkle from "../svg/v3/sparkle"
import ArrowRight from "../svg/arrowRight"
import { formatString, capitalizeFirsLetter } from "../../utils/functions"
import { useSelector } from "react-redux"
import { EmiStatus, TransactionDateTypes } from "../../utils/constants"
import moment from "moment"
import customerTxnCatchAll from "../../assets/images/transactionLogos/customerTxnCatchAll.svg"
import { BottomSheet } from "react-spring-bottom-sheet"
import DebitImage from "../svg/v3/debitImage"
import CreditImage from "../svg/v3/creditImage"
import crossIcon from "../../assets/images/v3/closeBtn.svg"
import CopyToClipboard from "../svg/v3/copyToClipboard"
import BlackCheckCIrcle from "../svg/v3/blackCheckCIrcle"
import { ColorButton } from "./buttons"
import { copyToClipboard, captureEvents } from "../../utils/functions"
import { toastify } from "../../core"

const Transaction_v3 = ({
  transaction,
  screenName,
  setShowConversionInProgressBottomSheet,
  setShowConversionToEmiFailedBottomSheet,
}) => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const navigate = useNavigate()
  const [isTransactionBottomSheetOpened, setIsTransactionBottomSheetOpened] =
    useState(false)
  const handleConvertToEmi = transaction => {
    navigate("/ConvertToEMI", {
      state: {
        txnId: transaction.id,
        amount: transaction.amount,
        description: formatString(
          capitalizeFirsLetter(transaction.description),
        ),
        logo: transaction.identifiedMerchantLogo,
        transactionDate: transaction.transactionDate,
        postingDate: transaction.postingDate,
        txnType: transaction.txnType,
        prevPath: "/",
      },
    })
  }

  const converionToEmiInProgress = () => {
    setShowConversionInProgressBottomSheet(true)
  }
  const closeConverionToEmiInProgress = () => {
    setShowConversionInProgressBottomSheet(false)
  }

  const conversionToEmiFailed = () => {
    setShowConversionToEmiFailedBottomSheet(true)
  }

  const renderRefundAndCashbackIconSection = () => {
    if (transaction.txnType === transactionTypes.REFUND) {
      return <div className='v3-home-transaction-refund'>Refund</div>
    } else if (transaction.txnType === transactionTypes.CASHBACK) {
      return <div className='v3-home-transaction-cashback'>Cashback</div>
    }
    return null
  }
  const renderEmiStates = () => {
    return (
      <div
        className='v3-home-transactionSection-transaction-convertToEmi'
        onClick={() => handleConvertToEmi(transaction)}
      >
        <div>
          {" "}
          <Sparkle color={theme.v3.rawColors.primary.color1} /> Convert to EMI
        </div>
        <div>
          <ArrowRight size={"10px"} color={theme.v3.rawColors.primary.color1} />
        </div>
      </div>
    )

    // we have pending and failure states , it will be handled when backend supports the feature
    // else if (transaction.emiStatus === EmiStatus.PENDING) {
    //   return (
    //     <div
    //     className='v3-home-transactionSection-transaction-convertToEmi v3-home-transactionSection-transaction-convertToEmi-inProgress'
    //     onClick={() => converionToEmiInProgress()}
    //     >
    //       <div>
    //         {" "}
    //         <Repeat color={theme.v3.tertiaryNeutral.color1} /> Conversion to
    //         EMI in Progress
    //       </div>
    //       <div style={{ marginRight: "5px" }}>
    //         <InfoCircle
    //           color={theme.v3.rawColors.tertiaryCaution.color1}
    //           size={"10px"}
    //           />
    //       </div>
    //     </div>
    //   )
    // }
  }
  const handleTransactionClick = () => {
    // if (screenName === EventName.SHOW_TRANSACTION)
    setIsTransactionBottomSheetOpened(true)
  }
  const emiStatus = transaction.emiStatus
  const handleCloseBottomSheet = () => {
    setIsTransactionBottomSheetOpened(false)
  }
  const handleColorButtonClick = () => {
    if (emiStatus === EmiStatus.NOT_CREATED) {
      navigate("/ConvertToEMI", {
        state: {
          txnId: transaction.id,
          amount: transaction.amount,
          description: formatString(
            capitalizeFirsLetter(transaction.description),
          ),
          logo: transaction.identifiedMerchantLogo,
          transactionDate: transaction.transactionDate,
          postingDate: transaction.postingDate,
          txnType: transaction.txnType,
          prevPath: "/",
        },
      })
    }
  }

  const copyTransactionId = () => {
    captureEvents({
      eventName: EventName.TRANSACTION_ID_COPY_CTA,
      metadata: { action: "copy transaction id" },
    })
    copyToClipboard(transaction?.id || "")
    toastify("Transaction Id Copied!")
  }

  const CreditTransactionTag = ({ transaction }) => {
    return (
      <div
        className='v3-transaction-credit-type'
        style={{
          backgroundColor:
            transaction?.txnType === transactionTypes.REFUND
              ? theme.v3.cssVars.primary.color3
              : theme.v3.cssVars.primary.color1,
          color:
            transaction?.txnType === transactionTypes.REFUND
              ? theme.v3.cssVars.primary.color1
              : theme.v3.cssVars.leadingWhite,
        }}
      >
        {transaction?.txnType === transactionTypes.REFUND
          ? "Refund"
          : "Cashback"}
      </div>
    )
  }

  return (
    <>
      <div className='v3-transaction-content' onClick={handleTransactionClick}>
        <div className='v3-transaction-section'>
          <div className='v3-transaction-left-section'>
            <div className='v3-transaction-logo'>
              {transaction?.identifiedMerchantLogo ? (
                <img
                  src={`data:image/jpg;base64,${transaction?.identifiedMerchantLogo}`}
                  className='v3-transaction-logo-image'
                  alt='transaction logo'
                />
              ) : (
                <img
                  src={customerTxnCatchAll}
                  alt='transaction logo'
                  className='v3-transaction-logo-image'
                />
              )}
            </div>
            <div className='v3-transaction-description'>
              <div className='v3-transaction-title'>
                {truncateString(transaction.description, 24)}
              </div>
              <div className='v3-transaction-date-and-type-section'>
                <div className='v3-transaction-created-date'>
                  {theme.transactionDateType ===
                  TransactionDateTypes.TRANSACTION_DATE
                    ? moment(transaction?.transactionDate).format("DD MMM")
                    : moment(transaction?.postingDate).format("DD MMM")}
                </div>
                {transaction?.txnNature === transactionNature.CREDIT &&
                  screenName === EventName.HOME && (
                    <CreditTransactionTag transaction={transaction} />
                  )}
              </div>
            </div>
          </div>
          <div className='v3-transaction-right-section'>
            <div
              className='v3-transaction-amount'
              style={
                transaction.txnNature === transactionNature.CREDIT
                  ? {
                      color: theme.v3.cssVars.tertiaryPositive.color1,
                    }
                  : {}
              }
            >
              {transaction.txnNature === transactionNature.CREDIT && "+ "}
              {formatAmount(transaction?.amount)}
            </div>
            {false && (
              <div className='v3-transaction-reward-point'>+ 200 pts</div>
            )}
            {transaction.emiStatus === EmiStatus.ACTIVE && (
              <div className='v3-home-transactionSection-transaction-value-emi'>
                <span style={{ marginRight: "4px" }}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='12'
                    height='12'
                    viewBox='0 0 12 12'
                    fill='none'
                  >
                    <path
                      d='M6 0.625C3.036 0.625 0.625 3.0365 0.625 6C0.625 8.9635 3.036 11.375 6 11.375C8.964 11.375 11.375 8.9635 11.375 6C11.375 3.0365 8.964 0.625 6 0.625ZM6 10.625C3.4495 10.625 1.375 8.5505 1.375 6C1.375 3.4495 3.4495 1.375 6 1.375C8.5505 1.375 10.625 3.4495 10.625 6C10.625 8.5505 8.5505 10.625 6 10.625ZM8.015 4.56799C8.1615 4.71449 8.1615 4.95201 8.015 5.09851L5.6815 7.43201C5.6085 7.50501 5.5125 7.54199 5.4165 7.54199C5.3205 7.54199 5.2245 7.50551 5.1515 7.43201L3.985 6.2655C3.8385 6.119 3.8385 5.88149 3.985 5.73499C4.1315 5.58849 4.369 5.58849 4.5155 5.73499L5.41701 6.63651L7.485 4.56851C7.6315 4.42201 7.8685 4.42199 8.015 4.56799Z'
                      fill={theme.v3.cssVars.tertiaryNeutral.color2}
                    />
                  </svg>
                </span>
                {t("transactions.convertedToEmiText")}
              </div>
            )}
          </div>
        </div>
        {transaction.emiAllowed &&
          transaction.emiStatus === EmiStatus.NOT_CREATED && (
            <div className='v3-transaction-emi-section'>
              {renderEmiStates()}
            </div>
          )}
      </div>
      <BottomSheet
        open={isTransactionBottomSheetOpened}
        onDismiss={() => setIsTransactionBottomSheetOpened(false)}
      >
        <div className='v3-transaction-bottomsheet'>
          <div className='v3-transaction-bottomsheet-header-section'>
            <div className='v3-transaction-bottomsheet-header-container'>
              <div className='v3-transaction-bottomsheet-header-logo'>
                {transaction?.identifiedMerchantLogo ? (
                  <img
                    src={`data:image/jpg;base64,${transaction?.identifiedMerchantLogo}`}
                    width={"68px"}
                    alt='transaction logo'
                  />
                ) : (
                  <img
                    src={customerTxnCatchAll}
                    width={"68px"}
                    alt='transaction logo'
                  />
                )}
              </div>
              <div className='v3-transaction-bottomsheet-header-text-section'>
                <div className='v3-transaction-bottomsheet-header-text'>
                  {transaction?.description || "transaction title"}
                </div>
                <div className='v3-transaction-bottomsheet-amount-section'>
                  <div className='v3-transaction-bottomsheet-header-amount'>
                    {formatAmount(transaction.transactionAmount)}
                  </div>
                  <div className='v3-transaction-bottomsheet-txn-nature-image'>
                    {transaction.txnNature === transactionNature.CREDIT ? (
                      <CreditImage />
                    ) : (
                      <DebitImage />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className='v3-transaction-bottomsheet-header-close-button'
              onClick={handleCloseBottomSheet}
            >
              <img src={crossIcon} alt='cross icon' />
            </div>
          </div>
          <div
            className='v3-transaction-bottomsheet-body-section'
            style={{ borderBottom: !emiStatus && "none" }}
          >
            <div className='v3-transaction-bottomsheet-detials-container'>
              <div className='v3-transaction-bottomsheet-details-key'>
                {t("transactions.transactionIdText")}
              </div>
              <div className='v3-transaction-bottomsheet-details-value'>
                {transaction.id}{" "}
                <span
                  className='v3-transaction-id-copy-icon'
                  onClick={copyTransactionId}
                >
                  <CopyToClipboard width='16' height='16' />
                </span>
              </div>
            </div>
            <div className='v3-transaction-bottomsheet-detials-container'>
              <div className='v3-transaction-bottomsheet-details-key'>
                {t("transactions.dateAndTimeText")}
              </div>
              <div className='v3-transaction-bottomsheet-details-value'>
                {moment(transaction.transactionDate).format(
                  "DD MMM YYYY, hh:mm A",
                )}
              </div>
            </div>
            <div className='v3-transaction-bottomsheet-detials-container-last'>
              <div className='v3-transaction-bottomsheet-details-key'>
                {t("transactions.paymentModeText")}
              </div>
              {/* hardcoded this value , we dont have it in response */}
              <div className='v3-transaction-bottomsheet-details-value'>
                {t("transactions.onlineText")}
              </div>
            </div>
          </div>
          {emiStatus && (
            <div className='v3-transaction-bottomsheet-footer-section'>
              {emiStatus === EmiStatus.ACTIVE && (
                <div className='v3-transaction-bottomsheet-converted-to-emi-container'>
                  <span className='v3-transaction-bottomsheet-converted-to-emi-icon'>
                    <BlackCheckCIrcle />
                  </span>{" "}
                  {t("transactions.convertedToEmiText")}
                </div>
              )}
              <div className='v3-transaction-bottomsheet-color-button'>
                <ColorButton
                  onPress={handleColorButtonClick}
                  text={
                    emiStatus === EmiStatus.ACTIVE
                      ? "View EMI Details"
                      : emiStatus === EmiStatus.NOT_CREATED
                        ? "Convert To EMI"
                        : null
                  }
                  width={"100%"}
                />
              </div>
            </div>
          )}
        </div>
      </BottomSheet>
    </>
  )
}

export default Transaction_v3
