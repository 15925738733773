import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { EmbeddedLink, Header } from "../../../core"
import EmiDescriptionCard from "./Components/EmiDescriptionCard"
import {
  captureEvents,
  consoleError,
  formatAmount,
} from "../../../../utils/functions"
import InfoCircle from "../../../../assets/images/v3/infoCircle.svg"
import { ColorButton } from "../../../core/buttons"
import { DisplayConfig } from "../../../../utils/constants"
import { BottomSheet } from "react-spring-bottom-sheet"
import Divider from "../../../core/Divider/v3/Divider"
import AccountService from "../../../../services/AccountService"
import { BottomSheetType, EventName } from "../../../../utils/enums"
import { showToast1 } from "../../../core/toast"
import { handleApiAuth } from "../../../../utils/auth"
import ApiIds from "../../../../auth/ApiIds"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import { Checkbox } from "../../../../core"
import LoadingBottomSheet from "../../../BottomSheet/LoadingBottomSheet"
import { useBottomSheet } from "../../../auth/BottomSheetContext"
import { useToast } from "../../../../nativeBaseReplacements/useToast"
import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb"
import DividerV3 from "../../../core/Divider/v3/DividerV3"

const EMI_CONVERTED_ERROR_STRING = /is previously converted to EMI/

const EMIOverview = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const windowDim = useWindowDimensions()
  const nativeToast = useToast()
  const { t } = useTranslation()

  const theme = useSelector(state => state.theme)
  const common = useSelector(state => state.common)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)

  const [isChecked, setIsChecked] = useState(
    screen.emi.tncIsCheckedByDefault ? true : false,
  )
  const [submitLoading, setSubmitLoading] = useState(false)
  const [isInfoBottomSheetOpen, setIsInfoBottomSheetOpen] = useState(false)
  const bottomSheetContext = useBottomSheet()

  const { isEmiPlansSet, txnState } = common
  const emiPlan = location?.state?.selectedPlan
  const totalAmount =
    emiPlan?.emi * emiPlan?.tenureInMonths + emiPlan?.processingFees

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const onCreateEmiAuthSuccess = async apiToken => {
    setSubmitLoading(true)
    try {
      const data = {
        tenureInMonths: emiPlan?.tenureInMonths,
        txnRefId: txnState?.txnId,
        emiStrategyId: emiPlan?.emiStrategyId,
      }
      const response = await AccountService.createEMI(
        apiToken,
        user.account.id,
        data,
      )
      let metadata = {
        tenureInMonths: emiPlan?.tenureInMonths,
      }
      captureEvents({
        eventName: EventName.CONVERT_TO_EMI_REQUESTED,
        metadata: metadata,
      })
      const result = response?.data
      if (result?.success) {
        captureEvents({
          eventName: EventName.CONVERT_TO_EMI_SUCCESS,
          metadata: {},
        })
        bottomSheetContext.openBottomSheet(
          BottomSheetType.SUCCESS,
          t("EmiElement.emiConversionSuccessMsg"),
          "",
          "/",
        )
      } else {
        captureEvents({
          eventName: EventName.CONVERT_TO_EMI_ERROR,
          metadata: {},
        })
        consoleError(result.errors)
        // if (EMI_CONVERTED_ERROR_STRING.test(result?.errors)) {
        //   toast(
        //     "Sorry!the transaction has already \nbeen converted. Please contact your \nbank for more details.",
        //   )
        // } else {
        //   captureEvents({
        //     eventName: EventName.CONVERT_TO_EMI_ERROR,
        //     metadata: {},
        //   })
        //   toast("Sorry an error occurred while \nconverting to EMI")
        // }
        bottomSheetClose() // to close the info bottomsheet which comes before failure bottomsheet
        if (!EMI_CONVERTED_ERROR_STRING.test(result?.errors)) {
          captureEvents({
            eventName: EventName.CONVERT_TO_EMI_ERROR,
            metadata: {},
          })
        }
        bottomSheetContext.openBottomSheet(
          BottomSheetType.FAILURE,
          t("EmiElement.BottomSheetFailureMsg"),
          "",
          "/",
          null,
          () => handleConvertToEMI(),
          true,
          true,
        )
      }
    } catch (error) {
      captureEvents({ eventName: EventName.CONVERT_TO_EMI_ERROR, metadata: {} })
      consoleError(error)
      bottomSheetClose()
      bottomSheetContext.openBottomSheet(
        BottomSheetType.FAILURE,
        t("EmiElement.BottomSheetFailureMsg"),
        "",
        "/",
        null,
        () => handleConvertToEMI(),
        true,
        true,
      )
    } finally {
      setSubmitLoading(false)
    }
  }

  const handleConvertToEMI = async () => {
    await handleApiAuth({
      apiId: ApiIds.CREATE_EMI,
      onAuthSuccess: onCreateEmiAuthSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: onAuthCancel,
      otpReason: t("EmiElement.toCreateEmi"),
      mpinReason: t("EmiElement.toCreateEmi"),
      version: config?.version,
      bottomSheetContext: bottomSheetContext,
    })
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  const handleInfoButtonClick = () => {
    setIsInfoBottomSheetOpen(true)
  }

  const bottomSheetClose = () => {
    setIsInfoBottomSheetOpen(false)
  }

  return (
    <>
      <div
        style={{
          height: windowDim.height,
          backgroundColor: theme.v3.cssVars.leadingWhite,
          maxWidth: DisplayConfig.MAX_WIDTH,
        }}
        className='v3-emiOverview-wrapper'
      >
        <div>
          <div className='v3-convertToEMI-header-wrapper'>
            <Header
              text={t("EmiElement.convertToEmiHeaderText", {
                topNo: "2",
                bottomNo: "2",
              })}
              onBack={async () => {
                navigate(-1, {
                  state: txnState,
                })
              }}
            />
            {/* <div className='v3-convertToEMI-header-circularProgress'>
              Step 2 of 2
              <div>
                <CircularProgressbar
                  value={"100"}
                  strokeWidth={15}
                  styles={buildStyles({
                    pathColor: theme.v3.rawColors.primary.color1,
                    trailColor: theme.v3.rawColors.tertiaryNeutral.color2,
                  })}
                />
              </div>
            </div> */}
          </div>
          <EmiDescriptionCard />
          <div className='v3-emiOverview-content'>
            <div>{t("EmiElement.emiOverview")}</div>
            <div>
              <div className='v3-emiOverview-content-details'>
                <div>{t("EmiElement.amt")}</div>
                <div>{formatAmount(txnState?.amount)}</div>
              </div>
              <div className='v3-emiOverview-content-details'>
                <div>{t("EmiElement.tenure")}</div>
                <div>
                  {location?.state?.selectedPlan?.tenureInMonths}{" "}
                  {t("EmiElement.Months")}
                </div>
              </div>
              <div className='v3-emiOverview-content-details'>
                <div>{t("EmiElement.interestRate")}</div>
                <div>
                  {(
                    location?.state?.selectedPlan?.interestRateBps / 100
                  ).toFixed(2)}{" "}
                  %
                </div>
              </div>
              <div className='v3-emiOverview-content-details'>
                <div>{t("EmiElement.emiAmount")}</div>
                <div>{formatAmount(location?.state?.selectedPlan?.emi)}</div>
              </div>
              <div className='v3-emiOverview-content-details'>
                <div>{t("EmiElement.processingFee")}</div>
                <div>
                  {formatAmount(location?.state?.selectedPlan?.processingFees)}
                </div>
              </div>
              <div className='v3-emiOverview-content-details'>
                <div className='v3-emiOverview-content-preClosureFee'>
                  {t("EmiElement.preclosureFee")}
                  <img src={InfoCircle} onClick={handleInfoButtonClick} />
                </div>
                <div>
                  {formatAmount(location?.state?.selectedPlan?.foreclosureFees)}
                </div>
              </div>
            </div>
            <div className='v3-emiOverview-content-totalAmount'>
              <div>{t("EmiElement.totalAmount")}</div>
              <div>{formatAmount(totalAmount)}</div>
            </div>
          </div>
        </div>
        <div className='v3-emiOverview-termsAndConditions'>
          <div>
            {/* Govt. taxes will be applicable on the Interest Component of the EMI,
            Processing Fee and Pre-Closure Fee as notified by the Govt. of
            India. */}
            {t("EmiElement.termsAndConditions")}
          </div>
          <div>
            <DividerV3 marginBottom={"6px"} />
            {screen?.emi?.isTncEnabled && (
              <div className='v3-emiOverview-tnc-section'>
                <div
                  className='v3-checkbox'
                  onClick={() => setIsChecked(!isChecked)}
                >
                  <Checkbox
                    isChecked={isChecked}
                    backgroundColor={
                      isChecked ? theme.v3.cssVars.secondary.color1 : null
                    }
                  />
                </div>
                <div className='v3-checkbox-label'>
                  <EmbeddedLink text={screen?.emi?.tncUrl} />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='v3-emiOverview-convertToEmi-button'>
          <ColorButton
            text='Convert to EMI'
            style={{ width: "100%", margin: "auto" }}
            onPress={() => handleConvertToEMI()}
            isDisable={screen?.emi.isTncEnabled ? !isChecked : false}
          />
        </div>
      </div>
      <BottomSheet
        open={isInfoBottomSheetOpen}
        onDismiss={() => bottomSheetClose()}
      >
        <div className='v3-emiOverview-bottomSheet'>
          <div className='v3-emiOverview-bottomSheet-preClosureFee'>
            {t("EmiElement.preclosureFee")}
          </div>
          <Divider />
          <div className='v3-emiOverview-bottomSheet-preClosureFee-description'>
            {t("EmiElement.preclosureDesc")}{" "}
          </div>
          <ColorButton
            text={t("EmiElement.okBtnText")}
            style={{ width: "100%" }}
            onPress={bottomSheetClose}
          />
        </div>
      </BottomSheet>

      {submitLoading && (
        <LoadingBottomSheet
          isOpen={submitLoading}
          title={t("EmiElement.loadingBottomSheetText")}
          onClose={() => setSubmitLoading(false)}
        />
      )}
    </>
  )
}

export default EMIOverview
