import React, { forwardRef, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import "react-spring-bottom-sheet/dist/style.css" // Import the styles
import cardBlock from "../../../../svg/v3/blockCardIcon.svg"
import lock from "../../../../svg/v3/lockBlue.svg"
import transactionBlock from "../../../../svg/v3/transactionsBlockBlue.svg"
import warningRed from "../../../../svg/v3/warningRed.svg"
import crossIcon from "../../../../../assets/images/v3/closeBtn.svg"

import { useDispatch, useSelector } from "react-redux"
import Ellipse from "../../../../svg/v3/Ellipse"
import { ColorButton } from "../../../../core/buttons"
import ApiIds from "../../../../../auth/ApiIds"
import { handleApiAuth } from "../../../../../utils/auth"
import {
  AU_BlockCodes,
  BottomSheetType,
  EventName,
  Federal_BlockCodes,
  Issuers,
} from "../../../../../utils/enums"
import CardService from "../../../../../services/CardService"
import { setdisabledFunctionsByBlockCode } from "../../../../../store/actions/Session"
import { useNavigate } from "react-router-dom"
import { captureEvents, consoleError } from "../../../../../utils/functions"
import { setCards } from "../../../../../store/actions/User"
import { useBottomSheet } from "../../../../auth/BottomSheetContext"
import DividerV3 from "../../../../core/Divider/v3/DividerV3"

const ProceedToBlock = forwardRef(({ setBottomSheetContent, reason }) => {
  const { t } = useTranslation()
  const DEFAULT_DESCRIPTIONS = [
    {
      imgSrc: lock,
      text: t("CardDetailsWidgetV3.lockDescription"),
    },
    {
      imgSrc: transactionBlock,
      text: t("CardDetailsWidgetV3.transactionBlockDescription"),
    },
    {
      imgSrc: warningRed,
      text: t("CardDetailsWidgetV3.warningRedDescription"),
    },
  ]
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const sheetRef = useRef()
  const proceedToBlockContainerRef = useRef(null)
  const [submitLoading, setSubmitLoading] = useState(false)

  const [theme, user, authStore, config, session, screen] = useSelector(
    state => [
      state.theme,
      state.user,
      state.auth,
      state.config,
      state.session,
      state.screen,
    ],
  )

  const bottomSheetContext = useBottomSheet()
  const card = user.cards?.[0]

  const handleSubmitClick = async () => {
    setSubmitLoading(true)
    setBottomSheetContent(null)

    await handleApiAuth({
      apiId: ApiIds.HOTLIST_CARD,
      onAuthSuccess: onAuthSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: onAuthCancel,
      otpReason: "to block card",
      mpinReason: "to block card",
      version: config?.version,
      bottomSheetContext: bottomSheetContext,
    })

    setSubmitLoading(false)
  }

  const updateBlockCodeAction = () => {
    let disabledFunctions = session?.disabledFunctionsByBlockCode
    if (session?.issuer === Issuers.AU_BANK) {
      let index = disabledFunctions.indexOf(AU_BlockCodes.TEMP_CARD_DISABLE)
      if (index === -1) {
        disabledFunctions.push(AU_BlockCodes.TEMP_CARD_DISABLE)
      }
      dispatch(
        setdisabledFunctionsByBlockCode({
          disabledFunctionsByBlockCode: disabledFunctions,
        }),
      )
    }
    if (session?.issuer === Issuers.FEDERAL_BANK) {
      let index = disabledFunctions.indexOf(Federal_BlockCodes.CARD_BLOCKED)
      if (index === -1) {
        disabledFunctions.push(Federal_BlockCodes.CARD_BLOCKED)
      }
      dispatch(
        setdisabledFunctionsByBlockCode({
          disabledFunctionsByBlockCode: disabledFunctions,
        }),
      )
    }
  }

  const onAuthSuccess = async apiToken => {
    try {
      const response = await CardService.hotlist(apiToken, user.cards[0]?.id, {
        reason: reason,
      })
      const result = response?.data
      if (result?.success) {
        const tempCard = { ...user.cards[0] }
        tempCard.isHotlisted = true

        updateBlockCodeAction()
        captureEvents({
          eventName: EventName.CARD_BLOCKED_REASON,
          metadata: { reason: reason },
        })

        dispatch(setCards({ cards: [tempCard] }))
        navigate("/ManageCard/BlockCard/CardBlocked", {
          state: { from: "blockCard" },
          replace: true,
        })
      } else {
        consoleError(result?.errors)
        // toast("Sorry an error occurred")
        // navigate on failure also as success callback may be called from challenge screen
        // this action can be made more specific by checking current path in url and acting accordingly
        handleFailureState()
        // navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      // toast("Sorry an error occurred")
      handleFailureState()
      // navigate("/", { replace: true })
    }
  }

  const handleFailureState = () => {
    bottomSheetContext.openBottomSheet(
      BottomSheetType.FAILURE,
      t("CardDetailsWidgetV3.BottomSheetFailureMsg"),
      t("CardDetailsWidgetV3.cardFreezeErrorMsg"),
      null,
      null,
      () => handleSubmitClick(),
    )
    // setBottomSheetContent(null)
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && console.log()
    handleFailureState()
    // navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && console.log()
    handleFailureState()
    // navigate("/", { replace: true })
  }

  useEffect(() => {
    const element = proceedToBlockContainerRef.current
    setTimeout(() => {
      element.classList.add("active")
    }, 3)
    return () => {
      element.classList.remove("active")
    }
  }, [])

  return (
    <div
      style={{ maxWidth: "450px" }}
      ref={proceedToBlockContainerRef}
      className='proceedToBloack-container'
    >
      <span
        className='v3-verify-otp-close-btn v3-blockCardReason-close-btn'
        onClick={() => {
          setBottomSheetContent(null)
        }}
      >
        <img src={crossIcon} />
      </span>
      <div>
        <div ref={sheetRef} className='bottom-sheet'>
          <div className='v3-blockCardReason-ellipse-container'>
            <Ellipse
              className='v3-blockCardReason-ellipse-svg'
              color1={"#FFF4F7"}
            />
            <img
              src={cardBlock}
              className='v3-blockCardReason-bottomSheet-blockCard-svg'
            />
          </div>

          <div className='v3-blockCardReason-faq-title'>
            {t("CardDetailsWidgetV3.blockCardCta")}
          </div>

          {DEFAULT_DESCRIPTIONS &&
            DEFAULT_DESCRIPTIONS.map(item => {
              return (
                <div className='v3-proceedToBlock-description-container'>
                  <div className='v3-proceedToBlock-description-img'>
                    <img src={item.imgSrc} />
                  </div>

                  <div className='v3-proceedToBlock-description-text'>
                    {item?.text}
                  </div>
                </div>
              )
            })}

          {/* <div className='v3-blockCardReason-bottomSheet-subtitle '>
              Since you chose for a temporary block you can also freeze your
              card instead of blocking it permanently
            </div> */}

          <div className='v3-blockCardReason-bottomSheet-cta-container'>
            <DividerV3 />
            <ColorButton
              width='93%'
              text={"Proceed to Block"}
              onPress={() => handleSubmitClick()}

              //   isLoading={submitLoading}
            />
          </div>

          <div className='v3-blockCardReason-bottomSheet-extra-space'></div>
        </div>
      </div>
    </div>
  )
})

export default ProceedToBlock
