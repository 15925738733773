import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import AccountService from "../../../../services/AccountService"
import { EmiStatus, ProgramTypes } from "../../../../utils/constants"
import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
  PwaVersions,
} from "../../../../utils/enums"
import Transaction from "../../../core/Transaction"
import { ColorButton } from "../../../core/buttons"
import CustomLoader from "../../../core/loader"
import ArrowDown from "../../../svg/arrowDown"
import { showToast1 } from "../../../core/toast"
import txnLogo from "../../../../assets/images/v3/makemytrip.png"
import LineBreaker from "../../../core/lineBreaker"
import {
  capitalizeFirsLetter,
  formatAmount,
  formatString,
} from "../../../../utils/functions"
import ArrowRight from "../../../svg/arrowRight"
import Sparkle from "../../../../components/svg/v3/sparkle"
import InfoCircle from "../../../svg/v3/infoCircle"
import Repeat from "../../../svg/v3/repeat"
import MoneyBillBan from "../../../svg/v3/moneyBillBan"
import Refresh from "../../../svg/v3/refresh"
import Warning from "../../../svg/v3/warning"
import SuccessBottomSheet from "../../../BottomSheet/SuccessBottomSheet"
import EMIConversionInProgressBottomSheet from "./Components/EMIConversionInProgressBottomSheet"
import EMIConversionFailedBottomSheet from "./Components/EMIConversionFailedBottomSheet"
import Transaction_v3 from "../../../core/Transaction_v3"
import nullImage from "../../../../assets/images/v3/zerostatetxn.svg"
import { useToast } from "../../../../nativeBaseReplacements/useToast"
import TransactionListLoader from "../../../core/SkeletonLoader/TransactionListLoader"

const options = {
  ALL: "ALL",
  UNBILLED: "UNBILLED",
}

const initTransactions = []
const initOffset = 0
const transactionCount = 10

const TransactionSection_v3 = props => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const user = useSelector(state => state.user)
  const session = useSelector(state => state.session)
  const config = useSelector(state => state.config)
  const common = useSelector(state => state.common)
  const [isOpen, setIsOpen] = useState(true)
  const [loading, setLoading] = useState(true)
  const [viewStatementByBlockCode, setViewStatementByBlockCode] = useState(true)
  const [transactions, setTransactions] = useState(initTransactions)
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(false)
  const [apiError, setApiError] = useState(0)
  const [
    showConversionInProgressBottomSheet,
    setShowConversionInProgressBottomSheet,
  ] = useState(false)
  const [
    showConversionToEmiFailedBottomSheet,
    setShowConversionToEmiFailedBottomSheet,
  ] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const offset = useRef(initOffset)
  const isTransactionsLoadingRef = useRef(false)

  const nativeToast = useToast()
  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  const latestTransactions = common.summaryData.latestTransactions

  const fetchUnbilledTransactions = async () => {
    setIsLoading(true)
    try {
      const response = await AccountService.getUnbilledTransactions(
        user.account.id,
        {
          count: transactionCount,
          offset: offset.current,
        },
      )
      const result = response.data
      if (result?.success) {
        if (result.data.totalCount !== 0) {
          setTransactions(transactions => [
            ...transactions,
            ...result.data.transactions,
          ])
          props.setUnbilledTransactions(result?.data?.totalCount)
        } else {
          props.setUnbilledTransactions(0)
        }
        if (result.data.hasMore) {
          offset.current += transactionCount
        } else if (result.data.totalCount !== 0 && !result.data.hasMore) {
          setTransactions(transactions => [...transactions])
          offset.current = -1
        }
      } else {
        props.setUnbilledTransactions(0)
        setTransactions(transactions => [...transactions])
        offset.current = -1
        setApiError(apiError + 1)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setTransactions(transactions => [...transactions])
      offset.current = -1
      setApiError(apiError + 1)
    } finally {
      setIsLoading(false)
    }
  }

  const loadMoreTransaction = async () => {
    // if current date's month is greater than or equal to start date's month (i.e. end not reached)
    // and any other api call for loading is not pending (txns must be loaded synchronously)
    if (
      offset.current !== -1 &&
      !isTransactionsLoadingRef.current &&
      apiError < 3
    ) {
      // use ref to track loading as setState can be async
      isTransactionsLoadingRef.current = true
      // show loader in UI
      setIsTransactionsLoading(true)

      // get txns

      await fetchUnbilledTransactions()

      isTransactionsLoadingRef.current = false
      setIsTransactionsLoading(false)
    }
  }
  useEffect(() => {
    if (!isAll) fetchUnbilledTransactions()
    else setTransactions(latestTransactions)
  }, [screen.home.transaction?.transactionViewType])

  const handleRefresh = async () => {
    setTransactions(initTransactions)
    offset.current = initOffset
  }

  const loadMoreComponent = () => {
    return offset.current === -1 ? (
      <></>
    ) : (
      <div
        onClick={loadMoreTransaction}
        className='home-transactionSection-loadmore-text-v2_1'
      >
        <span
          style={{ color: theme.color1 }}
          className='home-transactionSection-loadmore-text-v2_1-viewHere'
        >
          {" "}
          {t("TransactionSection.loadMoreText")}{" "}
          <span style={{ display: "inline-block" }}>
            {" "}
            <ArrowDown color={theme.color1} size={"9px"} />{" "}
          </span>
        </span>
      </div>
    )
  }

  const renderItem = ({ item, index }) => {
    switch (item.type) {
      case "TITLE":
        return (
          <>
            <div className='home-transactionSection-render-title'>
              <div
                className='home-transactionSection-render-title-text'
                style={{ fontFamily: theme.fontFamily }}
              >
                {t("TransactionSection.transactionsTitle")}
              </div>
              <div></div>
            </div>
          </>
        )

      case "DATE":
        return (
          <div
            className='home-transactionSection-render-transactionDate'
            style={{ color: theme.appTextColor }}
          >
            {moment(item.date).format("YYYY-MM-DD") ===
            moment().format("YYYY-MM-DD")
              ? "Today"
              : moment(item.date).format("Do MMM YY")}
          </div>
        )

      case "EMPTY":
        return (
          <>
            {config?.version === PwaVersions.V2_1 ? (
              <></>
            ) : (
              <div className='home-transactionSection-render-emptyItem'>
                <ColorButton
                  opacity={!viewStatementByBlockCode ? "0.4" : "1"}
                  text={"View Statement Summary"}
                  onPress={!viewStatementByBlockCode ? null : ViewTransaction}
                />
              </div>
            )}
          </>
        )

      default:
        return (
          <div className='home-transactionSection-container' key={index}>
            <Transaction item={item} />
          </div>
        )
    }
  }

  const renderLoader = () => {
    return isTransactionsLoading ? (
      <div className='home-transactionSection-renderLoader'>
        <CustomLoader color={theme.color1} width={20} height={20} />
      </div>
    ) : null
  }
  const isAll = screen.home.transaction?.transactionViewType === options.ALL
  const ViewTransaction = () => {
    if (user.programType === ProgramTypes.PREPAID) navigate("/Transactions")
    else if (isAll) navigate("/ViewTransactions")
    else navigate("/Statement")
  }

  const viewAllTransactions = () => {
    navigate("/ViewTransactions")
  }

  useEffect(() => {
    if (apiError >= 3) {
      toast("Error fetching Transactions")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError])

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.VIEW_STATEMENT_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.VIEW_STATEMENT_DISABLE,
      )
    ) {
      if (user.programType !== ProgramTypes.PREPAID) {
        setViewStatementByBlockCode(false)
      }
    }
  })
  const getMsg = () => {
    if (user.programType === ProgramTypes.PREPAID)
      return t("TransactionSection.viewAllTransactions")
    else if (isAll) return null
    else return t("TransactionSection.viewStmtSummary")
  }
  const handleConvertToEmi = transaction => {
    navigate("/ConvertToEMI", {
      state: {
        txnId: transaction.id,
        amount: transaction.amount,
        description: formatString(
          capitalizeFirsLetter(transaction.description),
        ),
        logo: transaction.identifiedMerchantLogo,
        transactionDate: transaction.transactionDate,
        postingDate: transaction.postingDate,
        txnType: transaction.txnType,
        prevPath: "/",
      },
    })
  }

  const converionToEmiInProgress = () => {
    setShowConversionInProgressBottomSheet(true)
  }
  const closeConverionToEmiInProgress = () => {
    setShowConversionInProgressBottomSheet(false)
  }

  const conversionToEmiFailed = () => {
    setShowConversionToEmiFailedBottomSheet(true)
  }

  const closeConversioToEmiFailed = () => {
    setShowConversionToEmiFailedBottomSheet(false)
  }
  const handleViewAllTransactionClick = () => {
    navigate("/Transactions", {
      state: {
        previousScreen: "HOME",
      },
    })
  }
  const isTransactionEnabled = screen.home.transaction.isEnabled
  const emptyImageUrl = screen.home.transaction.emptyImageUrl
  return (
    <>
      <div className='v3-home-transactionSection'>
        {isTransactionEnabled &&
          ((isAll && latestTransactions?.length > 0) ||
            (!isAll && transactions?.length > 0)) && (
            <div className='v3-activity-section-title'>
              {t("TransactionSection.acivitySectionTitle")}
              {transactions.length > 5 && (
                <div
                  className='v3-home-transaction-view-all'
                  onClick={handleViewAllTransactionClick}
                >
                  {t("TransactionSection.viewAll")}
                </div>
              )}
            </div>
          )}
        {/* {transactions.map(item =>
          item?.id ? (
            <>
              <div className='v3-home-transactionSection-transaction'>
                <div className='v3-home-transactionSection-transaction-logo'>
                  <img src={txnLogo} />
                </div>
                <div className='v3-home-transactionSection-transaction-desc'>
                  <div className='v3-home-transactionSection-transaction-desc-title'>
                    {formatString(capitalizeFirsLetter(item?.description))}
                  </div>
                  <div className='v3-home-transactionSection-transaction-desc-date'>
                    {moment(item?.transactionDate).format("DD MMM")}
                  </div>
                </div>
                <div className='v3-home-transactionSection-transaction-value'>
                  <div className='v3-home-transactionSection-transaction-value-amount'>
                    {formatAmount(item?.amount)}
                  </div>
                  {item?.emiStatus === EmiStatus.ACTIVE && (
                    <div
                      className='v3-home-transactionSection-transaction-value-emi'
                      onClick={() => {}}
                    >
                      <span style={{ marginRight: "4px" }}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='12'
                          height='12'
                          viewBox='0 0 12 12'
                          fill='none'
                        >
                          <path
                            d='M6 0.625C3.036 0.625 0.625 3.0365 0.625 6C0.625 8.9635 3.036 11.375 6 11.375C8.964 11.375 11.375 8.9635 11.375 6C11.375 3.0365 8.964 0.625 6 0.625ZM6 10.625C3.4495 10.625 1.375 8.5505 1.375 6C1.375 3.4495 3.4495 1.375 6 1.375C8.5505 1.375 10.625 3.4495 10.625 6C10.625 8.5505 8.5505 10.625 6 10.625ZM8.015 4.56799C8.1615 4.71449 8.1615 4.95201 8.015 5.09851L5.6815 7.43201C5.6085 7.50501 5.5125 7.54199 5.4165 7.54199C5.3205 7.54199 5.2245 7.50551 5.1515 7.43201L3.985 6.2655C3.8385 6.119 3.8385 5.88149 3.985 5.73499C4.1315 5.58849 4.369 5.58849 4.5155 5.73499L5.41701 6.63651L7.485 4.56851C7.6315 4.42201 7.8685 4.42199 8.015 4.56799Z'
                            fill='#747474'
                          />
                        </svg>
                      </span>
                      Converted to EMI
                    </div>
                  )}
                </div>
              </div>
              {item?.emiAllowed && item?.emiStatus == EmiStatus.NOT_CREATED && (
                <div
                  className='v3-home-transactionSection-transaction-convertToEmi'
                  onClick={() => handleConvertToEmi(item)}
                >
                  <div>
                    {" "}
                    <Sparkle color={theme?.color1} /> Convert to EMI
                  </div>
                  <div>
                    <ArrowRight size={"10px"} color={theme?.color1} />
                  </div>
                </div>
              )}
              {item?.emiStatus == EmiStatus.PENDING && (
                <div
                  className='v3-home-transactionSection-transaction-convertToEmi v3-home-transactionSection-transaction-convertToEmi-inProgress'
                  onClick={() => converionToEmiInProgress()}
                >
                  <div>
                    {" "}
                    <Repeat /> Conversion to EMI in Progress
                  </div>
                  <div style={{ marginRight: "5px" }}>
                    <InfoCircle color={"#EA8C00"} size={"10px"} />
                  </div>
                </div>
              )}
              {false &&
                item?.emiAllowed &&
                item?.emiStatus == EmiStatus.NOT_CREATED && (
                  <div
                    className='v3-home-transactionSection-transaction-convertToEmi v3-home-transactionSection-transaction-convertToEmi-failed'
                    onClick={() => conversionToEmiFailed()}
                  >
                    <div>
                      {" "}
                      <MoneyBillBan /> Conversion to EMI Failed
                    </div>
                    <div style={{ marginRight: "5px" }}>
                      <InfoCircle color={"#E00000"} size={"10px"} />
                    </div>
                  </div>
                )}
              {false &&
                item?.emiAllowed &&
                item?.emiStatus == EmiStatus.NOT_CREATED && (
                  <div
                    className='v3-home-transactionSection-transaction-convertToEmi v3-home-transactionSection-transaction-convertToEmi-failed'
                    onClick={() => handleConvertToEmi(item)}
                  >
                    <div>
                      {" "}
                      <Warning /> Conversion to EMI Failed.{" "}
                      <span style={{ fontWeight: "1000" }}>Tap to Retry</span>
                    </div>
                    <div>
                      <Refresh size={"14px"} />
                    </div>
                  </div>
                )}
              <LineBreaker />
            </>
          ) : (
            <></>
          ),
        )} */}

        {isTransactionEnabled &&
          (transactions.length > 0 && !isLoading ? (
            transactions?.slice(0, 5).map((transaction, index) => {
              return (
                <Transaction_v3
                  key={index}
                  transaction={transaction}
                  screenName={EventName.HOME}
                  setShowConversionInProgressBottomSheet={
                    setShowConversionInProgressBottomSheet
                  }
                  setShowConversionToEmiFailedBottomSheet={
                    setShowConversionToEmiFailedBottomSheet
                  }
                />
              )
            })
          ) : !isLoading ? (
            <div className='v3-home-transactions-zero-state'>
              <div className='v3-home-transaction-zero-state-container'>
                <div className='v3-home-transaction-zero-state-image'>
                  {emptyImageUrl ? (
                    <img
                      height={"125px"}
                      width={"141px"}
                      src={emptyImageUrl}
                      alt='zero state image'
                    />
                  ) : (
                    <img src={nullImage} alt='zero state image' />
                  )}
                </div>
                <div className='v3-home-transaction-text-section'>
                  <div className='v3-home-transaction-header-text'>
                    {t("TransactionSection.homeTrxnHeaderText")}
                  </div>
                  <div className='v3-home-transaction-description-text'>
                    {t("TransactionSection.homeTrxnDesc")}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <TransactionListLoader />
          ))}
        <EMIConversionInProgressBottomSheet
          isOpen={showConversionInProgressBottomSheet}
          onClose={closeConverionToEmiInProgress}
        />
        <EMIConversionFailedBottomSheet
          isOpen={showConversionToEmiFailedBottomSheet}
          onClose={closeConversioToEmiFailed}
        />
      </div>
    </>
  )
}

export default TransactionSection_v3
