import React, { forwardRef, useRef, useState } from "react"
import { BottomSheet } from "react-spring-bottom-sheet"
import "react-spring-bottom-sheet/dist/style.css" // Import the styles
import failureIcon from "../../assets/images/v3/downloadFailure.svg"

import { useDispatch, useSelector } from "react-redux"
import { ColorButton, WhiteButton } from "../core/buttons"
import crossIcon from "../../assets/images/v3/closeBtn.svg"
import DividerV3 from "../core/Divider/v3/DividerV3"

const numToString = {
  1: "One",
  2: "Two",
  3: "Three",
  4: "Four",
  5: "Five",
  6: "Six",
  7: "Seven",
}

const FailureBottomSheet = forwardRef(
  (
    {
      isOpen,
      onClose,
      title,
      subtitle,
      onCtaClick,
      onSecondaryCtaClick,
      showRetryButton = true,
      showSecondaryCta = false,
    },
    ref,
  ) => {
    const dispatch = useDispatch()

    const sheetRef = useRef()

    const [loading, setLoading] = useState(true)
    const [submitLoading, setSubmitLoading] = useState(false)

    const theme = useSelector(state => state.theme)
    const user = useSelector(state => state.user)
    const authStore = useSelector(state => state.auth)
    const config = useSelector(state => state.config)
    const session = useSelector(state => state.session)

    return (
      <BottomSheet open={isOpen} onDismiss={onClose}>
        <span
          className='v3-verify-otp-close-btn'
          style={{ float: "right", marginRight: "20px", marginTop: "20px" }}
          onClick={onClose}
        >
          <img src={crossIcon} />
        </span>
        <div>
          <div ref={sheetRef} className='bottom-sheet'>
            <div className='v3-success-bottomSheet-icon-container'>
              <img className='v3-success-bottomSheet-icon' src={failureIcon} />
            </div>

            <div className='bottom-sheet-header'>
              <div className='v3-success-bottomSheet-title'>{title}</div>

              <div className='v3-success-bottomSheet-subtitle'>{subtitle}</div>
            </div>
            {showRetryButton || (showSecondaryCta && <DividerV3 />)}
            {showRetryButton && (
              <div className='v3-opt-input-cta'>
                <ColorButton
                  isLoading={submitLoading}
                  onPress={() => onCtaClick()}
                  text='Retry'
                />
              </div>
            )}
            {showSecondaryCta && (
              <div className='v3-opt-input-secondary-cta' style={{}}>
                <WhiteButton
                  isLoading={submitLoading}
                  onPress={() => onSecondaryCtaClick()}
                  text='Back to Home'
                />
              </div>
            )}
          </div>
        </div>
      </BottomSheet>
    )
  },
)

export default FailureBottomSheet
