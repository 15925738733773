import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import LineBreaker from "../../../core/lineBreaker"
import analyzeSpendsDefault from "../../../../assets/images/v3/active/analyzeSpendsDefault.svg"
import manageEmiDefault from "../../../../assets/images/v3/active/manageEmiDefault.svg"
import statementdefault from "../../../../assets/images/v3/active/statementdefault.svg"
import paymentHistoryDefault from "../../../../assets/images/v3/active/paymentHistoryDefault.svg"
import TransactionSection_v3 from "../TransactionSection/TransactionSection_v3"
import moment from "moment"
import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
  cardSections,
} from "../../../../utils/enums"
import {
  captureEvents,
  getSortedComponentsBasedOnOrder,
  colorPicker,
} from "../../../../utils/functions"
import SuccessBottomSheet from "../../../BottomSheet/SuccessBottomSheet"
import PaymentGatewayV3 from "../../PaymentGateway/v3/paymentGatewayV3"
import AccountSummary from "./AccountSummary"
import QuickActions from "./QuickActions"
import RecentTransactions from "./RecentTransactions"
import usePayButtonText from "../../../../hooks/usePayButtonText"
import IdealMultiWalletWidgetV3 from "../MultiWalletWidget/IdealMultiwalletWidgetV3"

const ActivitySection_v3 = props => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const featureFlags = useSelector(state => state.featureFlags)
  const session = useSelector(state => state.session)
  const screen = useSelector(state => state.screen)

  const [isPaymentGatewayOpen, setIsPaymentGatewayOpen] = useState(false)

  const [orderedActivitySections, setOrderedActivitySections] = useState([])
  const textColor = theme.appTextColor
  const ctaPrimaryColor = theme.color1
  const ctaSecondaryColor = theme.color2
  const widgetBackgroundColor = theme.widgetBackgroundColor

  const textBase1 = colorPicker(textColor).primary1
  const textBase2 = colorPicker(textColor).primary2

  const ctaPrimaryColor1 = colorPicker(ctaPrimaryColor).primary1
  const ctaPrimaryColor2 = colorPicker(ctaPrimaryColor).primary2

  const quickActions = Array.isArray(
    screen?.home?.homeMiscellaneous?.quickActions,
  )
    ? screen?.home?.homeMiscellaneous?.quickActions
    : !!screen?.home?.homeMiscellaneous?.quickActions
      ? [screen?.home?.homeMiscellaneous?.quickActions]
      : []

  const ActivitySections = {
    ACCOUNT_SUMMARY: (
      <AccountSummary
        props={props}
        user={user}
        setIsPaymentGatewayOpen={setIsPaymentGatewayOpen}
      />
    ),
    QUICK_ACTION: <QuickActions quickActions={quickActions} />,
    RECENT_TRANSACTION: <RecentTransactions />,
    MULTI_WALLET: <IdealMultiWalletWidgetV3 />,
  }

  const account = user.account
  const card = user.cards?.[0]
  const navigate = useNavigate()

  const activityContainerRef = useRef(null)

  const isAccountActive = account.status === "ACTIVE"

  const getPayButtonText = usePayButtonText()

  const repayUrlCall = () => {
    !featureFlags?.isUPIFlowEnabled
      ? setIsPaymentGatewayOpen(true)
      : (window.location.href = session.repaymentUrl)
  }

  const closePaymentGatewayBottomSheet = () => {
    setIsPaymentGatewayOpen(false)
  }

  const quickActionsConfig = {
    ANALYSIS: {
      text1: t("ActivitySectionv3.analyse"),
      text2: t("ActivitySectionv3.spends"),
      redirectTo: "/",
      icon: analyzeSpendsDefault,
    },
    EMI: {
      text1: t("ActivitySectionv3.manage"),
      text2: t("ActivitySectionv3.emi"),
      redirectTo: "/",
      icon: manageEmiDefault,
    },
    STATEMENT: {
      text1: t("ActivitySectionv3.view"),
      text2: t("ActivitySectionv3.statement"),
      redirectTo: "/",
      icon: statementdefault,
    },
    HISTORY: {
      text1: t("ActivitySectionv3.payment"),
      text2: t("ActivitySectionv3.history"),
      redirectTo: "/",
      icon: paymentHistoryDefault,
    },
  }

  useEffect(() => {
    const element = activityContainerRef.current
    setTimeout(() => {
      element.classList.add("active")
    }, 3)

    const yourCardListData = screen.home.homePageLayout.yourCardsList

    const yourCardList =
      typeof yourCardListData == "string"
        ? JSON.parse(yourCardListData)
        : yourCardListData
    const orderedActivityComponents =
      getSortedComponentsBasedOnOrder(yourCardList)
    setOrderedActivitySections(orderedActivityComponents)
    return () => {
      element.classList.remove("active")
    }
  }, [])
  return (
    <div
      style={{ width: "100%" }}
      ref={activityContainerRef}
      className='activity-container'
    >
      {orderedActivitySections?.map((section, index) => {
        return (
          <>
            {ActivitySections[section]}
            {index !== orderedActivitySections.length - 1 && <LineBreaker />}
          </>
        )
      })}
      <PaymentGatewayV3
        isOpen={isPaymentGatewayOpen}
        onClose={closePaymentGatewayBottomSheet}
      />
    </div>
  )
}

export default ActivitySection_v3
