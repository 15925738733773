import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb"
import { useSelector } from "react-redux"
import { DotLottieReact } from "@lottiefiles/dotlottie-react"
import swipeDown from "../../../../../src/assets/images/v3/Swipe-Down.lottie"
import swipeUp from "../../../../../src/assets/images/v3/Swipe-Up.lottie"

const renderLottie = src => {
  return <DotLottieReact src={src} loop autoplay />
}

const InactiveGuideOverlay = ({ cardVisibleV3 }) => {
  const screen = useSelector(state => state.screen)
  const windowDimensions = useWindowDimensions()

  return (
    <div className='v3-home-overlay'>
      <div
        className='v3-swipe-load'
        style={{ top: cardVisibleV3 ? "230px" : "500px" }}
      >
        {cardVisibleV3 ? renderLottie(swipeDown) : renderLottie(swipeUp)}
      </div>
      <div className='v3-overlay-btn-container'>
        <button className='v3-overlay-btn-ok'>Okay, got it!</button>
      </div>
    </div>
  )
}

export default InactiveGuideOverlay
