import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { BottomSheet } from "react-spring-bottom-sheet"
import { Header } from "../core"
import AccountService from "../../services/AccountService"
import {
  CASHBACK_TRANSACTIONS_PER_PAGE,
  FilterBottomSheetTabs,
  FilterType,
} from "../../utils/constants"
import { consoleError, tempFormatTransactionsData } from "../../utils/functions"
import RewardsCashbackBanner from "../Rewards/v3/RewardsCashbackBanner"
import Filter from "../core/FIlter/v3/Filter"
import FiltersBottomSheet from "../Rewards/v3/FiltersBottomSheet"
import RewardTransactionListWithBottomSheet from "../Rewards/v3/RewardTransactionListWithBottomsheet"
import TransactionListLoader from "../core/SkeletonLoader/TransactionListLoader"
import InfiniteScroll from "../core/InfiniteScrollV3"
import { clearCommonState, setCommonState } from "../../store/actions/Common"

const Cashback_V3 = () => {
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)

  const dispath = useDispatch()
  const hasScrolledRef = useRef(false)

  const [selectedFilter, setSelectedFilter] = useState(null)
  const [selectedTab, setSelectedTab] = useState(FilterBottomSheetTabs.CYCLE)
  const [isFiltersBottomSheetOpen, setIsFiltersBottomSheetOpen] =
    useState(false)
  const [selectedDuration, setSelectedDuration] = useState(null)
  const [cycles, setCycles] = useState([])

  const cashbackData = user?.hfCashback

  const { leadingWhite } = theme?.v3?.rawColors

  const transactionFilters = [
    {
      filterName: FilterType.DATE,
      showBtnArrow: true,
      onFilterClick: () => setFilter(FilterType.DATE),
      isActive: selectedDuration?.length !== 0,
    },
    // {
    //   filterName: FilterType.SORT_BY,
    //   showBtnArrow: true,
    //   onFilterClick: () => setFilter(FilterType.SORT_BY),
    //   isActive: selectedFilters.includes(FilterType.SORT_BY),
    // },
    // {
    //   filterName: FilterType.CATEGORIES,
    //   showBtnArrow: true,
    //   onFilterClick: () => setFilter(FilterType.CATEGORIES),
    //   isActive: selectedFilters.includes(FilterType.CATEGORIES),
    // },
    {
      filterName: FilterType.CREDITED,
      showBtnArrow: false,
      onFilterClick: () => setFilter(FilterType.POSTED),
      isActive: selectedFilter === FilterType.POSTED,
    },
    {
      filterName: FilterType.REVERSED,
      showBtnArrow: false,
      onFilterClick: () => setFilter(FilterType.REVERSED),
      isActive: selectedFilter === FilterType.REVERSED,
    },
    {
      filterName: FilterType.PENDING,
      showBtnArrow: false,
      onFilterClick: () => setFilter(FilterType.PENDING),
      isActive: selectedFilter === FilterType.PENDING,
    },
  ]

  useEffect(() => {
    if (
      !user?.summary ||
      !user?.summary?.lastStatement ||
      !user?.account.id ||
      !config?.version
    )
      return
    const lastStatement = user?.summary?.lastStatement
    const lastStatementBillingCycle = {
      from: moment(lastStatement?.fromDate).format("YYYY-MM-DD"),
      to: moment(lastStatement?.toDate).format("YYYY-MM-DD"),
    }

    let billingCycles = []
    if (lastStatementBillingCycle?.to !== moment().format("YYYY-MM-DD")) {
      billingCycles.push({
        from: moment(lastStatement?.toDate).add(1, "days").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      })
    }

    billingCycles.push(lastStatementBillingCycle)
    ;(async function () {
      const toDate = moment(lastStatementBillingCycle?.to).format("YYYY-MM-DD")
      const fromDate = moment(lastStatementBillingCycle?.to)
        .subtract(210, "days")
        .format("YYYY-MM-DD")

      const statementBillingCyclesResponse =
        await AccountService.getBillingCycles({
          accountId: user?.account?.id,
          from: fromDate,
          to: toDate,
        })
      const result = statementBillingCyclesResponse?.data
      if (result?.success) {
        result?.data?.statements.map((cycle, index) => {
          billingCycles.push({
            from: moment(cycle.fromDate).format("YYYY-MM-DD"),
            to: moment(cycle.toDate).format("YYYY-MM-DD"),
          })
        })
        setCycles(billingCycles)
      }
    })()

    setSelectedDuration(billingCycles?.[0])
  }, [user, config])

  useEffect(() => {
    if (hasScrolledRef.current) {
      dispath(setCommonState({ isRewardsLoaded: true }))
    } else {
      hasScrolledRef.current = true
    }

    return () => {
      dispath(clearCommonState())
    }
  }, [selectedFilter])

  const fetchCashbackTransactions = async (
    accountId,
    count,
    offset,
    fromDate,
    toDate,
  ) => {
    if (!selectedDuration) return { success: false }
    try {
      let payload
      let response
      let result

      payload = {
        accountId: user?.account?.id,
        startDate: selectedDuration?.from,
        endDate: selectedDuration?.to,
        count: CASHBACK_TRANSACTIONS_PER_PAGE,
        offset: offset / CASHBACK_TRANSACTIONS_PER_PAGE,
        ...(selectedFilter ? { status: selectedFilter } : {}),
      }

      response = await AccountService.getHfCashbackTransactions(payload)
      result = response?.data
      if (result?.success) {
        const benefitIds = result?.data?.data
          ?.filter(item => item?.benefitId)
          .map(item => item?.benefitId)

        const transactionIds = result?.data?.data
          ?.filter(item => item?.transactionId)
          .map(item => item?.transactionId)

        let cashbackData = result?.data?.data

        if (benefitIds?.length !== 0) {
          const benefitDetailsResponse = await AccountService.getBulkBenefits({
            benefitIds: benefitIds,
          })

          if (benefitDetailsResponse?.data?.success) {
            cashbackData = cashbackData?.map(cashback => ({
              ...cashback,
              benefitDetails: cashback?.benefitId
                ? benefitDetailsResponse?.data?.data?.filter(
                    item => item?.benefitId === cashback?.benefitId,
                  )?.[0]
                : null,
            }))
          }
        }

        if (transactionIds?.length !== 0) {
          const transactionDetailsResponse =
            await AccountService.getBulkTransactions({
              accountId: user?.account?.id,
              transactionIdList: transactionIds,
            })

          if (transactionDetailsResponse?.data?.success) {
            cashbackData = cashbackData?.map(cashback => ({
              ...cashback,
              transactionDetails: cashback?.transactionId
                ? transactionDetailsResponse?.data?.data?.filter(
                    item => item?.id === cashback?.transactionId,
                  )?.[0]
                : null,
            }))
          }
        }
        return tempFormatTransactionsData(
          cashbackData,
          result?.data,
          CASHBACK_TRANSACTIONS_PER_PAGE,
        )
      } else {
        consoleError(response?.error)
        return { success: false }
      }
    } catch (error) {
      consoleError(error)
      return { success: false }
    }
  }

  const setFilter = filter => {
    if (filter === FilterType.DATE) {
      setIsFiltersBottomSheetOpen(true)
      return
    }

    if (selectedFilter === filter) {
      setSelectedFilter("")
    } else {
      setSelectedFilter(filter)
    }
  }

  const handleFiltersBottomSheetCloseButtonClick = () => {
    setIsFiltersBottomSheetOpen(false)
  }

  return (
    <div
      style={{
        minHeight: window.innerHeight,
        backgroundColor: leadingWhite,
        position: "relative",
      }}
    >
      <Header
        text='Cashback'
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <div className='v3-rewards-cashback-points-section'>
        <div className='v3-rewards-cashback-points-section-background-btm'></div>
        <RewardsCashbackBanner isCashback />
      </div>

      <div className='v3-rewards-cashback-history'>
        <div className='v3-rewards-cashback-history-sticky-header'>
          <div className='v3-rewards-cashback-history-header'>
            Cashback History
          </div>
          <div className='v3-rewards-cashback-history-filters'>
            {[
              ...transactionFilters.filter(element => element.isActive),
              ...transactionFilters.filter(element => !element.isActive),
            ].map((element, index) => {
              return <Filter data={element} />
            })}
          </div>
          <div className='v3-rewards-cashback-history-filters-text'>
            Showing results from{" "}
            <span>
              {moment(selectedDuration?.from).format("Do MMM")} to{" "}
              {moment(selectedDuration?.to).format("Do MMM")}
            </span>
          </div>
        </div>
        <div className='v3-rewards-cashback-history-transactions'>
          <InfiniteScroll
            displayElement={data => (
              <RewardTransactionListWithBottomSheet
                transactionList={data}
                isCashback
              />
            )}
            next={fetchCashbackTransactions}
            hasPullDownToRefresh={false}
            hasScrollTopTopOption={false}
            dataLength={11}
            fromDate={selectedDuration?.from}
            toDate={selectedDuration?.to}
            loadingDelay={200}
            selectedFilter={selectedFilter}
            TransactionLoader={TransactionListLoader}
            zeroStateText={"No cashback pt. history found with applied filters"}
          />
        </div>
      </div>

      <BottomSheet open={isFiltersBottomSheetOpen}>
        <FiltersBottomSheet
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          handleCloseButtonClick={handleFiltersBottomSheetCloseButtonClick}
          cycles={cycles}
          selectedCycle={selectedDuration}
          setSelectedCycle={setSelectedDuration}
        />
      </BottomSheet>
    </div>
  )
}

export default Cashback_V3
